import { FC, useState } from "react";

import { useMediaQuery } from "react-responsive";

import { TType, useTranslate } from "../../../../../assets/translations";
import { OpenBook } from "../../../../../assets/images/svg-elements/open-book";
import { MoneyCard } from "../../../../../assets/images/svg-elements/money-card";
import { FieldTime } from "../../../../../assets/images/svg-elements/field-time";
import { CalendarClearIcon } from "../../../../../assets/images/svg-elements/calendar-clear";

import { device } from "../../../../constants";

import { ButtonTheme } from "../../../../elements/button";

import ToggleApplicantsButton from "../../lesson-request-action-buttons/toggle-applicants/toggle-applicants";

import StudentDropdown from "../../lesson-request-dropdown/student-dropdown/student-dropdown";
import {
  MobileStudentPublicCardWrapper,
  StudentDropdownCardContainer,
} from "../../lesson-request-dropdown/student-dropdown/student-dropdown-card/student-dropdown-card-styled";

import {
  LessonRequestCardTimeInfo,
  LessonRequestCardWrapper,
} from "../lesson-request-card-styled";
import {
  StudentPublicCardButtons,
  StudentPublicCardCategoryInfo,
  StudentPublicCardLevel,
  StudentPublicCardPrice,
  StudentPublicCardTimeInfo,
  StudentPublicMessage,
} from "./student-public-card-styled";


import { formatPriceForCard } from "../../services/formatPriceForCard";
import { formatAge } from "../../../../../services/localization-service";

import {
  ApplicationStatuses,
  GetRequestsLessonStatuses,
  KnowledgeLevelsNames,
  LessonsFrequency,
  LessonsFrequencyNames,
  RequestLesson,
} from "../../../../../types";
import DeleteRequestButton from "../../lesson-request-action-buttons/delete-request/delete-request";
import { deleteConnectionRequest } from "../../../../../api/lesson-api/deleteConnectionRequest";
import {
  TeachingMeErrorNotification,
  TeachingMeSuccessNotification,
} from "../../../../elements/notification";
import { useQueryClient } from "@tanstack/react-query";
import CardActionModal from "../../../../modals/card-action-modal/card-action-modal";
import { useIsResponsive } from "../../../../../hooks/useIsResponsive";
import { useFormattedMoreMessage } from "../../../../../hooks/useFormattedMoreMessage";

interface StudentPublicCardProps {
  card: RequestLesson;
  t: TType;
}

const StudentPublicCard: FC<StudentPublicCardProps> = ({ card, t }) => {
  const [isApplicantsDropdownOpen, setIsApplicantsDropdownOpen] =
    useState<boolean>(true);

  const queryClient = useQueryClient();

  const { language } = useTranslate();

  const handleToggleDropdown = () => {
    setIsApplicantsDropdownOpen((prev) => !prev);
  };

  const { isBigDesktop } = useIsResponsive();

  const price = formatPriceForCard(card.desiredPriceRange);
  const {
    isMessageLongerThanMaxSymbols,
    formattedMessage,
    handleIsShowMore,
    isShowMore,
  } = useFormattedMoreMessage(card.message, !isBigDesktop ? 100 : 30);

  const handleDeleteRequest = async () => {
    try {
      const res = await deleteConnectionRequest(card.id);

      if (res.status === 200) {
        queryClient.refetchQueries(["studentActiveRequests"]);
        queryClient.refetchQueries(["studentAllRequests"]);
        TeachingMeSuccessNotification("Request has been successfully deleted");
      }
    } catch (error) {
      TeachingMeErrorNotification("Error occured while deleting an request");
    }
  };

  return (
    <LessonRequestCardWrapper>
      <StudentDropdownCardContainer className="container">
        <StudentPublicCardCategoryInfo>
          <OpenBook />
          <div className="personal_info">
            <div>
              <p>{card.categoryName}</p>
            </div>
          </div>
        </StudentPublicCardCategoryInfo>

        <StudentPublicCardPrice>
          <MoneyCard />
          <p>
            {price
              ? `${price} ${t("main-page.search-query.uah")}`
              : t("my-students.not-specified")}
          </p>
        </StudentPublicCardPrice>

        <StudentPublicCardTimeInfo>
          <div>
            <FieldTime />
            <p>{card.desiredTime ?? t("my-students.not-specified")}</p>
          </div>
          <div>
            <CalendarClearIcon />
            <p>
              {card.numberOfClassesPerWeek
                ? `${LessonsFrequencyNames[card.numberOfClassesPerWeek]} ${
                    card.numberOfClassesPerWeek === LessonsFrequency.ONE
                      ? t("main-page.search-query.one_time_per_week")
                      : t("main-page.search-query.time_per_week")
                  }`
                : t("my-students.not-specified")}
            </p>
          </div>
        </StudentPublicCardTimeInfo>

        <StudentPublicCardLevel>
          <div>
            <p className="card-section-title">
              {t("lesson-requests.cards.level")}
            </p>
            <p>
              {card.knowledgeLevel
                ? t(
                    `language-levels.${
                      KnowledgeLevelsNames[card.knowledgeLevel]
                    }`
                  )
                : t("my-students.not-specified")}
            </p>
          </div>
          <div>
            <p className="card-section-title">
              {t("lesson-requests.cards.age")}
            </p>
            <p>
              {card.age
                ? `${card.age} ${formatAge(+card.age, language, t)}`
                : t("my-students.not-specified")}
            </p>
          </div>
        </StudentPublicCardLevel>

        <StudentPublicMessage>
          {card.message ? (
            <div className="container">
              {isShowMore ? (
                <>
                  <p>{card.message}</p>
                  {isMessageLongerThanMaxSymbols && (
                    <button
                      onClick={handleIsShowMore}
                      className="load-more-message-btn"
                    >
                      Less
                    </button>
                  )}
                </>
              ) : (
                <>
                  <p>
                    {isMessageLongerThanMaxSymbols
                      ? `${formattedMessage}...`
                      : formattedMessage}
                  </p>
                  {isMessageLongerThanMaxSymbols && (
                    <button
                      onClick={handleIsShowMore}
                      className="load-more-message-btn"
                    >
                      More
                    </button>
                  )}
                </>
              )}
            </div>
          ) : (
            <p>{t("my-students.not-specified")}</p>
          )}
        </StudentPublicMessage>
      </StudentDropdownCardContainer>

      <MobileStudentPublicCardWrapper>
        {isApplicantsDropdownOpen &&
          (!!card.applicants.filter(applicant => applicant.status !== ApplicationStatuses.REJECTED).length ? (
            <StudentDropdown
              card={card}
              t={t}
            />
          ) : (
            <p
              style={{
                textAlign: "center",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              Not applicants yet
            </p>
          ))}

        {card.status === GetRequestsLessonStatuses.CREATED && (
          <StudentPublicCardButtons isApplicantsDropdownOpen={isApplicantsDropdownOpen}>
            {!!card.applicants.length && (
              <ToggleApplicantsButton
                theme={ButtonTheme.WHITE}
                handleClick={handleToggleDropdown}
              >
                {isApplicantsDropdownOpen ? "Hide" : "Show"}
              </ToggleApplicantsButton>
            )}
            <DeleteRequestButton handleDelete={handleDeleteRequest} />
          </StudentPublicCardButtons>
        )}
      </MobileStudentPublicCardWrapper>
    </LessonRequestCardWrapper>
  );
};

export default StudentPublicCard;
