import { useMediaQuery } from "react-responsive";

import { device } from "../components/constants";

export const useIsResponsive = () => {
  const isMobile = useMediaQuery({
    query: `(${device.mobileMax})`,
  });

  const isTablet = useMediaQuery({
    query: `(${device.tabletMin}) and (${device.tabletMax})`,
  });

  const isSmallDesktop = useMediaQuery({
    query: `(${device.desktop}) and (${device.smallDesktop})`,
  });

  const isBigDesktop = useMediaQuery({
    query: `(min-width: 1281px)`
  });

  return { isMobile, isTablet, isSmallDesktop, isBigDesktop };
};
