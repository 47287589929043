import { FC } from "react";

import { TrashBin } from "../../../../../assets/images/svg-elements/trash-bin";

import { ButtonTheme } from "../../../../elements/button";

import { DeleteRequestButtonStyled } from "./delete-request-styled";

interface DeleteRequestButtonProps {
  handleDelete: () => void;
}

const DeleteRequestButton: FC<DeleteRequestButtonProps> = ({
  handleDelete,
}) => {
  return (
    <DeleteRequestButtonStyled theme={ButtonTheme.WHITE} onClick={handleDelete}>
      <TrashBin />
    </DeleteRequestButtonStyled>
  );
};

export default DeleteRequestButton;
