import {FC} from "react";

import {Form} from "../../shared-form";
import {TFeedbackBody} from "../../../../../types";
import {TType} from "../../../../../assets/translations";
import {StyledModal} from "./desktop-form.styled";


interface Props {
    t: TType;
    open: boolean;
    approveFeedback: (feedback: TFeedbackBody) => void;
    cancelFeedback: () => void;
    suggestCategory?: boolean;
}

export const DesktopForm: FC<Props> = ({t, open, approveFeedback, cancelFeedback, suggestCategory}) => {

    return <StyledModal open={open}
                        footer={null}
                        closable={false}
    >
        <Form t={t} approveFeedback={approveFeedback} cancelFeedback={cancelFeedback} suggestCategory={suggestCategory}/>
    </StyledModal>
};
