import {FC, useEffect, useState} from "react";

import {FeedbacksSectionStyled} from "./feedbacks-section.styled";
import {getFeedbacks} from "../../../../../../api/user-api/getFeedbacks";
import {TFeedbackBody} from "../../../../../../types";
import {FeedbackCard} from "../../elements/feedback-card";
import {usePagination} from "../../../../../../hooks/usePagination";
import Pagination from "../../../../../elements/pagination/pagination";
import {NotFoundComponent} from "../../../../../elements/not-found-component";

export const FeedbacksSection: FC = ()=> {

  const [feedbacks, setFeedbacks] = useState<TFeedbackBody[]>([])
  const [totalItems, setTotalItems] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const {
    goToNextPage,
    goToPage,
    goToPreviousPage,
    paginationRange,
    totalPages,
  } = usePagination({
    pageSize: 10,
    totalItems,
    currentPage,
    setCurrentPage,
  });

  useEffect(() => {
    getFeedbacks(currentPage -1)
      .then(({data})=>{
        setFeedbacks(data.feedbacks);
        setTotalItems(data.totalItems);
      })
  }, [currentPage]);

  return (
    <FeedbacksSectionStyled>
      <div className="title-container">
        <h2>Відгуки користувачів</h2>
        <p>Адміністратор може переглядати та керувати відгуками для покращення сервісу.</p>
      </div>

      <section className="regular-section">
        {!!feedbacks.length ?
          <div className="feedbacks-container">
          {feedbacks && feedbacks.map((feedback)=>(
            <FeedbackCard key={feedback.id} feedback={feedback}/>
          ))}
        </div>
        :
        <NotFoundComponent text="Немає результатів"/>
        }
      </section>

      {totalPages !== 0 && (
        <Pagination
          currentPage={currentPage}
          goToNextPage={goToNextPage}
          goToPage={goToPage}
          goToPreviousPage={goToPreviousPage}
          paginationRange={paginationRange}
          totalPages={totalPages}
        />
      )}
    </FeedbacksSectionStyled>
  )
}