import styled from "styled-components";

export const FeedbackCardStyled = styled.div`

    display: grid;
    grid-template-columns: 30% 15% 35% 10% 10%;  
    padding: 16px 12px;
    background: #fafcff;
    
    .feedback-type-badge {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #52C41A;
        background: #F6FFED;
        border: 1px solid #B7EB8F;
        border-radius: 4px;
        padding: 1px 8px;
        width: fit-content;
    }

    div.border-right {
        border-right: 1px solid #d6e4ff;
        height: 100%;
    }
    
    .align-center {
        display: flex;
        align-items: center;
    }
    
    div.text-center {
        display: flex;
        justify-content: center;
    }
    
    .email {
        font-size: 16px;
        font-weight: 500;
    }
    
    .rate {
        .ant-rate {
            display: flex;
            
            .star-item {
                svg {
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }
    
    .comment {
        padding: 0 16px;
    }
`