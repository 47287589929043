import { FC, useState } from "react";
import { ActionButtons } from "../lesson-request-action-buttons-styled";
import {
  CardMessageButtonProps,
  handleMessageButton,
} from "../lesson-request-action-buttons";
import { Letter } from "../../../../../assets/images/svg-elements/letter";
import CardActionModal from "../../../../modals/card-action-modal/card-action-modal";
import { putConnectionRequests } from "../../../../../api/lesson-api/putConnectionRequests";
import { postAcceptApplicant } from "../../../../../api/lesson-api/postAcceptApplicant";
import { ApplicationStatuses } from "../../../../../types";
import { TeachingMeErrorNotification } from "../../../../elements/notification";

interface OpenContactsButtonProps extends CardMessageButtonProps {
  connectionRequestId: string;
  applicationId: string;
}

const OpenContactsButton: FC<OpenContactsButtonProps> = ({
  language,
  navigate,
  studentId,
  t,
  teacherId,
  theme,
  disabled,
  applicationId,
  connectionRequestId,
}) => {
  const [isOpenContactsModal, setIsOpenContactsModal] =
    useState<boolean>(false);

  const handleAcceptOpenContacts = async () => {
    try {
      const res = await postAcceptApplicant(
        connectionRequestId,
        applicationId,
        {
          status: ApplicationStatuses.CONTACTS_OPENED,
        }
      );

      if (res.status === 200) {
        handleMessageButton({ language, navigate, studentId, t, teacherId });
      }
    } catch (error) {
      TeachingMeErrorNotification("Error");
    }
  };
  return (
    <>
      <CardActionModal
        open={isOpenContactsModal}
        handleRequest={handleAcceptOpenContacts}
        handleCancel={() => setIsOpenContactsModal(false)}
        header="Are you sure you want to open contacts to this teacher?"
      />
      <ActionButtons
        theme={theme}
        onClick={() => setIsOpenContactsModal(true)}
        disabled={disabled}
      >
        <Letter />
      </ActionButtons>
    </>
  );
};

export default OpenContactsButton;
