import axiosClient from "../../config/axiosConfig";
import {POST_RESOLVE_TICKET} from "../endpoints";
import {TicketStatus} from "../../types";

type RequestBody = {
  resolveComment: string,
  resolverName: string,
  status: TicketStatus
}

export const postResolveTicket = async (ticketId: string, requestBody: RequestBody) => {
  return axiosClient.post(POST_RESOLVE_TICKET(ticketId), requestBody);
}