import React, {FC, useEffect, useState} from "react";

import {AssignLabelsPopupStyled} from "./assign-labels-popup.styled";
import {ButtonTheme, StyledButton} from "../../../../../elements/button";
import {getBadgeItems} from "../../../../../elements/badge/badge-items";
import {BadgeSizes, BadgesTypes, TLabelEntity} from "../../../../../../types/Badge";
import {CustomLoader} from "../../../../../elements/custom-loader";
import {TeacherDetails} from "../../../../../../types";
import {useTranslate} from "../../../../../../assets/translations";
import Badge from "../../../../../elements/badge/badge";
import {postAdminLabels} from "../../../../../../api/category-api/postAdminLabels";
import {deleteAdminLabels} from "../../../../../../api/category-api/deleteAdminLabels";
import {TeachingMeErrorNotification, TeachingMeSuccessNotification} from "../../../../../elements/notification";

interface Props {
  open: boolean;
  onCancel?: () => void;
  user: TeacherDetails | undefined;
  refetchUserDetails: () => void;
}

export const AssignLabelsPopup: FC<Props> = ({open, onCancel, user, refetchUserDetails}) => {

  const [activeLabels, setActiveLabels] = useState<TLabelEntity[]>(user?.labels || []);
  const [availableLabels, setAvailableLabels] = useState<TLabelEntity[]>([]);

  const {t} = useTranslate();

  useEffect(() => {
    const allLabelsList = Object.values(BadgesTypes).map((item) => ({manual: true, value: item}));

    const filteredLabels = allLabelsList.filter(
      (label) => !activeLabels.some((activeLabel) => activeLabel.value === label.value));

    setAvailableLabels(filteredLabels);
  }, [activeLabels]);

  useEffect(() => {
    if (!user?.labels) {
      return
    }

    setActiveLabels(user?.labels);
  }, [user])

  function saveLabels() {
    if (!user) {
      return
    }

    const removedLabels = user.labels.filter(
      (userLabel) => !activeLabels.some((activeLabel) => activeLabel.value === userLabel.value)).map((removedLabel) => removedLabel.value);

    const addedLabels = activeLabels.filter(
      (activeLabel) => !user.labels.some((userLabel) => userLabel.value === activeLabel.value)).map((addedLabel) => addedLabel.value);

    Promise.all([
      postAdminLabels({userId: user.id, labels: addedLabels}),
      deleteAdminLabels({userId: user.id, labels: removedLabels})
    ])
      .then(() => {
        onCancel && onCancel();
        TeachingMeSuccessNotification("Теги збережено успішно!");
        refetchUserDetails();
      })
      .catch(() => {
        TeachingMeErrorNotification("Не вдалося зберегти теги, спробуйте будь-ласка пізніше.")
      })
  }

  function handleRemoveLabel(badgeType: BadgesTypes) {
    setActiveLabels(prev => prev.filter((item) => item.value !== badgeType))
  }

  function handleAddLabel(badgeType: TLabelEntity) {
    setActiveLabels(prev => [...prev, badgeType]);
  }

  return (
    <AssignLabelsPopupStyled open={open} onCancel={onCancel} closable={false} footer={null}>
      {user ?
        <>
          <h1>Керування тегами репетитора</h1>
          <p className="main-description">
            Додавайте або видаляйте теги, які будуть доповнювати інформацію про репетитора.
          </p>

          <div className="manage-labels-block">
            <div className="active-labels-container labels-block">
              <h2>Активні теги</h2>
              {getBadgeItems(activeLabels, t).map((item, index) => (
                <div className="badge-container">
                  <Badge key={index} badge={item} size={BadgeSizes.MEDIUM}/>
                  <button disabled={!item.manual} onClick={() => handleRemoveLabel(item.badgeType)}>-</button>
                </div>
              ))}
            </div>
            <div className="available-labels-container labels-block">
              <h2>Доступні теги</h2>
              {getBadgeItems(availableLabels, t).map((item, index) => (
                <div className="badge-container">
                  <Badge key={index} badge={item} size={BadgeSizes.MEDIUM}/>
                  <button onClick={() => handleAddLabel({manual: item.manual, value: item.badgeType})}>+</button>
                </div>
              ))}
            </div>
          </div>

          <div className="buttons">
            <StyledButton disabled={!areLabelsChanged(user.labels, activeLabels)}
                          hintForDisabledButton="Немає жодних змін." onClick={saveLabels} className="approve"
                          theme={ButtonTheme.WHITE}>
              Зберегти
            </StyledButton>
            <StyledButton onClick={onCancel} className="cancel">
              Скасувати
            </StyledButton>
          </div>
        </>
        :
        <CustomLoader/>}
    </AssignLabelsPopupStyled>
  )
}

function areLabelsChanged(defaultLabels: TLabelEntity[], activeLabels: TLabelEntity[]) {
  if (defaultLabels.length !== activeLabels.length) {
    return true
  }

  const sortedDefaultLabels = [...defaultLabels.map((defaultLabel) => defaultLabel.value)].sort();
  const sortedActiveLabels = [...activeLabels.map((activeLabel) => activeLabel.value)].sort();

  return !sortedDefaultLabels.every((item, index) => item === sortedActiveLabels[index]);
}