import styled from "styled-components";

import {device} from "../../../constants";


export const ContentContainer = styled.div`
  .navigation, .content {
    width: 100%;
    max-width: 1440px;
  }

  p {
    margin: 0;
  }

  .navigation {
    > button {
      background: #FFFFFF;
      border: 1px solid #D9D9D9;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
      border-radius: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: rgba(0, 0, 0, 0.85);
      padding: 4px 15px;
      display: flex;
      align-items: center;
      column-gap: 12px;
      cursor: pointer;
      transition: box-shadow 0.3s ease-in-out;

      svg {
        height: 14px;
        width: 14px;
      }

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.16) 0 0 30px 0;
      }
    }
  }

  .content {
    display: flex;
    column-gap: 32px;

    .issue, .resolve {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      width: 100%;

      .header {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: rgb(24, 6, 125);
      }

      .content {
        width: 100%;
        background: rgb(255, 255, 255);
        border-radius: 12px;
        box-shadow: rgba(90, 91, 96, 0.05) 0 6px 12px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        .top-info {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .type {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
          }

        }

        .description {
          word-break: break-all;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
        }

        .attachments {
          display: flex;
          flex-direction: column;
          row-gap: 12px;

          p {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: rgb(0, 0, 0);
          }

          .files {
            display: flex;
            column-gap: 10px;
            row-gap: 10px;
            flex-wrap: wrap;
          }

        }

        .date {
          font-size: 12px;
          line-height: 20px;
          color: rgb(140, 140, 140);
          font-weight: 400;
        }

        .resolver {
          align-self: flex-end;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: rgb(0, 0, 0);
        }
      }
    }
  }

  @media (${device.tabletMin}) and (${device.tabletMax}) {
    padding: 24px 4.9% 32px;
  }

  @media (${device.tabletMax}) {
    row-gap: 24px;
    background: rgb(255, 255, 255);

    .content {
      flex-direction: column;
      row-gap: 24px;

      .issue, .resolve {
        .content {
          padding: 0;
          box-shadow: unset;
        }
      }
    }
  }
`

export const StyledFile = styled.div`
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  border-radius: 6px;
  display: flex;
  column-gap: 12px;
  padding: 12px;
  width: 48%;
  cursor: pointer;
  transition: hover, 0.4s;

  .file-icon {
    background: #F5F5F5;
    border-radius: 8px;
    padding: 9px 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .file-info {

    h2, p {
      margin: 0;
    }

    h2 {
      overflow-wrap: anywhere;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #111729;
    }

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #8C8C8C;
    }
  }
  
  &:hover{
    box-shadow: rgb(93,81,164) 0 0 2px 1px;
  }

  @media (${device.mobileMax}) {
    width: 100%;
  }
`