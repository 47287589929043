import styled, { css } from "styled-components";
import { siderOptimizationStyle } from "../../../../config";
import { device } from "../../../constants";

interface Props {
  background?: string;
  noPaddings?: boolean;
  isSiderOpen: boolean;
  shouldRenderProfileSider: boolean;
  isAuthenticated?: boolean;
  isDesktop?: boolean;
}

export const WithSiderWrapperStyled = styled.div<Props>`
  margin-top: var(--header-offset);
  min-height: calc(100% - var(--header-offset));
  background: ${({ background }) => background ?? "#FFFFFF"};
  position: relative;

  .content-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .outer-container {
    height: 100%;
    padding: 24px;

    @media (${device.mobileMax}) {
      padding: ${({ noPaddings}) => noPaddings ? "0px" : "16px"};
    }

    ${siderOptimizationStyle}

    &:has(.navigation) {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    ${({ noPaddings, isSiderOpen, isAuthenticated, isDesktop }) => {
      if (noPaddings && !isAuthenticated) {
        return css`
          padding: 0;
        `
      }

      if (noPaddings && isAuthenticated && !isDesktop) {
        return css`
          padding: 0;
        `
      }

      if (noPaddings && isAuthenticated) {
        return css`
          padding-top: 0;
          padding-bottom: 0;
          padding-right: 0;

          padding-left: ${isSiderOpen
            ? "calc(var(--sider-opened-width)) !important"
            : "calc(var(--sider-collapsed-width)) !important"};
        `;
      }
    }}
  }
`;
