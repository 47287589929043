import {FC} from "react";
import {useNavigate} from "react-router-dom";
import {Rate} from "antd";

import {FeedbackCardStyled} from "./feedback-card.styled";
import {TFeedbackBody} from "../../../../../../types";
import {ButtonTheme, StyledButton} from "../../../../../elements/button";
import {StyledStar} from "../../../../../elements/feedback/shared-form/shared-form.styled";

interface Props {
  feedback: TFeedbackBody;
}

export const FeedbackCard: FC<Props> = ({feedback})=> {

  const {id, email, rating, comment, type} = feedback;

  const navigate = useNavigate();

  return (
    <FeedbackCardStyled>
      <div className="email border-right align-center">
        {email}
      </div>
      <div className="rate border-right text-center align-center">
        <Rate character={<StyledStar className="star-item"/>} disabled value={rating}/>
      </div>
      <div className="comment border-right align-center">
        {comment}
      </div>
      <div className="text-center border-right align-center">
        <span className="feedback-type-badge">{type}</span>
      </div>
      <div className="text-center align-center">
        <StyledButton style={{height: "fit-content"}} onClick={()=>navigate(id)} theme={ButtonTheme.INVERT_BLUE}>Деталі</StyledButton>
      </div>
    </FeedbackCardStyled>
  )
}