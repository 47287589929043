import { FC } from "react";
import { ButtonTheme } from "../../../../elements/button"
import { ToggleApplicantsButtonStyled } from "./toggle-applicants-styled";

interface ToggleApplicantsButtonProps {
    theme: ButtonTheme;
    children: string | React.ReactElement;
    handleClick: () => void;
}

const ToggleApplicantsButton: FC<ToggleApplicantsButtonProps> = ({ theme, children, handleClick }) => {
  return (
    <ToggleApplicantsButtonStyled theme={theme} onClick={handleClick}>
        {children}
    </ToggleApplicantsButtonStyled>
  )
}

export default ToggleApplicantsButton