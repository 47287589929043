export const formatPriceForCard = (price: string | null) => {
  if (!price) return null;

  const numbers = price
    .split(",")
    .flatMap((range) => range.match(/\d+/g))
    .map(Number);

  if (numbers.length === 0) return "";

  if (numbers.length === 1 && numbers[0] === 700) {
    return `${numbers[0]}+`;
  }

  const lowest = Math.min(...numbers);
  const highest = Math.max(...numbers);

  return `${lowest} - ${highest}`;
};
