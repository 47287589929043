import {FC, useState} from "react";

import {ResolveTicketFormStyled} from "./resolve-ticket-form.styled";
import {StyledButton} from "../../../../../elements/button";
import TextArea from "antd/es/input/TextArea";
import {postAssignTicket} from "../../../../../../api/user-api/postAssignTicket";
import {ISingleTicket, TicketStatus} from "../../../../../../types";
import {TeachingMeErrorNotification, TeachingMeSuccessNotification} from "../../../../../elements/notification";
import {postResolveTicket} from "../../../../../../api/user-api/postResolveTicket";

interface Props {
  ticketDetails: ISingleTicket;
  triggerTicketDetails: ()=> void;
}

export const ResolveTicketForm:FC<Props> = ({ticketDetails, triggerTicketDetails})=> {

  const [resolveComment, setResolveComment] = useState<string>(()=> ticketDetails.issueResult ? ticketDetails.issueResult : "");

  function handleAssignButton() {
    postAssignTicket(ticketDetails.id, {resolverName: "Test Admin"})
      .then((response)=>{
        TeachingMeSuccessNotification("Ticket has been assigned successfully");
        triggerTicketDetails();
      })
      .catch((error)=>{
        TeachingMeErrorNotification("Failed to assign the ticket")
      })
  }

  function handleResolveTicket() {
    postResolveTicket(ticketDetails.id, {resolveComment, resolverName: "Test Admin", status: TicketStatus.RESOLVED})
      .then((response)=>{
        TeachingMeSuccessNotification("Ticket has been resolved successfully");
        triggerTicketDetails();
      })
      .catch((error)=>{
        TeachingMeErrorNotification("Failed to resolve the ticket")
      })
  }

  return (
    <ResolveTicketFormStyled>
      {ticketDetails.resolverName ? <div>Resolver name: {ticketDetails.resolverName}</div>
        :
      <StyledButton hintForDisabledButton="Дана проблема вже прийнята на опрацювання" disabled={ticketDetails.status !== TicketStatus.OPEN} onClick={handleAssignButton}>Assign</StyledButton>}
      <TextArea onChange={(e)=>setResolveComment(e.target.value)} value={resolveComment} placeholder="resolve comment"/>
      <StyledButton onClick={handleResolveTicket} hintForDisabledButton="Необхідно вказати коментар для вирішення проблеми" disabled={!resolveComment || ticketDetails.status === TicketStatus.RESOLVED}>Resolve</StyledButton>
    </ResolveTicketFormStyled>
  );
}