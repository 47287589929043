import { FC, useCallback, useState } from "react";
import { isAxiosError } from "axios";
import { NavigateFunction } from "react-router-dom";

import { createChat, getChatByIds } from "../../../../api/chat-api";
import { putDirectLessonRequest } from "../../../../api/lesson-api/putDirectConnectionRequests";

import { Edit } from "../../../../assets/images/svg-elements/edit";
import { Letter } from "../../../../assets/images/svg-elements/letter";
import { Translations, TType } from "../../../../assets/translations";

import { ButtonTheme } from "../../../elements/button";
import {
  TeachingMeErrorNotification,
  TeachingMeSuccessNotification,
} from "../../../elements/notification";

import { LessonRequestActionButtonsWrapper } from "../lesson-request-card/lesson-request-card-styled";

import { ActionButtons, CardMessageButtonStyled } from "./lesson-request-action-buttons-styled";
import {
  GetRequestsLessonStatuses,
  LessonRequestType,
} from "../../../../types";
import {
  createLessonDataKey,
  saveToLs,
} from "../../../../services/storage-service";
import { useQueryClient } from "@tanstack/react-query";
import ClarificationNeededPopup from "../../../modals/clarification-needed/clarification-needed-popup";
import TeacherPublicRequestAcceptPopup from "../../../modals/teacher-public-request-accept/teacher-public-request-accept";

export interface CardMessageButtonProps {
  teacherId: string;
  studentId: string;
  navigate: NavigateFunction;
  language: Translations;
  theme: ButtonTheme;
  t: TType;
  type?: "icon" | "text";
  disabled?: boolean;
}

interface HandleMessageButtonProps {
  teacherId: string;
  studentId: string;
  navigate: NavigateFunction;
  language: Translations;
  t: TType;
}

export const handleMessageButton = async ({
  language,
  navigate,
  studentId,
  t,
  teacherId,
}: HandleMessageButtonProps) => {
  try {
    const res = await getChatByIds(teacherId, studentId);

    if (res?.data?.id) {
      navigate(`/m/${res.data.id}`);
    }
  } catch (error: unknown) {
    if (isAxiosError(error)) {
      try {
        const newChatRes = await createChat(language, studentId);

        if (newChatRes?.data?.id) {
          navigate(`/m/${newChatRes.data.id}`, { state: newChatRes.data });
        } else {
          throw new Error("Chat creation failed");
        }
      } catch (error) {
        TeachingMeErrorNotification(t("errors.default"));
      }
    }
  }
};

export const CardMessageButton: FC<CardMessageButtonProps> = ({
  navigate,
  studentId,
  teacherId,
  language,
  theme,
  type,
  t,
  disabled
}) => {
  return (
    <CardMessageButtonStyled
      theme={theme}
      onClick={() =>
        handleMessageButton({ language, navigate, studentId, t, teacherId })
      }
      disabled={disabled}
    >
      {type === "icon" ? (
        <Letter />
      ) : (
        <span>{t("lesson-requests.button.message")}</span>
      )}
    </CardMessageButtonStyled>
  );
};

interface LessonRequestActionButtonsProps {
  isStudent: boolean;
  status: GetRequestsLessonStatuses;
  navigate: NavigateFunction;
  teacherId: string;
  studentId: string;
  language: Translations;
  connectionRequestId: string;
  t: TType;
  view?: "card" | "banner";
  categoryId?: string;
  type?: LessonRequestType;
  setIsOpenDropdown?: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenDropdown?: boolean;
  clarificationNeeded: boolean;
}

const LessonRequestActionButtons: FC<LessonRequestActionButtonsProps> = ({
  isStudent,
  status,
  navigate,
  teacherId,
  studentId,
  language,
  connectionRequestId,
  t,
  view = "card",
  categoryId,
  type,
  setIsOpenDropdown,
  isOpenDropdown,
  clarificationNeeded,
}) => {
  const queryClient = useQueryClient();
  const [isClarificationNeededPopupOpen, setIsClarificationNeededPopupOpen] =
    useState<boolean>(false);
  const [isAcceptPopupOpen, setIsAccpetPopupOpen] = useState<boolean>(false);

  const handleChangeRequestStatus = useCallback(
    async (newStatus: GetRequestsLessonStatuses, successMsg: string) => {
      try {
        const res = await putDirectLessonRequest(connectionRequestId, {
          status: newStatus,
        });
        if (res.status === 200) {
          TeachingMeSuccessNotification(successMsg);

          if (newStatus === GetRequestsLessonStatuses.CANCELLED) {
            queryClient.refetchQueries(["teacherDirectRequests"]);
            queryClient.refetchQueries(["ConnectionRequest"]);
          }

          // If teacher accepted, prepare for lesson creation
          if (newStatus === GetRequestsLessonStatuses.ACCEPTED) {
            saveToLs(createLessonDataKey, {
              teacherId,
              categoryId,
              studentId,
            });
            navigate("/lesson/create");
          }
        }
      } catch (error) {
        if (isAxiosError(error)) {
          TeachingMeErrorNotification(error.message);
        }
      }
    },
    [connectionRequestId, categoryId, navigate, studentId, teacherId]
  );

  const renderStudentButtons = () => {
    if (status !== GetRequestsLessonStatuses.ACCEPTED) {
      return (
        <LessonRequestActionButtonsWrapper view={view}>
          {type === LessonRequestType.STUDENT_PUBLIC && setIsOpenDropdown && (
            <ActionButtons
              theme={ButtonTheme.BLUE}
              onClick={() => setIsOpenDropdown((prev) => !prev)}
            >
              {isOpenDropdown ? "Hide applicants" : "Show applicants"}
            </ActionButtons>
          )}

          <ActionButtons theme={ButtonTheme.INVERT_BLUE}>
            <Edit />
          </ActionButtons>
        </LessonRequestActionButtonsWrapper>
      );
    }

    return (
      <LessonRequestActionButtonsWrapper view={view}>
        <CardMessageButton
          language={language}
          navigate={navigate}
          studentId={studentId}
          teacherId={teacherId}
          theme={ButtonTheme.INVERT_BLUE}
          type="icon"
          t={t}
        />
      </LessonRequestActionButtonsWrapper>
    );
  };

  const renderTeacherButtons = () => {
    if (status !== GetRequestsLessonStatuses.CREATED) return null;

    if (type === LessonRequestType.STUDENT_PUBLIC) {
      return (
        <LessonRequestActionButtonsWrapper view={view}>
          <ClarificationNeededPopup
            t={t}
            open={isClarificationNeededPopupOpen}
            onCancel={() => setIsClarificationNeededPopupOpen(false)}
          />
          <TeacherPublicRequestAcceptPopup
            t={t}
            open={isAcceptPopupOpen}
            onCancel={() => setIsAccpetPopupOpen(false)}
            connectionRequestId={connectionRequestId}
          />
          <ActionButtons
            theme={ButtonTheme.DARK_BLUE}
            onClick={() => {
              if (clarificationNeeded) {
                setIsClarificationNeededPopupOpen(true);
                return;
              } else {
                setIsAccpetPopupOpen(true);
              }
            }}
          >
            {t("lesson-requests.buttons.schedule")}
          </ActionButtons>
        </LessonRequestActionButtonsWrapper>
      );
    }

    return (
      <LessonRequestActionButtonsWrapper view={view}>
        <ActionButtons
          theme={ButtonTheme.DARK_BLUE}
          onClick={() => {
            handleChangeRequestStatus(
              GetRequestsLessonStatuses.ACCEPTED,
              t("lesson-requests.notifications.approve")
            );
          }}
        >
          {t("lesson-requests.buttons.schedule")}
        </ActionButtons>

        <ActionButtons
          theme={ButtonTheme.INVERT_BLUE}
          onClick={() => {
            handleChangeRequestStatus(
              GetRequestsLessonStatuses.CANCELLED,
              t("lesson-requests.notifications.decline")
            );
          }}
        >
          {t("lesson-requests.buttons.decline")}
        </ActionButtons>

        {view !== "banner" && (
          <CardMessageButton
            language={language}
            navigate={navigate}
            studentId={studentId}
            teacherId={teacherId}
            theme={ButtonTheme.INVERT_BLUE}
            type="icon"
            t={t}
          />
        )}
      </LessonRequestActionButtonsWrapper>
    );
  };

  return <>{isStudent ? renderStudentButtons() : renderTeacherButtons()}</>;
};

export default LessonRequestActionButtons;
