import styled from "styled-components";

import {
  ChevronIconStyled,
  NavSiderStyledProps,
} from "../profile-sider-styled";
import { NavLink } from "react-router-dom";
import { device } from "../../../constants";

export const NavSiderMenu = styled.ul<NavSiderStyledProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  border: none;
  height: fit-content;
  padding: 0px 19px;
  margin: 0px;
  max-heigth: 50%;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;

  // IE and Edge
  -ms-overflow-style: none;

  // Firefox
  scrollbar-width: none;

  // Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }

  h5 {
    text-transform: uppercase;
    padding: 0px 19px;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.4px;
    font-weight: 500;
    color: #757575;
    opacity: ${({ collapsed }) => (collapsed ? "0" : "1")};
    transition: opacity 0.3s ease-out;
    transition-delay: ${({ collapsed }) => (collapsed ? "0" : "0.2s")};
  }

  li {
    list-style-type: none;
    position: relative;
    min-width: 40px;

    &.tool-visible {
      .collapsed-open {
        margin-top: 17px;
      }
    }

    svg {
      &:not(.settings-icon) {
        stroke: #757575;
      }
    }

    &.active {
      .image-block {
        background: #f6f6f6;
        border-radius: 8px;
      }

      .button-container {
        background: #f6f6f6;
        border-radius: 8px;
      }

      button {
        border-radius: 8px;
        color: #000;

        .chevron {
          fill: #000;
        }

        svg {
          &:not(.settings-icon) {
            stroke: #000;
          }
        }
      }
    }

    .link-container {
      display: flex;
      align-items: center;
      text-wrap: no-wrap;
      // padding: ${({ collapsed }) => (collapsed ? "0px" : "0px 12px")};
      color: #757575;

      &:hover {
        .tooltiptext {
          visibility: visible;
        }
      }

      &:hover {
        background: #f6f6f6;
        border-radius: 8px;
      }

      p {
        visibility: ${({ collapsed }) => (collapsed ? "hidden" : "visible")};
        transition-delay: ${({ collapsed }) => (collapsed ? "0" : "0.2s")};
      }

      @media (${device.tabletMax}) {
        max-height: 40px;
      }
    }
  }
`;

export const NavSiderSubMenu = styled.div<NavSiderStyledProps>`
  &.closed {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: height 0.3s ease, opacity 0.3s ease;
    margin-top: 0;
    padding: 0 0 0 50px;

    .sub-menu-container {
      display: flex;
      flex-direction: column;
      gap: 10px;

      a {
        opacity: 0;
        transition: opacity 0.3s ease;
        color: #757575;
      }
    }
  }

  &.show {
    height: auto;
    opacity: 1;
    margin-top: ${({ opened, collapsed }) =>
      opened && !collapsed ? "12px" : "0px"};

    .sub-menu-container {
      position: relative;
      padding: ${({ collapsed }) => (collapsed ? "0px" : "0 11px 0 33px")};
      list-style: none;

      &:before {
        content: "";
        visibility: ${({ collapsed }) => (collapsed ? "hidden" : "visible")};
        height: calc(100% - 19px);
        borded-radius: 0px 0px 8px 0px;
        width: 2px;
        background: #b2b2b2;
        position: absolute;
        left: 33px;
      }

      div {
        display: flex;
        align-items: center;
        position: relative;

        .curve-line {
          height: 5px;
          width: 12px;
          border-bottom: 2px solid #b2b2b2;
          border-left: 2px solid #b2b2b2;
          border-radius: 0px 0px 0px 8px;
        }

        li {
          width: 100%;
          padding: 8px 0px 8px 12px;

          &:hover {
            background: #f6f6f6;
            border-radius: 8px;
          }
        }
      }

      a {
        display: inline-block;
        width: 100%;
        opacity: 1;
      }
    }
  }

  &.collapsed-open {
    position: absolute;
    opacity: 1;
    width: 172px;
    height: fit-content;
    background: white;
    top: 50%;
    left: 100%;
    margin-left: 12px;
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 8px;
    transition: margin-top 0.3s ease;

    .sub-menu-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 0px;

      li {
        border-radius: 8px;
        position: inherit;
        height: fit-content;
        padding: 8px 8px 8px 8px !important;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -2%;
        cursor: pointer;
        color: #757575;
        position: relative;

        a {
          color: #757575;
        }
      }
    }
  }

  &.lesson-requests {
    width: ${({ language }) =>
      language === "en" ? "200px" : "172px"} !important;
  }
`;

export const EllipsisLink = styled(NavLink)`
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NavSiderTooltip = styled.div<{
  rect: DOMRect | null;
  isVisible: boolean;
}>`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  max-width: 200px;
  white-space: nowrap;
  position: absolute;
  left: 100%;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    left: -7px;
    top: calc(50% - 5px);
    transform: rotate(-90deg);
    width: 10px;
    height: 10px;
    background: black;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    border-radius: 2px;
  }
`;

export const ChevronVerticalIcon = styled(ChevronIconStyled)<{
  opened: string | undefined;
  collapsed: string | undefined;
}>`
  transform: ${({ opened }) => (opened ? "rotate(270deg)" : "rotate(90deg)")};
  visibility: ${({ collapsed }) => (collapsed ? "hidden" : "visible")};

  transition: transform 0.1s ease-out, visibility 0.05s ease-out;

  cursor: pointer;
  stroke: #757575;
  fill: #757575;
  position: absolute;
  right: 15px;
`;
