import { useState, useMemo } from "react";

export const useFormattedMoreMessage = (
  message: string | null,
  maxSymbols: number
) => {
  const [isShowMore, setIsShowMore] = useState(false);

  const handleIsShowMore = () => {
    setIsShowMore((prev) => !prev);
  };

  const isMessageLongerThanMaxSymbols = useMemo(() => {
    return message ? message.length > maxSymbols : false;
  }, [message, maxSymbols]);

  const formattedMessage = useMemo(() => {
    if (!message) return "";
    if (isShowMore || !isMessageLongerThanMaxSymbols) {
      return message;
    }
    return message.slice(0, maxSymbols);
  }, [message, isShowMore, maxSymbols, isMessageLongerThanMaxSymbols]);

  return {
    isMessageLongerThanMaxSymbols,
    formattedMessage,
    isShowMore,
    handleIsShowMore,
  };
};
