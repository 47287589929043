import {FC} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {IssueDetailsSectionStyled} from "./issue-details-section.styled";
import {useTicketDetails} from "../../../../../../hooks/query-hooks";
import {CustomLoader} from "../../../../../elements/custom-loader";
import {ErrorPage, ErrorPageTypes} from "../../../../error-page";
import {BackArrow} from "../../../../../../assets/images/svg-elements/back-arrow";
import {getTypeOptions} from "../../../../../../types";
import {getTicketStatus} from "../../../../support-page";
import {StyledFile} from "../../../../support-page/ticket-details/ticket-details.styled";
import {formatSizeUnits, handleDownloadFile} from "../../../../../../services/file-service";
import {FileIcon} from "../../../../../../assets/images/svg-elements/file";
import {getSimplifiedDate} from "../../../../../../services/date-service";
import {TType} from "../../../../../../assets/translations";
import {ResolveTicketForm} from "../../elements/resolve-ticket-form";

interface Props {
  t: TType;
}

export const IssueDetailsSection:FC<Props> = ({t})=> {

  const navigate = useNavigate();

  const {id} = useParams();

  const {data: ticketDetails, isFetching, isError, refetch} = useTicketDetails({id: String(id), enabled: !!id});

  if (isFetching) {
    return <CustomLoader wrapperHeight="100vh"/>
  }

  if (isError || !id) {
    return <ErrorPage t={t} type={ErrorPageTypes.SOMETHING_WRONG}/>
  }

  return(
    <IssueDetailsSectionStyled>
      <div className="navigation">
        <button onClick={() => navigate(-1)}><BackArrow/>{t("back")}</button>
      </div>

      <div className="content">
        <div className="issue">
          <div className="header">{t("support.issue")}</div>

          <div className="content">
            <div className="top-info">
              <div className="type">
                {getTypeOptions(t).find(o => o.value === ticketDetails!.type)!.name}
              </div>

              {getTicketStatus(ticketDetails!.status, t)}
            </div>

            <div className="description">{ticketDetails!.issueDetails}</div>

            {ticketDetails!.medias.length > 0 && <div className="attachments">
                <p>{t("support.issue-medias")}</p>
                <div className="files">
                  {ticketDetails!.medias.map((file) =>
                    <StyledFile key={file.id} onClick={() => handleDownloadFile(file, t)}>
                      <div className="file-icon"><FileIcon/></div>
                      <div className="file-info">
                        <h2>{file.filename}</h2>
                        <p>{formatSizeUnits(file.fileSize, t)}</p>
                      </div>
                    </StyledFile>)}
                </div>
            </div>}

            <div className="date">{getSimplifiedDate(ticketDetails!.createdDate)}</div>
          </div>
        </div>

        <div className="resolve">
          <div className="header">{t("support.resolve")}</div>
          <div className="content">
            <ResolveTicketForm ticketDetails={ticketDetails!} triggerTicketDetails={refetch}/>
          </div>
        </div>
      </div>
    </IssueDetailsSectionStyled>
  )
}