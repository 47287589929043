import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
  lazy,
  Suspense,
  useEffect,
} from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import jwt_decode from "jwt-decode";
import {v4 as uuid} from "uuid";
import ReactGA from "react-ga4";
import {useMediaQuery} from "react-responsive";

import {
  Filter,
  TagsRemoveMethodsBindings,
  initialFilterValue,
  ErrorPageTypes,
} from "../components/pages";
import {CookieConsentModal} from "../components/modals/cookie-consent";
import {Feedback} from "../components/elements/feedback";
import {TeachingMeErrorNotification} from "../components/elements/notification";
import {TeachingMeFooter} from "../components/elements/footer";
import {TeachingMeHeader} from "../components/elements/header";
import {
  RESET_SEARCH_CATEGORY,
  UPDATE_SEARCH_CATEGORY,
  SET_CATEGORIES,
  SET_COUNTRIES,
  SET_CURRENCIES,
  SHOW_REGISTRATION_THIRD_STEP_MODAL,
  HIDE_REGISTRATION_THIRD_STEP_MODAL,
  RootState,
  updateUserInfo,
  updateTokens,
  authUser,
  setRegistrationStepAction, SET_PRICE_RANGE,
} from "../components/redux";
import {useLocationChange} from "../hooks";
import {TeachersSecureRoute} from "./TeacherSecureRoute";
import {MobileSecureRoute} from "./MobileSecureRoute";
import {SecureRoute} from "./SecureRoute";
import {getCountries, getUserProfile} from "../api/user-api";
import {getCategories, getCurrencies, postCategoriesSearch} from "../api/category-api";
import CookieService, {sessionIdKey} from "../services/cookie-service";
import {device} from "../components/constants";
import {Authority, Category, TCategoriesSearchResponse, Teacher, TJwtTokenEncoded} from "../types";
import {TType, withTranslate} from "../assets/translations";
import {TranslationsContext} from "../assets/translations/translationsContext";
import {CustomLoader} from "../components/elements/custom-loader";
import {RoutesCustomLoaderWrapper} from "../components/elements/custom-loader/custom-loader.styled";
import {
  loadFromLs,
  logoutClickKey,
  refreshTokenKey,
  removeFromLS,
  saveToLs,
  tokenKey
} from "../services/storage-service";
import {today} from "../services/date-service";
import {removeUndefinedValues, sendAnalyticEvent} from "../services/user-service";
import {useTopCategories} from "../hooks/query-hooks";
import {getPriceRange} from "../api/category-api/getPriceRange";
import {ConnectRequestPage} from "../components/pages/connect-request-page";
import {StepTwo} from "../components/pages/connect-request-page/steps/step-two";
import {StepOne} from "../components/pages/connect-request-page/steps/step-one";
import MainLayout from "../components/layout-components/layout/main-layout";
import { useGetUser } from "../hooks/useGetUser";
import ProfileSider from "../components/elements/profile-sider/profile-sider";
import { ContentWrapper } from "../components/layout-components/layout/main-layout-styled";
import { useRenderSider } from "../hooks/useRenderSider";
import LessonRequestPage from "../components/pages/lesson-request-page/lesson-request";
import { useSiderContext } from "../context/SiderContext";
import {BackOfficePage} from "../components/pages/back-office-page";
import {AdminSecureRoute} from "./AdminSecureRoute";


const HomePage = lazy(() => import("../components/pages/home-page/home-page").then(module => ({default: module.HomePage})));
const MainPage = lazy(() => import("../components/pages/home-page/main-page").then(module => ({default: module.MainPage})));
const SearchPage = lazy(() => import("../components/pages/home-page/search-page").then(module => ({default: module.SearchPage})));
const MessengerPage = lazy(() => import("../components/pages/messenger-page").then(module => ({default: module.MessengerPage})));
const PrivacyPolicyPage = lazy(() => import("../components/pages/privacy-policy-page").then(module => ({default: module.PrivacyPolicyPage})));
const TermsPage = lazy(() => import("../components/pages/terms-page").then(module => ({default: module.TermsPage})));
const LogoutPage = lazy(() => import("../components/pages/logout-page").then(module => ({default: module.LogoutPage})));
const MyLessonsPage = lazy(() => import("../components/pages/my-lessons-page").then(module => ({default: module.MyLessonsPage})));
const MeetingPageWithZoom = lazy(() => import("../hoc/meeting-page-with-zoom").then(module => ({default: module.MeetingPageWithZoom})));
const JoinMeetingPage = lazy(() => import("../components/pages/meeting-page").then(module => ({default: module.JoinMeetingPage})));
const MySchedule = lazy(() => import("../components/pages/my-schedule-page").then(module => ({default: module.MySchedule})));
const FavouritesPage = lazy(() => import("../components/pages/favourites-page").then(module => ({default: module.FavouritesPage})));
const CreateLessonPage = lazy(() => import("../components/pages/create-lesson-page").then(module => ({default: module.CreateLessonPage})));
const DrawboardPage = lazy(() => import("../components/pages/drawboard-page").then(module => ({default: module.DrawboardPage})));
const EditLessonPage = lazy(() => import("../components/pages/edit-lesson-page").then(module => ({default: module.EditLessonPage})));
const ErrorPage = lazy(() => import("../components/pages/error-page").then(module => ({default: module.ErrorPage})));
const UsersDetailsPage = lazy(() => import("../components/pages/user-details").then(module => ({default: module.UsersDetailsPage})));
const SettingsPage = lazy(() => import("../components/pages/settings-page").then(module => ({default: module.SettingsPage})));
const ProfilePage = lazy(() => import("../components/pages/profile-page").then(module => ({default: module.ProfilePage})));
const LessonDetailsPage = lazy(() => import("../components/pages/lesson-details-page").then(module => ({default: module.LessonDetailsPage})));
const ForgotPasswordFirstPageMobile = lazy(() => import("../components/pages/mobile/forgot-password-first-page").then(module => ({default: module.ForgotPasswordFirstPageMobile})));
const ResetPasswordPageMobile = lazy(() => import("../components/pages/mobile/reset-password-page").then(module => ({default: module.ResetPasswordPageMobile})));
const LoginPageMobile = lazy(() => import("../components/pages/mobile/login-page-mobile").then(module => ({default: module.LoginPageMobile})));
const NotificationsPage = lazy(() => import("../components/pages/mobile/notifications-page-mobile").then(module => ({default: module.NotificationsPage})));
const SignUpChooseRolePageMobile = lazy(() => import("../components/pages/mobile/sign-up-choose-role-page-mobile").then(module => ({default: module.SignUpChooseRolePageMobile})));
const SignUpConfirmEmailPageMobile = lazy(() => import("../components/pages/mobile/sign-up-confirm-email-page-mobile").then(module => ({default: module.SignUpConfirmEmailPageMobile})));
const SignUpPageMobile = lazy(() => import("../components/pages/mobile/sign-up-page-mobile").then(module => ({default: module.SignUpPageMobile})));
const RegistrationPageMobile = lazy(() => import("../components/pages/mobile/registration-third-step-page-mobile").then(module => ({default: module.RegistrationPageMobile})));
const UploadTaskResultPage = lazy(() => import("../components/pages/mobile/upload-task-result-page").then(module => ({default: module.UploadTaskResultPage})));
const ReviewTaskResultPage = lazy(() => import("../components/pages/mobile/review-task-result-page").then(module => ({default: module.ReviewTaskResultPage})));
const ReviewHomeworkPage = lazy(() => import("../components/pages/mobile/review-homework-page").then(module => ({default: module.ReviewHomeworkPage})));
const SupportPage = lazy(() => import("../components/pages/support-page").then(module => ({default: module.SupportPage})));
const TicketDetails = lazy(() => import("../components/pages/support-page").then(module => ({default: module.TicketDetails})));
const CreateTicket = lazy(() => import("../components/pages/support-page").then(module => ({default: module.CreateTicket})));
const MyStudentsPage = lazy(() => import("../components/pages/my-students").then(module => ({default: module.MyStudentsPage})));


interface Props {
  t: TType;
}

export const ApplicationRoutesComponent: FC<Props> = ({t}) => {

  const context = useContext(TranslationsContext);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const {categories} = useSelector((state: RootState) => state.userStore);

  const {isAuthenticated} = useSelector((state: RootState) => state.tokensStore);

  const [notificationsCount, setNotificationsCount] = useState<number>(0);

  const [messagesCount, setMessagesCount] = useState<number>(0);

  const [headerFooterVisibility, setHeaderFooterVisibility] = useState<boolean>(true);

  const [footerVisibility, setFooterVisibility] = useState<boolean>(true);

  const [isCompactFooter, setIsCompactFooter] = useState<boolean>(true);

  const [isUserDataLoading, setIsUserDataLoading] = useState<boolean>(true);

  const [showRegistration, setShowRegistration] = useState<boolean>(false);

  const {isSiderOpen, setIsSiderOpen} = useSiderContext();

  const isMobile = useMediaQuery({
    query: `(${device.mobileMax})`,
  });

  const {
    data: topCategories,
    isLoading: categoriesLoading,
    isError: categoriesError
  } = useTopCategories();

  const {user, authority} = useGetUser();

  const {shouldRenderProfileSider} = useRenderSider();

  const setIsAuthenticated = (isAuthenticated: boolean) => {
    dispatch(authUser(isAuthenticated));
  };

  const logOut = async () => {
    saveToLs(logoutClickKey, true);
    setShowRegistration(false);
    navigate("/logout");
  };

  const showRegistrationThirdStep = () => {
    isMobile ? navigate("/registration-info") : dispatch({type: SHOW_REGISTRATION_THIRD_STEP_MODAL});
  };
  
  const isStudent: boolean = authority === Authority.ROLE_STUDENT;

  async function updateProfileInfo() {
    const token = loadFromLs(tokenKey);
    const refreshToken = loadFromLs(refreshTokenKey);
    const sessionId = CookieService.get(sessionIdKey);

    if (!sessionId) {
      const cookieExpDate = new Date(today);
      cookieExpDate.setFullYear(today.getFullYear() + 1);

      CookieService.set(sessionIdKey, uuid(), {path: "/", expires: cookieExpDate});
    }

    if (token && refreshToken) {
      const jwtParsed: TJwtTokenEncoded = jwt_decode(token);
      const jwtRefreshParsed: TJwtTokenEncoded = jwt_decode(refreshToken);

      const tokenExpired = Date.now() >= Number(jwtParsed.exp) * 1000;
      const refreshTokenExpired = Date.now() >= Number(jwtRefreshParsed.exp) * 1000;

      if (tokenExpired || refreshTokenExpired) {
        setIsAuthenticated(false);
        removeFromLS(tokenKey);
        removeFromLS(refreshTokenKey);

        return setIsUserDataLoading(false);
      }

      dispatch(updateTokens({token, jwtParsed, refreshToken}));

      getUserProfile()
        .then(result => {
          dispatch(updateUserInfo(result));

          if (result.data.registrationStep) {
            dispatch(setRegistrationStepAction(result.data.registrationStep));
          }

          setShowRegistration(!!result.data.registrationStep);
        })
        .finally(() => setIsUserDataLoading(false));
    } else {
      setIsAuthenticated(false);
      setIsUserDataLoading(false);
    }
  }

  const fillRequiredData = () => {
    getCategories(context.language)
      .then(({data}) => {
        if (data.length) {
          dispatch({type: SET_CATEGORIES, categories: data});
        }
      });

    getCountries(context.language)
      .then(({data}) => {
        if (data.length) {
          dispatch({type: SET_COUNTRIES, countries: data});
        }
      });

    getCurrencies()
      .then(({data}) => {
        if (data.length) {
          dispatch({type: SET_CURRENCIES, currencies: data});
        }
      });

    getPriceRange()
      .then(({data})=>{
        if (data) {
          dispatch({type: SET_PRICE_RANGE, priceRange: data})
        }
      })
  };

  useLayoutEffect(() => {
    updateProfileInfo();

    fillRequiredData();

    const jsrsasignScript = document.createElement("script");
    jsrsasignScript.src = "https://cdn.jsdelivr.net/npm/jsrsasign@10.8.6/lib/jsrsasign-all-min.min.js";
    jsrsasignScript.defer = true;

    document.body.append(jsrsasignScript);
  }, []);

  useEffect(() => {
    if (showRegistration) {
      showRegistrationThirdStep();
    } else {
      dispatch({type: HIDE_REGISTRATION_THIRD_STEP_MODAL});
    }
  }, [showRegistration, isMobile]);

  useEffect(() => {
    let favicon = document.getElementById("favicon") as HTMLLinkElement;

    if (!favicon) {
      favicon = document.createElement('link');
      favicon.rel = 'icon';
      favicon.id = 'favicon';
      document.head.appendChild(favicon);
    }

    if (notificationsCount) {
      favicon.href = `favicon-wide-notification.png?${new Date().getTime()}`;
    } else {
      favicon.href = `favicon-wide.png?${new Date().getTime()}`;
    }
  }, [notificationsCount]);

  useLocationChange((location) => {
    let urlWithoutParams = location.pathname.replaceAll(/\/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/g, "");

    if (urlWithoutParams[urlWithoutParams.length - 1] === "/") {
      urlWithoutParams = urlWithoutParams.slice(0, -1);
    }

    ReactGA.send({hitType: "pageview", page: urlWithoutParams, title: urlWithoutParams});
  });

  const [filterData, setFilterData] = useState<Filter>({...initialFilterValue});
  const [tags, setTags] = useState<TagsRemoveMethodsBindings[]>([]);
  const [searchStatus, setSearchStatus] = useState<boolean>(false);
  const [searchDrawerOpen, setSearchDrawerOpen] = useState<boolean>(false);
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [teachersResponse, setTeachersResponse] = useState<TCategoriesSearchResponse>({
    data: {
      pageSize: 10,
      page: 0,
    },
  } as TCategoriesSearchResponse);
  const [teachersLoading, setTeachersLoading] = useState<boolean>(true);

  const setCategoryCode = useCallback((code: number) => dispatch({type: UPDATE_SEARCH_CATEGORY, code}), []);

  const resetCategoryCode = useCallback(() => dispatch({type: RESET_SEARCH_CATEGORY}), []);

  const clearAll = () => {
    setTags([]);
    setFilterData({...initialFilterValue});
  };

  const getCategoryByCode = useCallback((code: number | string): Category => {
    const allCategories: Category[] = categories.flatMap((cat: Category) => cat.childrenCategories!);
    return allCategories.find(cat => cat.code === +code)!;
  }, [categories]);

  const performSearch = () => {
    const searchFilters: any = removeUndefinedValues({...filterData});

    delete searchFilters.page;
    delete searchFilters.pageSize;
    delete searchFilters.sortBy;
    delete searchFilters.sortDirection;

    navigate({pathname: "/search", search: new URLSearchParams(searchFilters).toString()});

    filterData.page = 0;
    if (filterData.categories) {
      setCategoryCode(filterData.categories[0]);
      const {code, name} = getCategoryByCode(filterData.categories[0]);

      sendAnalyticEvent({
        action: "frequently_selected_categories",
        category: JSON.stringify({code, name}),
      });
    } else {
      resetCategoryCode();
    }

    const [sortBy, sortDirection] = filterData.sortBy.split(" ");

    setTeachersLoading(true);
    setSearchStatus(true);
    postCategoriesSearch(context.language, {...filterData, sortBy, sortDirection})
      .then(response => {
        if (response?.data) {
          setTeachersResponse(response);
          setTeachers(response.data.teachers);
        }
      })
      .catch(() => TeachingMeErrorNotification(t("errors.search")))
      .finally(() => setTeachersLoading(false));
  };

  const applySearchText = (event: ChangeEvent<HTMLInputElement>) => setFilterData({
    ...filterData,
    searchText: event.target.value,
  });

  const resetCategories = () => {
    setTags(prev => [...prev.filter(tag => tag.id !== "categoryTag")]);

    setFilterData(prev => ({...prev, categories: undefined}));
  };

  const addCategoryTag = (label: string, code: number): void => {
    setTags(prev => {
      const tag = prev.find(tag => tag.id === "categoryTag");

      if (tag) {
        return [...prev.map(tag => tag.id === "categoryTag" ? {
          ...tag,
          title: label,
          removeTagMethod: resetCategories,
        } : {...tag})];
      } else {
        return [...prev, {id: "categoryTag", title: label, removeTagMethod: resetCategories}];
      }
    });

    setFilterData(prev => ({...prev, categories: [code]}));

  };


  if (isUserDataLoading) {
    return <RoutesCustomLoaderWrapper><CustomLoader/></RoutesCustomLoaderWrapper>;
  }

  return <>
    <MainLayout isAuthenticated={isAuthenticated}>
      {headerFooterVisibility && <TeachingMeHeader t={t}
                                                   setIsAuthenticated={setIsAuthenticated}
                                                   notificationsCount={notificationsCount}
                                                   setNotificationsCount={setNotificationsCount}
                                                   messagesCount={messagesCount}
                                                   setMessagesCount={setMessagesCount}
                                                   searchDrawerOpen={searchDrawerOpen}
                                                   setSearchDrawerOpen={setSearchDrawerOpen}
                                                   filterData={filterData}
                                                   setFilterData={setFilterData}
                                                   searchStatus={searchStatus}
                                                   tags={tags}
                                                   setTags={setTags}
                                                   performSearch={performSearch}
                                                   clearAll={clearAll}
                                                   applySearchText={applySearchText}
                                                   logOut={logOut}
                                                   setShowRegistration={setShowRegistration}
                                                   user={user}
      />}

      <div className="content-wrapper">
        {user && shouldRenderProfileSider && <ProfileSider administrator={authority === Authority.ROLE_ADMINISTRATOR} user={user} t={t} isOpen={isSiderOpen} setIsOpen={setIsSiderOpen}/>}
        <ContentWrapper isSiderOpen={isSiderOpen} shouldRenderProfileSider={shouldRenderProfileSider}>
            <Suspense fallback={<RoutesCustomLoaderWrapper><CustomLoader/></RoutesCustomLoaderWrapper>}>
              <Routes>
                <Route path="/" element={<TeachersSecureRoute>
                  <HomePage t={t}
                            filterData={filterData}
                            setFilterData={setFilterData}
                            searchStatus={searchStatus}
                            tags={tags}
                            setTags={setTags}
                            performSearch={performSearch}
                            clearAll={clearAll}
                            applySearchText={applySearchText}
                            setIsCompactFooter={setIsCompactFooter}
                  />
                </TeachersSecureRoute>}>
                  <Route index element={<MainPage t={t}
                                                  isAuthenticated={isAuthenticated}
                                                  filterData={filterData}
                                                  addCategoryTag={addCategoryTag}
                                                  topCategories={topCategories}
                                                  categoriesLoading={categoriesLoading}
                                                  categoriesError={categoriesError}
                                                  performSearch={performSearch}
                                                  setSearchDrawerOpen={setSearchDrawerOpen}/>}/>
                  <Route path="/register" element={<MainPage t={t}
                                                  isAuthenticated={isAuthenticated}
                                                  filterData={filterData}
                                                  addCategoryTag={addCategoryTag}
                                                  topCategories={topCategories}
                                                  categoriesLoading={categoriesLoading}
                                                  categoriesError={categoriesError}
                                                  performSearch={performSearch}
                                                  setSearchDrawerOpen={setSearchDrawerOpen}/>}/>
                  <Route path="/search" element={<SearchPage t={t}
                                                            setTeachers={setTeachers}
                                                            teachersLoading={teachersLoading}
                                                            teachersResponse={teachersResponse}
                                                            teachers={teachers}
                                                            setTeachersResponse={setTeachersResponse}
                                                            performSearch={performSearch}
                                                            filterData={filterData}
                                                            setSearchStatus={setSearchStatus}
                                                            setTags={setTags}
                                                            setFilterData={setFilterData}/>}/>
                </Route>
                <Route path="/login-mobile" element={<MobileSecureRoute>
                  <LoginPageMobile t={t}
                                  setIsAuthenticated={setIsAuthenticated}
                                  setShowRegistration={setShowRegistration}/>
                </MobileSecureRoute>}/>

                <Route path="/signup-mobile-choose-role" element={<MobileSecureRoute>
                  <SignUpChooseRolePageMobile t={t}/>
                </MobileSecureRoute>}/>

                <Route path="/signup-mobile-confirm-email" element={<MobileSecureRoute>
                  <SignUpConfirmEmailPageMobile t={t}/>
                </MobileSecureRoute>}/>

                <Route path="/signup-mobile" element={<MobileSecureRoute>
                  <SignUpPageMobile t={t} setShowRegistration={setShowRegistration}/>
                </MobileSecureRoute>}/>

                <Route path="/forgot-password-first-step-mobile" element={<MobileSecureRoute>
                  <ForgotPasswordFirstPageMobile t={t}/>
                </MobileSecureRoute>}/>

                <Route path="/reset-password" element={<MobileSecureRoute>
                  <ResetPasswordPageMobile t={t}/>
                </MobileSecureRoute>}/>

                <Route path="/registration-info" element={<MobileSecureRoute>
                  <RegistrationPageMobile t={t} setShowRegistration={setShowRegistration}/>
                </MobileSecureRoute>}/>

                <Route path="/notifications" element={<SecureRoute t={t}
                                                                  isAuthenticated={isAuthenticated}
                                                                  children={
                                                                    <MobileSecureRoute enableOnTablet>
                                                                      <NotificationsPage
                                                                        t={t}
                                                                        notificationsCount={notificationsCount}
                                                                        setNotificationsCount={setNotificationsCount}/>
                                                                    </MobileSecureRoute>}/>}/>

                <Route path="/user/settings/account" element={<SecureRoute t={t}
                                                                  isAuthenticated={isAuthenticated}
                                                                  children={<SettingsPage t={t}/>}/>}/>

                {!isStudent && <Route path="/user/settings/price-schedule" element={<SecureRoute t={t}
                                                                  isAuthenticated={isAuthenticated}
                                                                  children={<SettingsPage t={t}/>}/>}/>}

                <Route path="/user/settings/security" element={<SecureRoute t={t}
                                                                  isAuthenticated={isAuthenticated}
                                                                  children={<SettingsPage t={t}/>}/>}/>

                {!isStudent && <Route path="/user/settings/lessons" element={<SecureRoute t={t}
                                                                  isAuthenticated={isAuthenticated}
                                                                  children={<SettingsPage t={t}/>}/>}/>}

                <Route path="/user/profile" element={<SecureRoute t={t}
                                                                  isAuthenticated={isAuthenticated}
                                                                  children={<ProfilePage t={t}/>}/>}/>

                <Route path="/lessons" element={<SecureRoute t={t}
                                                            isAuthenticated={isAuthenticated}
                                                            children={<MyLessonsPage t={t}/>}/>}/>

                <Route path="/lessons/:id" element={<SecureRoute t={t}
                                                                isAuthenticated={isAuthenticated}
                                                                children={<LessonDetailsPage t={t}/>}/>}/>

                <Route path="/lessons/:id/homework"
                      element={<SecureRoute t={t}
                                            isAuthenticated={isAuthenticated}
                                            children={<EditLessonPage t={t}/>}/>}/>
                <Route path="/lessons/:id/upload"
                      element={<SecureRoute t={t}
                                            isAuthenticated={isAuthenticated}
                                            children={<MobileSecureRoute>
                                              <UploadTaskResultPage t={t}
                                                                    setHeaderFooterVisibility={setHeaderFooterVisibility}/>
                                            </MobileSecureRoute>}/>}/>

                <Route path="/lessons/:id/review"
                      element={<SecureRoute t={t}
                                            isAuthenticated={isAuthenticated}
                                            children={<MobileSecureRoute>
                                              <ReviewTaskResultPage t={t}
                                                                    setHeaderFooterVisibility={setHeaderFooterVisibility}/>
                                            </MobileSecureRoute>}/>}/>

                <Route path="/lessons/:lessonId/homework/:homeworkId/review"
                      element={<SecureRoute t={t}
                                            isAuthenticated={isAuthenticated}
                                            children={<MobileSecureRoute>
                                              <ReviewHomeworkPage t={t}
                                                                  setHeaderFooterVisibility={setHeaderFooterVisibility}/>
                                            </MobileSecureRoute>}/>}/>

                <Route path="/lesson/create"
                      element={<SecureRoute t={t}
                                            isAuthenticated={isAuthenticated}
                                            children={<CreateLessonPage t={t}/>}
                      />}/>

            <Route path="/connect-request/:teacherId"
                   element={<SecureRoute t={t}
                                         isAuthenticated={isAuthenticated}
                                         children={<ConnectRequestPage t={t}/>}
                   />}>


              <Route index element={<StepOne t={t}/>}/>

              <Route path="step-two" element={<StepTwo t={t}/>}/>

            </Route>

            <Route path="/user/details/:id"
                   element={<UsersDetailsPage t={t} setIsCompactFooter={setIsCompactFooter}/>}/>
                <Route path="/user/details/:id"
                      element={<UsersDetailsPage t={t} setIsCompactFooter={setIsCompactFooter}/>}/>

                <Route path="/schedule" element={<SecureRoute t={t}
                                                              isAuthenticated={isAuthenticated}
                                                              children={<MySchedule t={t}/>}/>}/>
                <Route path="/m" element={<SecureRoute t={t}
                                                      isAuthenticated={isAuthenticated}
                                                      children={<MessengerPage t={t} setFooterVisibility={setFooterVisibility}/>}/>}/>
                <Route path="/m/:chatId" element={<SecureRoute t={t}
                                                      isAuthenticated={isAuthenticated}
                                                      children={<MessengerPage t={t} setFooterVisibility={setFooterVisibility}/>}/>}/>


                <Route path="/favourites" element={<SecureRoute t={t}
                                                                isAuthenticated={isAuthenticated}
                                                                children={<FavouritesPage t={t}/>}/>}/>

                <Route path="/meeting/join" element={<SecureRoute t={t}
                                                                  isAuthenticated={isAuthenticated}
                                                                  children={<JoinMeetingPage t={t}/>}/>}/>

                <Route path="/meeting" element={<SecureRoute t={t}
                                                            isAuthenticated={isAuthenticated}
                                                            children={<MeetingPageWithZoom t={t}
                                                                                            setHeaderFooterVisibility={setHeaderFooterVisibility}/>}/>}/>

                <Route path="/drawboards/:id" element={<SecureRoute t={t}
                                                                    isAuthenticated={isAuthenticated}
                                                                    children={<DrawboardPage t={t}
                                                                                            setFooterVisibility={setFooterVisibility}/>}/>}/>

                <Route path="/privacy-policy" element={<PrivacyPolicyPage t={t}/>}/>

                <Route path="/support" element={<SecureRoute t={t}
                                                            isAuthenticated={isAuthenticated}
                                                            children={<SupportPage t={t}/>}/>}/>

                <Route path="/support/ticket/:id" element={<SecureRoute t={t}
                                                                        isAuthenticated={isAuthenticated}
                                                                        children={<TicketDetails t={t}/>}/>}/>

                <Route path="/support/ticket/create" element={<SecureRoute t={t}
                                                                          isAuthenticated={isAuthenticated}
                                                                          children={<CreateTicket t={t}/>}/>}/>

                <Route path="/terms-of-use" element={<TermsPage t={t}/>}/>

                <Route path={isStudent && user ? `/lesson-requests` : "/lesson-requests/requests-from-students"} element={<SecureRoute t={t} isAuthenticated={isAuthenticated} children={<LessonRequestPage t={t} user={user}/>}/>}/>
                <Route path={isStudent && user ? `/lesson-requests` : "/lesson-requests/opportunity-spot"} element={<SecureRoute t={t} isAuthenticated={isAuthenticated} children={<LessonRequestPage t={t} user={user}/>}/>}/>

                <Route path="/my-students/all-students" element={<SecureRoute t={t} isAuthenticated={isAuthenticated} children={<MyStudentsPage t={t} />}/>}/>
                <Route path="/my-students/invited-students" element={<SecureRoute t={t} isAuthenticated={isAuthenticated} children={<MyStudentsPage t={t} />}/>}/>

                <Route path="/logout" element={<LogoutPage/>}/>

                <Route path="/administrator/*" element={
                  <SecureRoute t={t} isAuthenticated={isAuthenticated}>
                    <AdminSecureRoute>
                        <BackOfficePage t={t}/>
                    </AdminSecureRoute>
                  </SecureRoute>}/>

                <Route path="*" element={<ErrorPage t={t} type={ErrorPageTypes.NOT_FOUND}/>}/>
              </Routes>
            </Suspense>
      {(headerFooterVisibility && footerVisibility) &&
          <TeachingMeFooter t={t}
                            isCompactFooter={isCompactFooter}
                            topCategories={topCategories}
                            categoriesLoading={categoriesLoading}
                            categoriesError={categoriesError}
                            performSearch={performSearch}
                            addCategoryTag={addCategoryTag}
                            filterData={filterData}/>}
        </ContentWrapper>
      </div>

    </MainLayout>
    <CookieConsentModal t={t}/>
    <Feedback t={t}/>
  </>;
};

const ApplicationRoutes = withTranslate(ApplicationRoutesComponent);

export default ApplicationRoutes;