import styled from "styled-components";

import {BadgeBorderColors, BadgeDarkColors, BadgeLightColors} from "../../../types/Badge";

export const BadgeWrapper = styled.div<{
  size: "medium" | "small" | "icon";
  color: BadgeDarkColors | BadgeLightColors;
  borderColor?: BadgeBorderColors;
  isTextLight: boolean;
  isSmallDesktop: boolean;
}>`
  @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

    display: flex;
    align-items: center;
    gap: 4px;
    padding: ${({size}) =>
            size === "medium"
                    ? "3px 12px"
                    : size === "small"
                            ? "2px 8px"
                            : size === "icon" && "3px"};
    background-color: ${({color}) => color};
    border: ${({color, borderColor}) =>
            borderColor
                    ? `1px solid ${borderColor}`
                    : color === "transparent"
                            ? "1px solid #18067D"
                            : "none"};
    width: fit-content;
    max-width: ${({size}) =>
    size === "medium"
      ? "233px"
      : size === "small"
      ? "194px"
      : size === "icon" && "26px"};
  border-radius: 100px;
    
        p {
            font-weight: 400;
            font-size: ${({ size, isSmallDesktop }) =>
                    isSmallDesktop ? "12px" : size === "medium" ? "14px" : size === "small" && "12px"};

            line-height: 20px;
            font-family: "Poppins", sans-serif;
            color: ${({ isTextLight }) => isTextLight ? "#fff" : isTextLight === null ? "#18067D" : !isTextLight && "#404040" };
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    
  img {
    min-heidth: 20px;
    min-width: 20px;
  }
`;


export const BadgeIcon = styled