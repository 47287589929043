import styled from "styled-components";

export const FeedbackDetailsSectionStyled = styled.section`

  .feedback-details-container {
      display: flex;
      
      .meta-data-container {
          width: 45%;
          border-right: 1px solid #d6e4ff;
          padding-right: 20px;
          height: auto;
          display: flex;
          flex-direction: column;
          gap: 16px;

          .email {
              font-size: 18px;
              font-weight: 500;
          }
      }
      
      .comment-container {
          padding-left: 20px;
      }
  }
`