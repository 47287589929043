import styled from 'styled-components';

import {device} from "../../constants";


export const StyledWrapper = styled.div`
  width: 100%;
  min-height: 100px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  row-gap: 40px;

  .not_found_details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1rem;

    h2, p {
      margin: 0;
      color: #000000;
      text-align: center;
    }

    h2 {
      font-weight: 500;
      font-size: 28px;
      line-height: 36px;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }

  a {
    color: rgb(191, 191, 191);
  }

  @media (${device.mobileMax}) {
    row-gap: 16px;
    padding: 0 20px;

    >svg{
      width: 100px;
      height: 80px;
    }

    .not_found_details {

      h2 {
        font-size: 20px;
        line-height: 28px;
      }

      p {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
`