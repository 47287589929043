import {GET_FILE} from "../endpoints";
import axiosClient from "../../config/axiosConfig";


export const getFile = async (fileId: string): Promise<{ data: Blob }> => {
    return axiosClient.get(GET_FILE(fileId), {
        responseType: "blob"
    });
}

