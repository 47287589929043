import {FC} from "react";
import {SupportIssueStyled} from "./support-issue.styled";
import {getTypeOptions, IFilterTicket} from "../../../../../../types";
import {useTranslate} from "../../../../../../assets/translations";
import {getTicketStatus} from "../../../../support-page";
import {ButtonTheme, StyledButton} from "../../../../../elements/button";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";

interface Props {
  issue: IFilterTicket
}

export const SupportIssue: FC<Props> = ({issue}) => {

  const {issuerEmail, createdDate, issueDetails, status, type, id} = issue;

  const {t} = useTranslate();

  const navigate = useNavigate();

  return (
    <SupportIssueStyled>
      <div className="email-and-date border-right">
        <div>
          {issuerEmail}
        </div>
        <div>
          {dayjs(createdDate).format("YYYY-MM-DD HH:mm")}
        </div>
      </div>
      <div className="issue-details-wrapper border-right">
        <div className="issue-details">
          {issueDetails}
        </div>
      </div>
      <div className="issue-type border-right">
        {getTypeOptions(t).find(option => option.value === type)!.name}
      </div>
      <div className="issue-status border-right">
        {getTicketStatus(status, t)}
      </div>
      <div className="details">
        <StyledButton onClick={() => navigate(`ticket/${id}`)} theme={ButtonTheme.INVERT_BLUE}>Деталі</StyledButton>
      </div>
    </SupportIssueStyled>
  );
}