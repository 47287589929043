import React, { FC } from "react";
import { CardActionModalWrapper } from "./card-action-modal-styled";
import { ActionButtons } from "../../pages/lesson-request-page/lesson-request-action-buttons/lesson-request-action-buttons-styled";
import { ButtonTheme } from "../../elements/button";

interface Props {
  open: boolean;
  header: string;
  paragraph?: string;
  handleRequest: () => void;
  handleCancel: () => void;
}

const CardActionModal: FC<Props> = ({
  open,
  header,
  paragraph,
  handleRequest,
  handleCancel,
}) => {
  return (
    <CardActionModalWrapper open={open} footer={null} closable={false}>
      <h1>{header}</h1>
      <p>{paragraph}</p>
      <div className="buttons">
        <ActionButtons theme={ButtonTheme.WHITE} onClick={handleCancel}>
          No
        </ActionButtons>
        <ActionButtons theme={ButtonTheme.BLUE} onClick={handleRequest}>
          Yes
        </ActionButtons>
      </div>
    </CardActionModalWrapper>
  );
};

export default CardActionModal;
