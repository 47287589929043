import React, { FC } from "react";
import { ActionButtons } from "../lesson-request-action-buttons-styled";
import { ButtonTheme } from "../../../../elements/button";

interface ApprovePublicRequestButtonProps {
  clarificationNeeded: boolean;
  setIsClarificationNeededPopupOpen: (
    value: React.SetStateAction<boolean>
  ) => void;
  setIsAcceptPopupOpen: (value: React.SetStateAction<boolean>) => void;
  setIsWrongCategoryPopupOpen: (value: React.SetStateAction<boolean>) => void;
  children: string | React.ReactElement;
  disabled?: boolean;
  isTeacherCategory?: boolean;
}

const ApprovePublicRequestButton: FC<ApprovePublicRequestButtonProps> = ({
  clarificationNeeded,
  setIsAcceptPopupOpen,
  setIsClarificationNeededPopupOpen,
  children,
  disabled,
  setIsWrongCategoryPopupOpen,
  isTeacherCategory
}) => {
  return (
    <ActionButtons
      disabled={disabled}
      theme={ButtonTheme.DARK_BLUE}
      onClick={() => {
        if (clarificationNeeded) {
          setIsClarificationNeededPopupOpen(true);
          return;
        } else if (!isTeacherCategory) {
          setIsWrongCategoryPopupOpen(true);
          return
        }
        else {
          setIsAcceptPopupOpen(true);
        }
      }}
    >
      {children}
    </ActionButtons>
  );
};

export default ApprovePublicRequestButton;
