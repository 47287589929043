import styled from "styled-components";
import { device } from "../../constants";

export const BurgerMenuButtonStyled = styled.div`
  height: 15px;
  width: 15px;
  margin-left: auto;
  position: relative;

  span {
    display: inline-block;
    height: 2px;
    width: 100%;
    background: black;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s ease;

    &:nth-child(1) {
      top: 25%;
    }

    &:nth-child(3) {
      top: 75%;
    }
  }

  &.open {
    span {
      &:nth-child(1) {
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  @media (${device.mobileMax}) {
    width: 20px;
    height: 20px;
  }
`;
