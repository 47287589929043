import {FC, ReactNode, useCallback} from "react";
import {useNavigate} from "react-router-dom";

import {NotFoundComponent} from "../../elements/not-found-component";
import {ErrorPage, ErrorPageTypes} from "../error-page";
import {useTickets} from "../../../hooks/query-hooks";
import {TType, useTranslate} from "../../../assets/translations";
import {TicketStatus} from "../../../types";
import {ClosedStatus, ContentContainer, InProgressStatus, OpenStatus, ResolvedStatus} from "./support-page.styled";
import {PlusIcon} from "../../../assets/images/svg-elements/plus";
import {BackArrow} from "../../../assets/images/svg-elements/back-arrow";
import {CustomLoader} from "../../elements/custom-loader";
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/uk';
import WithSiderWrapper from "../../layout-components/wrappers/page-with-sider/page-with-sider";

dayjs.extend(localizedFormat);

interface Props {
    t: TType;
}

export const getTicketStatus = (status: TicketStatus, t: TType): ReactNode => {
    switch (status) {
        case TicketStatus.OPEN:
            return <OpenStatus data-text={t("support.ticket-open")}/>
        case TicketStatus.IN_PROGRESS:
            return <InProgressStatus data-text={t("support.ticket-in-progress")}/>
        case TicketStatus.RESOLVED:
            return <ResolvedStatus data-text={t("support.ticket-resolved")}/>
        case TicketStatus.CLOSED:
            return <ClosedStatus data-text={t("support.ticket-closed")}/>
        default:
            return <OpenStatus data-text={t("support.ticket-open")}/>
    }
}

export const SupportPage: FC<Props> = ({t}) => {

    const navigate = useNavigate();

    const {language} = useTranslate();

    const {isFetching, data: tickets, isError} = useTickets();

    const createTicket = useCallback(() => navigate("ticket/create"), []);

    const openTicket = useCallback((id: string) => navigate(`ticket/${id}`), []);


    if (isError) {
        return <ErrorPage t={t} type={ErrorPageTypes.SOMETHING_WRONG}/>
    }

    return <WithSiderWrapper background="#ECEFF2">
        <ContentContainer className="content-container">
            <div className="header">
                <div className="caption">
                    <h2>{t("support.title")}</h2>
                    <p>{t("support.caption")}</p>
                </div>

                <div className="create" onClick={createTicket}>
                    <PlusIcon/><p>{t("support.create")}</p>
                </div>
            </div>
            {isFetching ? <CustomLoader/> :
                (tickets && tickets.length > 0) ?
                    <div className="tickets">
                        {tickets.map(ticket => <div className="ticket" key={ticket.id}>
                            <div className="info">
                                <p className="issue" onClick={() => openTicket(ticket.id)}>{ticket.issueDetails}</p>
                                <p className="date">{dayjs(ticket.createdDate).locale(language).format("DD MMM YY,  HH:mm")}</p>
                            </div>
                            <div className="details">
                                {getTicketStatus(ticket.status, t)}
                                <BackArrow className="show-details-arrow" onClick={() => openTicket(ticket.id)}/>
                            </div>
                        </div>)}
                    </div>
                    : <NotFoundComponent text={t("support.no-tickets")}
                                        hint={t("support.no-tickets-hint")}/>}
        </ContentContainer>
    </WithSiderWrapper>
};
