import styled from "styled-components";

import { StyledButton } from "../../../elements/button";

export const ActionButtons = styled(StyledButton)`
  padding: 8px 15px;
  font-size: 12px;
  line-height: 22px;
  flex: 1;
  max-height: 40px;

  &:has(svg) {
    padding: 7px 9px;
  }
`;

export const CardMessageButtonStyled = styled(ActionButtons)`
  flex: 1;
`;
