import styled from "styled-components";

export const UserDetailsSectionStyled = styled.section`

    .user-details-card {
        display: flex;
        gap: 188px;
        justify-content: space-between;
        
        .user-info {
            display: flex;
            flex-direction: column;
            max-width: 800px;
            gap: 24px;


            .picture-and-name {
                display: flex;
                gap: 24px;
                align-items: center;

                img {
                    border-radius: 50%;
                    width: 58px;
                    height: 58px;
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 50px;

                    p:first-child {
                        font-weight: 500;
                    }

                    p:nth-child(2) {
                        color: rgb(140, 140, 140);
                    }
                }

            }

            .about-user {
                margin-left: 82px;
            }

        }
    }
    
    .action-buttons-container {
        display: flex;
        gap: 16px;
    }
`