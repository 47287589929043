import axiosClient from "../../config/axiosConfig";

import { PUT_CONNECTION_REQUESTS } from "../endpoints";

export const deleteConnectionRequest = async (connectionRequestId: string) => {
  return axiosClient.delete(PUT_CONNECTION_REQUESTS(connectionRequestId), {
    params: {
      connectionRequestId,
    },
  });
};
