import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getStudentProfileInfo,
  getUserProfile,
} from "../api/user-api";
import { RootState } from "../components/redux";
import {
  AdminProfile,
  Authority,
  StudentProfile,
  TUserProfileData,
} from "../types";
import { useQuery } from "@tanstack/react-query";

const mockAdminData: AdminProfile = {
  firstName: "Admin",
  lastName: "Admin",
  avatarId: "",
};

export function useGetUser() {
  const { jwtParsed } = useSelector((state: RootState) => state.tokensStore);
  const [user, setUser] = useState<
    TUserProfileData | AdminProfile | null
  >(null);

  const {data, isLoading} = useQuery({
    queryFn: async () => (await getUserProfile()).data,
    queryKey: ["UserProfile"],
    enabled: true
  })

  useEffect(() => {
    if (!jwtParsed?.authority) {
      return;
    }

    const isAdmin = jwtParsed.authority === Authority.ROLE_ADMINISTRATOR;

    if (isAdmin) {
      setUser({ ...mockAdminData });
      return;
    } else {
      if (!data) return
      setUser(data)
    }
  }, [jwtParsed?.authority, data]);

  return {
    user,
    authority: jwtParsed?.authority,
  };
}
