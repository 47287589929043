export const BASE_URL = () => process.env.REACT_APP_API_BASE_URL;
export const GOOGLE_USERINFO = () => "https://www.googleapis.com/oauth2/v3/userinfo";
export const GET_IMAGES = (imageName: string) => `https://storage.googleapis.com/teaching-me-media-test/images/${imageName}`;

export const CATEGORIES = () => `/categories/v1/open/categories`;
export const CURRENCIES = () => `/categories/v1/open/currencies`;
export const GET_SKILLS = (userId: string) => `/categories/v1/open/users/${userId}/categories`;
export const POST_SEARCH_CATEGORIES = () => `/categories/v1/search`;
export const POST_SEARCH_CATEGORIES_OPEN = () => `/categories/v1/open/search`;
export const GET_TEACHERS_BY_LABEL = (label: string) => `/categories/v1/open/labels/${label}/search`;
export const GET_CATEGORY_PRICE = (teacherId: string, categoryId: string) => `/categories/v1/open/categories/${categoryId}/users/${teacherId}/price`;
export const GET_TOP_CATEGORIES = () => `/categories/v1/open/top-categories`;
export const POST_SKILLS = () => `/categories/v1/users/teacher/categories`;
export const GET_PRICE_RANGE = ()=> `/categories/v1/open/categories/price-range`;
export const GET_SUBCATEGORIES = () => `/categories/v1/open/subcategories`;
export const ADMIN_LABELS = ()=> `/categories/v1/admin/labels`;
export const POST_RECALCULATE_INDEX = ()=> `/categories/v1/open/categories/recalculateIndex`

export const GET_LANGUAGES = () => `/users/v1/open/languages`;
export const CHATS_BY_USER_IDS = () => `/users/v1/users/chats`;
export const POST_FEEDBACK = () => `/users/v1/open/feedback`;
export const GET_NOTIFICATIONS = () => `/users/v1/notifications`;
export const POST_CHECK_NOTIFICATIONS = (notificationId: number) => `/users/v1/notifications/${notificationId}/read`;
export const GET_COUNTRIES = () => `/users/v1/open/countries`;
export const GET_CITIES = () => `/users/v1/open/cities`;
export const GET_TEACHER_PROFILE_SETUP = () => `/users/v1/profile/setup`;
export const GET_STUDENT_PROFILE_INFO = () => `/users/v1/users/student/profile`;
export const GET_TEACHER_PROFILE_BASIC_INFO = () => `/users/v1/users/teacher/profile/basicInfo`;
export const GET_AI_QUOTA = ()=> `/users/v1/ai/quota`
export const UPDATE_TEACHER_PROFILE_BASIC_INFO = () => `/users/v1/users/verification`;
export const GET_TEACHER_PROFILE_OTHER_INFO = () => `/users/v1/users/teacher/profile/otherInfo`;
export const USER_PROFILE = () => `/users/v1/users/profile`;
export const POST_TEACHER_PROFILE = () => `/users/v1/teachers/profile`;
export const USER_UPLOAD_PHOTO = () => `/users/v1/users/profile/uploadPhoto`;
export const POST_AI_GENERATE_USER_DESCRIPTION = () => `/users/v1/ai/teacher-description`;
export const POST_PHONE_VERIFICATION_CODE = () => `/users/v1/users/phone-number/validate`;
export const POST_COMPLETE_REGISTRATION = () => `/users/v1/users/registration/complete`;
export const POST_SEND_PHONE_VERIFICATION_CODE = () => `/users/v1/users/phone-number/code`;
export const POST_LOGIN_USER = () => `/users/v1/open/auth/login`;
export const REFRESH_TOKEN = () => `/users/v1/open/auth/refresh`;
export const POST_RESET_PASSWORD_EMAIL = () => `/users/v1/open/auth/reset-password-email`;
export const POST_RESET_PASSWORD = () => `/users/v1/open/auth/reset-password`;
export const POST_VERIFY_CODE_AND_UPDATE_EMAIL = () => `/users/v1/users/profile/validate-and-update-email`;
export const POST_VALIDATE_EMAIL = () => `/users/v1/open/auth/validate/email`;
export const POST_VALIDATE_EMAIL_VERIFICATION_CODE = () => `/users/v1/open/auth/email/validate`;
export const POST_EMAIL_VERIFICATION_CODE = () => `/users/v1/open/auth/email/code`;
export const POST_REGISTER_USER = () => `/users/v1/open/auth/register`;
export const GET_CHATS = () => `/users/v1/chats`;
export const MESSAGES = () => `/users/v1/messages`;
export const READ_MESSAGES = () => `/users/v1/messages/read`;
export const GET_FILE = (fileId: string) => `/users/v1/files/${fileId}`;
export const GET_VIDEO = (videoId: string) => `/users/v1/open/attachments/video/${videoId}/download`;
export const UPLOAD_ATTACHMENTS = () => `/users/v1/attachments/upload`;
export const GET_TEACHER_DETAILS = (id: string) => `/users/v1/user/${id}/details`;
export const GET_TEACHER_DETAILS_OPEN = (id: string) => `/users/v1/open/user/${id}/details`;
export const GET_TEACHER_REVIEWS = (id: string) => `/users/v1/open/teacher/${id}/review`;
export const FAVOURITE_TEACHER = () => `/users/v1/teachers/favourites`;
export const TEACHERS_SCHEDULE = () => `/users/v1/teacher/schedule`;
export const GET_TEACHERS_SCHEDULE = (teacherId: string) => `/users/v1/open/teacher/${teacherId}/schedule`;
export const GET_ATTACHMENTS = (id: number) => `/users/v1/chats/${id}/attachments`;
export const GET_ATTACHMENTS_COUNT = (id: number) => `/users/v1/chats/${id}/attachments/count`;
export const PHONE_VALIDATION = () => `/users/v1/users/phone-number/uniqueness`;
export const UPLOAD_VIDEO = () => `/users/v1/attachments/video/upload`;
export const POST_PROFILE_VIEW = () => `/users/v1/open/users/profile/view`;
export const POST_READ_NOTIFICATIONS = () => `/users/v1/notifications/read`;
export const TICKETS = () => `/users/v1/support-tickets`;
export const GET_TICKET = (ticketId: string) => `/users/v1/support-tickets/${ticketId}`;
export const POST_ASSIGN_TICKET = (ticketId: string) => `/users/v1/support-tickets/${ticketId}/assign`;
export const POST_RESOLVE_TICKET = (ticketId: string) => `/users/v1/support-tickets/${ticketId}/resolve`;
export const GET_TICKETS_FILTER = ()=> `/users/v1/support-tickets/filter`;
export const WS_NOTIFICATIONS = (email: string) => `/open/users/${email}/notifications`;
export const WS_CHATS = (email: string) => `/open/users/${email}/chats`;
export const WS_CHAT_MESSAGES = (selectedChatId: number) => `/open/users/chats/${selectedChatId}/messages`;
export const WS_DRAWBOARD_SEND = (userId: string, drawboardId: string) => `/open/users/${userId}/drawboards/${drawboardId}/operations`;
export const WS_DRAWBOARD = (drawboardId: string) => `/open/users/drawboards/${drawboardId}/operations`;
export const POST_SUBSCRIBE_FOR_NEWS = () => `/users/v1/open/news-subscribers`;
export const GET_LESSON_SCHEDULE = (teacherId: string)=> `/users/v1/open/teacher/${teacherId}/lesson-schedule`
export const ERROR_EXCEPTIONS = () => `/users/v1/open/exceptions`;
export const DELETE_USER_ACCOUNT = ()=>`/users/v1/users/delete`;
export const GET_TEACHER_STUDENT_MAPPINGS = ()=>`/users/v1/teacher-student-mappings`;
export const INVITE_USER = () => `/users/v1/users/invite`;
export const GET_INVITED_USERS = () => `/users/v1/users/invites`;
export const PUT_TEACHER_STUDENT_MAPPINGS = (mappingsId: string) => `/users/v1/teacher-student-mappings/${mappingsId}`;
export const GET_FEEDBACKS = ()=> `/users/v1/feedbacks`;
export const GET_FEEDBACK_DETAILS = (feedbackId: string) => `/users/v1/feedbacks/${feedbackId}`;
export const GET_USERS = ()=> `/users/v1/users`;
export const POST_TEACHER_REVIEW = (teacherId: string) => `/users/v1/teacher/${teacherId}/review`;

export const GET_LESSONS = () => `/lessons/v1/lessons/filter`;
export const GET_LESSON_SETTINGS = () => `/lessons/v1/lessons/settings`;
export const PUT_LESSON_SETTINGS = () => `/lessons/v1/lessons/settings`;
export const GET_ACTIVE_STUDENTS = () => `/lessons/v1/students/active`;
export const GET_ALL_TODO_HOMEWORKS = () => `/lessons/v1/homeworks/profile`;
export const GET_LESSON_DETAILS = (id: string) => `/lessons/v1/lessons/${id}`;
export const POST_CANCEL_LESSON = (id: string) => `/lessons/v1/lessons/${id}/cancel`;
export const POST_APPROVE_LESSON = (id: string) => `/lessons/v1/lessons/${id}/approve`;
export const POST_COMPLETE_LESSON = (id: string) => `/lessons/v1/lessons/${id}/complete`;
export const POST_CREATE_LESSON = () => `/lessons/v1/lessons`;
export const GET_LESSONS_BY_USER_ID = () => `/lessons/v1/open/lessons`;
export const POST_LESSON_REVIEW = (lessonId: string) => `/lessons/v1/lessons/${lessonId}/review`;
export const POST_UPLOAD_TASK_RESULT = (lessonId: string, homeworkId: string, taskId: string) => `/lessons/v1/lessons/${lessonId}/homeworks/${homeworkId}/tasks/${taskId}/result`;
export const POST_SEND_HOMEWORK = (lessonId: string, homeworkId: string) => `/lessons/v1/lessons/${lessonId}/homeworks/${homeworkId}/send-for-review`;
export const POST_REVIEW_TASK_RESULT = (lessonId: string, homeworkId: string, taskId: string) => `/lessons/v1/lessons/${lessonId}/homeworks/${homeworkId}/tasks/${taskId}/review`;
export const POST_TASK = (lessonId: string, homeworkId: string) => `/lessons/v1/lessons/${lessonId}/homeworks/${homeworkId}/tasks`;
export const PUT_DELETE_TASK = (lessonId: string, homeworkId: string, taskId: string) => `/lessons/v1/lessons/${lessonId}/homeworks/${homeworkId}/tasks/${taskId}`;
export const POST_REVIEW_HOMEWORK = (lessonId: string, homeworkId: string) => `/lessons/v1/lessons/${lessonId}/homeworks/${homeworkId}/review`;
export const GET_HOMEWORK = (lessonId: string, homeworkId: string) => `/lessons/v1/lessons/${lessonId}/homeworks/${homeworkId}`;
export const POST_CAPTURE_INVOICE = (invoiceId: string) => `/lessons/v1/invoice/${invoiceId}/capture`;
export const POST_CREATE_HOMEWORK = (lessonId: string) => `/lessons/v1/lessons/${lessonId}/homeworks`;
export const PUT_UPDATE_HOMEWORK = (lessonId: string, homeworkId: string) => `/lessons/v1/lessons/${lessonId}/homeworks/${homeworkId}`;
export const POST_START_MEETING = (lessonId: string, meetingId: string) => `/lessons/v1/lessons/${lessonId}/meetings/${meetingId}/start`;
export const POST_END_MEETING = (lessonId: string, meetingId: string) => `/lessons/v1/lessons/${lessonId}/meetings/${meetingId}/finalize`;
export const GET_RESPONSE_TIME = () => `/lessons/v1/open/lessons/response-to-request-time`;
export const DRAWBOARDS = () => `/lessons/v1/drawboards`;
export const DRAWBOARD_OPERATIONS = (drawboardId: string) => `/lessons/v1/drawboards/${drawboardId}/operations`;
export const LESSONS_COUNT = () => `/lessons/v1/open/lessons/count`;
export const POST_CONNECTION_REQUEST_PUBLIC = () => `/lessons/v1/connection-requests/public`;
export const POST_CONNECTION_REQUESTS_DIRECT = ()=> `/lessons/v1/connection-requests/direct`;
export const GET_CONNECTION_REQUESTS_LATEST_DIRECT = ()=> `/lessons/v1/connection-requests/latest-direct`;
export const GET_CONNECTION_REQUESTS = () => "/lessons/v1/connection-requests";
export const PUT_CONNECTION_REQUESTS = (connectionRequestId: string) => `/lessons/v1/connection-requests/${connectionRequestId}`;
export const PUT_DIRECT_CONNECTION_REQUESTS = (connectionRequestId: string) => `/lessons/v1/connection-requests/direct/${connectionRequestId}`;
export const POST_USER_APPLICATIONS = (connectionRequestId: string) => `/lessons/v1/connection-requests/${connectionRequestId}/user-applications`;
export const POST_ACCEPT_APLICANT = (connectionRequestId: string, applicationId: string) => `/lessons/v1/connection-requests/${connectionRequestId}/user-applications/${applicationId}/process`;
export const PUT_DECLINE_APLICANT = (connectionRequestId: string, applicationId: string) => `/lessons/v1/connection-requests/${connectionRequestId}/user-applications/${applicationId}`;