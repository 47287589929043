import React, { useState } from "react";

import { NavLink } from "react-router-dom";

import {
  ChevronVerticalIcon,
  NavSiderSubMenu,
  NavSiderTooltip,
  NavSiderMenu,
  EllipsisLink,
} from "./profile-sider-menu-styled";

import { ProfileSiderMenuItems } from "../../../../types/ProfileSider";
import {
  NavSiderButtonItem,
  NavSiderImageBlock,
  SiderBadge,
} from "../profile-sider-styled";
import { createPortal } from "react-dom";
import { useVisibleWithRect } from "../../../../hooks/useVisibleWithRect";
import { useTranslate } from "../../../../assets/translations";
import { useIsResponsive } from "../../../../hooks/useIsResponsive";

interface ProfileSiderMenuProps {
  title: string;
  isCollapsed: string | undefined;
  activeKey: string | undefined;
  setActiveKey: React.Dispatch<React.SetStateAction<string | undefined>>;
  items: ProfileSiderMenuItems[];
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ProfileSiderMenuButtonItemProps
  extends Omit<ProfileSiderMenuProps, "title" | "items"> {
  el: ProfileSiderMenuItems;
  setHoveredKey?: React.Dispatch<React.SetStateAction<string | null>>;
  rect?: DOMRect | null;
  isVisible?: boolean;
}

const ProfileSiderMenu = ({
  title,
  isCollapsed,
  activeKey,
  setActiveKey,
  items,
  setIsOpen
}: ProfileSiderMenuProps) => {
  const [hoveredKey, setHoveredKey] = useState<string | null>(null);

  return (
    <NavSiderMenu collapsed={isCollapsed}>
      <h5>{title}</h5>
      {items?.map((el) => (
        <li
          key={el.id}
          className={`${activeKey === el.key ? "active" : "inactive"} ${
            hoveredKey === el.key ? "tool-visible" : "tool-hiden"
          }`}
        >
          {el.children?.length ? (
            <ProfileSiderMenuButtonItem
              activeKey={activeKey}
              el={el}
              isCollapsed={isCollapsed}
              setActiveKey={setActiveKey}
              setHoveredKey={setHoveredKey}
              setIsOpen={setIsOpen}
            />
          ) : (
            <ProfileSiderMenuLinkItem
              isCollapsed={isCollapsed}
              el={el}
              to={el.to}
              setIsOpen={setIsOpen}
            />
          )}
        </li>
      ))}
    </NavSiderMenu>
  );
};

export default ProfileSiderMenu;

const ProfileSiderSubMenu = ({
  isCollapsed,
  activeKey,
  el,
  rect,
  isVisible,
  setIsOpen
}: ProfileSiderMenuButtonItemProps) => {
  const isOpened = activeKey === el.key ? "true" : undefined;

  const { language } = useTranslate();

  const { isMobile, isTablet } = useIsResponsive();

  const handleClick = () => {
    if (isMobile || isTablet) {
      setIsOpen(false)
    }
  }

  if (isCollapsed) {
    const container = document.querySelector(".sider_modal");

    if (!container) return null;

    return (
      container &&
      createPortal(
        <NavSiderSubMenu
          collapsed={isCollapsed}
          opened={isOpened}
          language={language}
          className={`${activeKey === el.key ? "show" : "hide"} ${
            isOpened && isCollapsed ? "collapsed-open" : "closed"
          } ${activeKey}`}
          style={{
            top: rect
              ? isVisible
                ? rect.top - rect.height + 24
                : rect.top - rect.height
              : 0,
            left: rect ? rect.left + rect.width - 3 : 0,
          }}
        >
          <ul className="sub-menu-container">
            {el.children?.map((child) => (
              <div key={child.id}>
                {!isCollapsed && <div className="curve-line" />}
                <li>
                  <NavLink to={child.to} onClick={handleClick}>{child.label}</NavLink>
                  {!!child.count && (
                    <SiderBadge
                      collapsed={isCollapsed}
                      opened={isOpened}
                      className="sub-menu-collapsed-badge"
                    >
                      <span>{child.count}</span>
                    </SiderBadge>
                  )}
                </li>
              </div>
            ))}
          </ul>
        </NavSiderSubMenu>,
        container
      )
    );
  }

  return (
    <NavSiderSubMenu
      collapsed={isCollapsed}
      opened={isOpened}
      className={`${activeKey === el.key ? "show" : "hide"} ${
        isOpened ? "some-class-when-opened" : "closed"
      }`}
    >
      <ul className="sub-menu-container">
        {el.children?.map((child) => (
          <div key={child.id}>
            {!isCollapsed && <div className="curve-line" />}
            <li>
              {child.count ? (
                <EllipsisLink to={child.to} onClick={handleClick}>{child.label}</EllipsisLink>
              ) : (
                <NavLink to={child.to} onClick={handleClick}>{child.label}</NavLink>
              )}
              {!!child.count && (
                <SiderBadge
                  collapsed={isCollapsed}
                  opened={isOpened}
                  language={language}
                >
                  <span>{child.count}</span>
                </SiderBadge>
              )}
            </li>
          </div>
        ))}
      </ul>
    </NavSiderSubMenu>
  );
};

const ProfileSiderMenuLinkItem = ({
  el,
  to,
  isCollapsed,
  setIsOpen
}: {
  el: ProfileSiderMenuItems;
  to: string | undefined;
  isCollapsed: string | undefined;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { isVisible, rect, ref, showElement, hideElement } =
    useVisibleWithRect<HTMLAnchorElement>();

  const { isMobile, isTablet } = useIsResponsive();

  if (!to) {
    return null;
  }

  const container = document.querySelector(".sider_modal");
  if (!container) {
    return null;
  }

  const handleClick = () => {
    if (isMobile || isTablet) {
      setIsOpen(false)
    }
  }

  return (
    <NavLink
      to={to}
      className="link-container"
      ref={ref}
      onMouseEnter={showElement}
      onMouseLeave={hideElement}
      onClick={handleClick}
    >
      <NavSiderImageBlock collapsed={isCollapsed}>{el.icon}</NavSiderImageBlock>
      <p>{el.label}</p>
      {isCollapsed &&
        container &&
        createPortal(
          <NavSiderTooltip
            className="tooltiptext"
            rect={rect}
            isVisible={isVisible}
            style={{
              top: rect ? rect?.top - rect.height - 10 : 0,
              left: rect ? rect.left + rect.width + 13 : 0,
            }}
          >
            {el.label}
          </NavSiderTooltip>,
          container
        )}
    </NavLink>
  );
};

const ProfileSiderMenuButtonItem = ({
  activeKey,
  isCollapsed,
  setActiveKey,
  el,
  setIsOpen
}: ProfileSiderMenuButtonItemProps) => {
  const handleActiveKey = (key: string) => {
    if (activeKey !== key) {
      setActiveKey(key);
    } else {
      setActiveKey("");
    }
  };

  const { language } = useTranslate();

  const { isVisible, rect, ref, showElement, hideElement } =
    useVisibleWithRect<HTMLButtonElement>();

  const container = document.querySelector(".sider_modal");
  if (!container) {
    return null;
  }

  return (
    <>
      <NavSiderButtonItem
        ref={ref}
        onClick={() => {
          handleActiveKey(el.key)}
        }
        collapsed={isCollapsed}
        onMouseEnter={showElement}
        onMouseLeave={hideElement}
      >
        <div className="button-container">
          <div className="info-block">
            <NavSiderImageBlock className="image-block" collapsed={isCollapsed}>
              {el.icon}
            </NavSiderImageBlock>
            <p>{el.label}</p>
            {!!el.count && (
              <SiderBadge collapsed={isCollapsed} language={language}>
                <span>{el.count}</span>
              </SiderBadge>
            )}
          </div>
          {isCollapsed &&
            container &&
            createPortal(
              <NavSiderTooltip
                className="tooltiptext"
                rect={rect}
                isVisible={isVisible}
                style={{
                  top: rect ? rect?.top - rect.height - 10 : 0,
                  left: rect ? rect.left + rect.width + 13 : 0,
                }}
              >
                {el.label}
              </NavSiderTooltip>,
              container
            )}
          <ChevronVerticalIcon
            className="chevron"
            opened={activeKey === el.key ? "true" : undefined}
            collapsed={isCollapsed}
          />
        </div>
      </NavSiderButtonItem>
      {el.children?.length && rect && (
        <ProfileSiderSubMenu
          activeKey={activeKey}
          el={el}
          isCollapsed={isCollapsed}
          setActiveKey={setActiveKey}
          rect={rect}
          isVisible={isVisible}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
};
