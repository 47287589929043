export interface IBadge {
    [key: string] : IBadgeItem
}

export interface IBadgeItem {
    text: string;
    icon?: string;
    color: BadgeLightColors | BadgeDarkColors;
    borderColor?: BadgeBorderColors,
    isTextLight?: boolean | null;
    badgeType: BadgesTypes;
    manual: boolean;
}

export type ISubcategoryBadgeItem = Omit<IBadgeItem, "badgeType" | "manual">;

export enum BadgeSizes {
    MEDIUM = "medium",
    SMALL = "small",
    ICON = "icon"
}

export enum BadgeLightColors {
    AQUA = "#B0E8E4",
    BLUE = "#9EC4FA",
    LIGHT_PURPLE = "#E8E6F2",
    PURPLE = "#C3ADEB",
    PINK = "#F0A8DE",
    RED = "#F5A3BC",
    ORANGE = "#FAB49E",
    LIGHT_ORANGE = "#F7C8A1",
    LIGHT_BLUE = "#A1E5F7",
    GREEN = "#B8EBAD",
    TRANSPARENT = "transparent"
}

export enum BadgeDarkColors {
    AQUA = "#198079",
    BLUE = "#0D5BF2",
    PURPLE = "#7633CC",
    PINK = "#DC189B",
    RED = "#D9265C",
    ORANGE = "#FF6333",
    LIGHT_ORANGE = "#F2890D",
    LIGHT_BLUE = "#0DADF2",
    GREEN = "#3FCC33",
    TRANSPARENT = "transparent",
    VIOLET = "#18067D"
}

export enum BadgeBorderColors {
    LIGHT_GRAY = "#D9D9D9",
    VIOLET = "#18067D"
}

export enum BadgesTypes {
    RECOMMENDED = "RECOMMENDED",
    TOP_TEACHER = "TOP_TEACHER",
    NEW_TUTOR = "NEW_TUTOR",
    VERIFIED_TUTOR = "VERIFIED_TUTOR",
    FIRST_LESSON_FOR_FREE = "FIRST_LESSON_FOR_FREE",
    POPULAR_TUTOR = "POPULAR_TUTOR",
    RISING_STAR = "RISING_STAR"
}

export type TLabelEntity = {
    manual: boolean,
    value: BadgesTypes
}