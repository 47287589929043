import {FC, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {validate as validateIsUUID} from "uuid";

import {UsersSectionStyled} from "./users-section-styled";
import {getUsers} from "../../../../../../api/user-api/getUsers";
import {getUsersPicture} from "../../../../../../services/user-service";
import {TUser} from "../../../../../../types";
import {usePagination} from "../../../../../../hooks/usePagination";
import Pagination from "../../../../../elements/pagination/pagination";
import {FormInput} from "../../../../../elements/form-input";
import {ButtonTheme, StyledButton} from "../../../../../elements/button";
import {NotFoundComponent} from "../../../../../elements/not-found-component";

export const UsersSection: FC = () => {

  const [users, setUsers] = useState<TUser[]>([]);
  const [totalItems, setTotalItems] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchName, setSearchName] = useState<string>("");
  const [searchId, setSearchId] = useState<string>("");
  const [SearchIdErrorMessage, setSearchIdErrorMessage] = useState("");

  const navigate = useNavigate();

  const {
    goToNextPage,
    goToPage,
    goToPreviousPage,
    paginationRange,
    totalPages,
  } = usePagination({
    pageSize: 10,
    totalItems,
    currentPage,
    setCurrentPage,
  });

  useEffect(() => {
    if (!validateIsUUID(searchId) && searchId !== "") {
      return
    }

    getUsers(currentPage - 1, searchName, searchId)
      .then(({data}) => {
        setUsers(data.users);
        setTotalItems(data.totalItems);
      });
  }, [currentPage, searchName, searchId]);

  function handleSearchNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchName(e.target.value);
    setCurrentPage(1);
  }

  function handleSearchIdChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;

    setSearchId(value);
    setCurrentPage(1);

    if (!value) {
      setSearchIdErrorMessage("");
      return
    }

    if (!validateIsUUID(value)) {
      setSearchIdErrorMessage("ID має бути у форматі UUID");
    } else {
      setSearchIdErrorMessage("");
    }
  }

  return (
    <UsersSectionStyled>
      <div className="title-container">
        <h2>Список користувачів</h2>
        <p>Переглядайте список користувачів та переходьте до детальної інформації про кожного з них.</p>
      </div>

      <section className="regular-section">
        <header className="section-header">
          <FormInput wrapperClassName="form-input-wrapper" value={searchName} placeholder="введіть ім'я користувача"
                     title="Пошук за іменем" setValue={handleSearchNameChange}/>
          <FormInput errorMessage={SearchIdErrorMessage} wrapperClassName="form-input-wrapper" value={searchId} placeholder="введіть id користувача"
                     title="Пошук по id" setValue={handleSearchIdChange}/>
        </header>
        {!!users.length ?
          <div className="users-container">
            {
              !!users.length && users.map((user) => (
                <div key={user.id} className="user-card">
                  <div className="user-info">
                    <img src={getUsersPicture(user.avatarId)} loading="lazy" alt={user.fullName}/>
                    <div className="info">
                      <p>{user.fullName}</p>
                      <p className="date-container">{user.createdDate || "no created date"}</p>
                    </div>
                  </div>
                  <div>
                    {user.userType}
                  </div>
                  <div>
                    <StyledButton onClick={() => navigate(user.id)}
                                  theme={ButtonTheme.INVERT_BLUE}>Керування</StyledButton>
                  </div>
                </div>
              ))
            }
          </div>
          :
          <NotFoundComponent text="Немає результатів"/>
        }
      </section>

      {totalPages !== 0 && (
        <Pagination
          currentPage={currentPage}
          goToNextPage={goToNextPage}
          goToPage={goToPage}
          goToPreviousPage={goToPreviousPage}
          paginationRange={paginationRange}
          totalPages={totalPages}
        />
      )}
    </UsersSectionStyled>
  );
}