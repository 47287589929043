import {useQuery} from "@tanstack/react-query";

import {QUERY_KEYS} from "../../config";
import {getTicketById} from "../../api/user-api";


interface Props {
    id: string;
    enabled: boolean;
}

export const useTicketDetails = ({id, enabled}: Props) => useQuery({
    queryKey: QUERY_KEYS.USER.ticketById(id),
    queryFn: () => getTicketById(id).then(({data}) => data),
    enabled
});