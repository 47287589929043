import styled from "styled-components";

export const IssuesSectionStyled = styled.section`
    .regular-section {
        .section-header {
            margin-bottom: 24px;
        }
        
        .issues-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }
    
    
`