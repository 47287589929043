import {Authority, Gender, TFile} from "./User";
import {TLabelEntity} from "./Badge";


export enum Labels {
    RECOMMENDED = "RECOMMENDED",
    TOP_TEACHER = "TOP_TEACHER",
}

export type TLanguagesResponse = {
    data: TLanguage[]
};

export type TLanguage = {
    id: string;
    code: number | null;
    languageCode: string;
    name: string;
};

export type SpokenLanguage = {
    id: string;
    level: TLanguageLevel;
    name: string;
}

export type TCountriesResponse = {
    data: Country[]
};

export type Country = {
    name: string;
    code: number;
};

export type TCitiesResponse = {
    data: CityData[]
};

export type CityData = {
    code: number,
    name: string
};

export type TLocalizedData = {
    id: string;
    code?: number;
    languageCode: string;
    name: string;
}

export type TProfileDemographyData = {
    code: number;
    localizedData?: TLocalizedData[];
}

export interface IEducationAndExperienceBackground {
    id?: string;
    title: string;
    fromYear: string | null;
    toYear: string | null;
    attachments: TFile[];
}

export enum TLanguageLevel {
    BEGINNER = "BEGINNER",
    PRE_INTERMEDIATE = "PRE_INTERMEDIATE",
    INTERMEDIATE = "INTERMEDIATE",
    UPPER_INTERMEDIATE = "UPPER_INTERMEDIATE",
    ADVANCED = "ADVANCED",
    NATIVE_SPEAKER = "NATIVE_SPEAKER"
}

export enum TLanguageLevelNames {
    BEGINNER = "beginner",
    PRE_INTERMEDIATE = "pre-intermediate",
    INTERMEDIATE = "intermediate",
    UPPER_INTERMEDIATE = "upper-intermediate",
    ADVANCED = "advanced",
    NATIVE_SPEAKER = "native-speaker"
}

export type TCategoriesResponse = {
    data: Category[]
};

export type Category = {
    id: string;
    name: string;
    code?: number;
    description?: string;
    childrenCategories?: Category[];
    iconLocation?: string;
};

export type TUpdateCategory = {
    id: string;
    pricePerHour: number;
    currencyId: number;
}

export enum Currency {
    USD = "USD",
    UAH = "UAH",
}

export type TCurrenciesResponse = {
    data: TCurrency[];
}

export type TCurrency = {
    id: number;
    value: string;
}

export type TPriceRangeResponse = {
    data: TPriceRange;
}

export type TPriceRange = {
    minPrice: number;
    maxPrice: number;
}

export type CategorySearchResponse = {
    id?: string;
    description?: string;
    pricePerHour?: number;
    code: null;
    name: string;
    iconLocation: string;
}

export type TSubCategoriesResponse = {
    data: {
        subcategories: TUserSubcategory[]
    }
}


export type TUsersSkillResponse = {
    data: {
        userCategories: TUsersSkill[],
        subcategories: TUserSubcategory[],
    };
};

export type TUsersSkill = {
    currency?: {
        id: number;
        label: Currency;
    },
    pricePerHour: number | undefined,
    category: Category;
};

export type TUserSubcategory = {
    subcategoryId: number,
    subcategoryName: string,
    subcategoryLocalizedName: string
}

export const emptySkill: TUsersSkill = {
    currency: {
        id: 1,
        label: Currency.UAH,
    },
    pricePerHour: undefined,
    category: {
        id: "",
        name: "",
        code: 0,
    },
};

export type Review = {
    comment: string;
    rating: number;
    createdAt: string;
    studentAvatarId: string | null;
    studentFirstName: string;
    studentLastName: string;
}

export type TTeacherReview = {
    comment: string;
    rating: number;
}

export enum LessonType {
    ONLINE = "Online",
    OFFLINE = "Offline",
    BOTH = "Both"
}

export type TStudentProfileResponse = {
    data: StudentProfile
};

export type TTeacherProfileResponse = {
    data: TeacherProfile
};
export type TTeacherProfileOtherInfoResponse = {
    data: IOtherInfo
};

export interface UserProfile {
    firstName: string;
    lastName: string;
    middleName?: string;
    phoneNumber: string;
    avatarId?: string;
    countryCode: string;
    cityCode?: null;
    about?: string;
    email: string;
    gender: Gender;
    notificationEnabled: boolean;
    registrationMethod?: string | null;
    connectionRequestsCounter?: number;
    clarificationNeeded?: boolean;
}

export type AdminProfile = Pick<UserProfile, "firstName" | "lastName" | "avatarId" | "connectionRequestsCounter" | "clarificationNeeded">

export interface StudentProfile extends UserProfile {
    city?: TProfileDemographyData;
    country?: TProfileDemographyData;
    region?: TProfileDemographyData;
}

export interface TeacherProfile extends UserProfile {
    city?: TProfileDemographyData;
    videoId: string | null;
    title?: string;
    educations: IEducationAndExperienceBackground[];
    experiences: IEducationAndExperienceBackground[];
    verificationStatus: {
        comment: string;
        verificationStatus: EVerificationStatus;
    } |
        null;
    verified: boolean;
}

export enum EVerificationStatus {
    NOT_APPROVED = "NOT_APPROVED",
    IN_PROGRESS = "IN_PROGRESS",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED"
}

export interface IOtherInfo {
    city?: TProfileDemographyData;
    country?: TProfileDemographyData;
    lessonType: LessonType;
    personalizedData?: {
        spokenLanguages: SpokenLanguage[];
    };
    region?: TProfileDemographyData;
    workingFrom?: string;
    workingTo?: string;
}

export type TTopTeachersResponse = {
    data: Teacher[]
};

export type TCategoriesSearchResponse = {
    data: {
        teachers: Teacher[],
        maxPrice: number,
        minPrice: number,
        totalResults: number,
        page: number,
        pageSize: number
    }
}

export interface Teacher {
    about: string,
    avatarId: string | null,
    categories: CategorySearchResponse[],
    email: string,
    firstName: string,
    labels: TLabelEntity[],
    id: string,
    lastName: string,
    lessonType: string,
    location: string,
    pricePerHour?:number,
    minPricePerHour?:number,
    maxPricePerHour?:number,
    rating: string,
    verifiedTeacher: boolean,
    addedToFavourites?: boolean;
}

export type TTeacherDetailsResponse = {
    data: TeacherDetails,
}

export interface TeacherDetails {
    about?: string;
    addedToFavourites: boolean;
    avatarId?: string;
    categories: Category[];
    city?: {
        code?: number;
        localizedData?: string;
    },
    country?: string;
    labels: TLabelEntity[],
    educations?: IEducationAndExperienceBackground[];
    experiences?: IEducationAndExperienceBackground[];
    firstName?: string;
    id: string;
    lastActiveTime: string | null;
    lastName?: string;
    lessonType: LessonType;
    minPricePerHour?:number;
    maxPricePerHour?:number;
    profileViews: number;
    rating?: number;
    role: Authority;
    spokenLanguages?: SpokenLanguage[];
    title?: string;
    verifiedTeacher?: boolean;
    workingFrom: string;
    workingTo: string;
    videoId: string | null;
    subcategories: TUserSubcategory[];
}

export type TFavouriteTeachersResponse = {
    data: Teacher[];
}

export type TTeacherResponseTimeResponse = {
    data: ITeacherResponseTime
}

export interface ITeacherResponseTime {
    responseHoursAvg: number | null,
    responseTotalCount: number | null,
    userId: string
}

export type TTeacherReviewsResponse = {
    data: TeacherReviews
}

export interface TeacherReviews {
    reviews: Review[];
    page: number;
    totalElements: number;
    pageSize: number;
    isLast: boolean;
}

export type TTeacherSchedulerResponse = {
    data: ITeacherSchedule;
}

export interface ITeacherSchedule {
    timeSlots: TTimeSlot[] | null;
}

export enum Days {
    Monday = 'MONDAY',
    Tuesday = 'TUESDAY',
    Wednesday = 'WEDNESDAY',
    Thursday = 'THURSDAY',
    Friday = 'FRIDAY',
    Saturday = 'SATURDAY',
    Sunday = 'SUNDAY',
}

export type TTimeSlot = {
    weekDay?: Days;
    multiplier: number;
    isRepeatable: boolean;
    startDate: string;
    endDate: string;
}

export type TTopCategoriesResponse = {
    data: TopCategory[]
};

export type TopCategory = {
    name: string;
    code: number;
    teachersCount: number;
    parentCategoryName: string;
    location: string;
};

export type TUserGoogleInfoResponse = {
    data: IUserGoogleInfo
}

export interface IUserGoogleInfo {
    email: string;
    email_verified: boolean;
    family_name: string; //surname
    given_name: string; //name
    hd: string; //domain from which request is made
    locale: string; // in such format: "uk"
    name: string; //name + surname
    picture: string;
    sub: string;
}