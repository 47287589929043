import {Route, Routes} from "react-router-dom";
import React, {FC} from "react";

import {BackOfficePageStyled} from "./back-office-page.styled";
import WithSiderWrapper from "../../layout-components/wrappers/page-with-sider/page-with-sider";
import {IssuesSection} from "./components/sections/issues-section/issues-section";
import {IssueDetailsSection} from "./components/sections/issue-details-section/issue-details-section";
import {TType} from "../../../assets/translations";
import {FeedbacksSection} from "./components/sections/feedbacks-section";
import {FeedbackDetailsSection} from "./components/sections/feedback-details-section/feedback-details-section";
import {UsersSection} from "./components/sections/users-section";
import {UserDetailsSection} from "./components/sections/user-details-section";

interface Props {
  t: TType;
}

export const BackOfficePage:FC<Props> = ({t}) => {

  return (
    <WithSiderWrapper background="#ECEFF2">
      <BackOfficePageStyled>
        <Routes>
          <Route path="support" element={<IssuesSection/>}/>

          <Route path="support/ticket/:id" element={<IssueDetailsSection t={t}/>}/>

          <Route path="feedbacks" element={<FeedbacksSection/>}/>

          <Route path="feedbacks/:id" element={<FeedbackDetailsSection/>}/>

          <Route path="users" element={<UsersSection/>}/>

          <Route path="users/:id" element={<UserDetailsSection/>}/>
        </Routes>
      </BackOfficePageStyled>
    </WithSiderWrapper>
  )
}