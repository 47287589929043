import {BadgeDarkColors, BadgesTypes, IBadgeItem, TLabelEntity} from "../../../types/Badge";
import {TType} from "../../../assets/translations";
import MoneyIcon from "../../../assets/images/svg/money.svg";
import VerifiedIconOutlined from "../../../assets/images/svg/verified-icon-outlined.svg";
import ClosedBookIcon from "../../../assets/images/svg/closed-book.svg";
import GlobeTeacherIcon from "../../../assets/images/svg/globe-teacher.svg";
import StarOutlinedIcon from "../../../assets/images/svg/star-outlined.svg";
import TopTeacherIcon from "../../../assets/images/svg/top-teacher.svg";
import RecommendedTutorIcon from "../../../assets/images/svg/pen-with-sheet.svg";

export function getBadgeItems(labels: TLabelEntity[] | BadgesTypes[], t: TType): IBadgeItem[] {

  if (labels == null) return [];

  return labels.map((label) => {

    const value = typeof label === "string" ? label : label.value;

    if (value === BadgesTypes.FIRST_LESSON_FOR_FREE) {
      return (
        {
          icon: MoneyIcon,
          text: t("teacher-labels.free-lesson"),
          color: BadgeDarkColors.AQUA,
          isTextLight: true,
          badgeType: typeof label === "string" ? label : label.value,
          manual: typeof label === "string" ? false : label.manual,
        })
    }

    if (value === BadgesTypes.VERIFIED_TUTOR) {
      return (
        {
          icon: VerifiedIconOutlined,
          text: t("teacher-labels.verified-tutor"),
          color: BadgeDarkColors.BLUE,
          isTextLight: true,
          badgeType: typeof label === "string" ? label : label.value,
          manual: typeof label === "string" ? false : label.manual,
        }
      )
    }

    if (value === BadgesTypes.NEW_TUTOR) {
      return (
        {
          icon: ClosedBookIcon,
          text: t("teacher-labels.new-tutor"),
          color: BadgeDarkColors.PURPLE,
          isTextLight: true,
          badgeType: typeof label === "string" ? label : label.value,
          manual: typeof label === "string" ? false : label.manual,
        }
      )
    }

    if (value === BadgesTypes.POPULAR_TUTOR) {
      return (
        {
          icon: GlobeTeacherIcon,
          text: t("teacher-labels.popular-tutor"),
          color: BadgeDarkColors.PINK,
          isTextLight: true,
          badgeType: typeof label === "string" ? label : label.value,
          manual: typeof label === "string" ? false : label.manual,
        }
      )
    }

    if (value === BadgesTypes.RISING_STAR) {
      return (
        {
          icon: StarOutlinedIcon,
          text: t("teacher-labels.rising-star"),
          color: BadgeDarkColors.ORANGE,
          isTextLight: true,
          badgeType: typeof label === "string" ? label : label.value,
          manual: typeof label === "string" ? false : label.manual,
        }
      )
    }

    if (value === BadgesTypes.TOP_TEACHER) {
      return (
        {
          icon: TopTeacherIcon,
          text: t("teacher-labels.top-tutor"),
          color: BadgeDarkColors.LIGHT_BLUE,
          isTextLight: true,
          badgeType: typeof label === "string" ? label : label.value,
          manual: typeof label === "string" ? false : label.manual,
        }
      )
    }

    return (
      {
        icon: RecommendedTutorIcon,
        text: t("teacher-labels.recommended"),
        color: BadgeDarkColors.GREEN,
        isTextLight: true,
        badgeType: typeof label === "string" ? label : label.value,
        manual: typeof label === "string" ? false : label.manual,
      }
    )

  })
}


