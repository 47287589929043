import { FC } from "react";

import { useNavigate } from "react-router-dom";

import { TType, useTranslate } from "../../../../../assets/translations";
import { CalendarClearIcon } from "../../../../../assets/images/svg-elements/calendar-clear";

import { ButtonTheme } from "../../../../elements/button";

import { CardMessageButton } from "../../lesson-request-action-buttons/lesson-request-action-buttons";
import ChangeRequestStatusButton from "../../lesson-request-action-buttons/change-request-status/change-request-status";

import LessonRequestStatus from "../../lesson-request-status/lesson-request-status";

import {
  TeacherDirectAge,
  TeacherDirectCardButtons,
  TeacherDirectCardContainer,
  TeacherDirectCardStatusWrapper,
  TeacherDirectCardTimeInfo,
  TeacherDirectCardTimeSlot,
  TeacherDirectCardTimeSlots,
  TeacherDirectCardUserInfo,
} from "./teacher-direct-card-styled";
import { LessonRequestCardWrapper } from "../lesson-request-card-styled";

import { getUsersPicture } from "../../../../../services/user-service";

import {
  GetRequestsLessonStatuses,
  KnowledgeLevelsNames,
  LessonsFrequency,
  LessonsFrequencyNames,
  RequestLesson,
} from "../../../../../types";
import { formatAge } from "../../../../../services/localization-service";
import { useIsResponsive } from "../../../../../hooks/useIsResponsive";
import {
  getMonthNameWithDate,
  normalizeHourWithTimezone,
} from "../../../../../services/date-service";
import { useFormattedMoreMessage } from "../../../../../hooks/useFormattedMoreMessage";
import { StudentDirectMessage } from "../student-direct-card/student-direct-card-styled";

interface TeacherDirectCardProps {
  card: RequestLesson;
  t: TType;
}

const TeacherDirectCard: FC<TeacherDirectCardProps> = ({ card, t }) => {
  const { language } = useTranslate();
  const navigate = useNavigate();
  const { isTablet, isSmallDesktop, isBigDesktop } = useIsResponsive();

  const {
    isMessageLongerThanMaxSymbols,
    formattedMessage,
    handleIsShowMore,
    isShowMore,
  } = useFormattedMoreMessage(card.message, !isBigDesktop ? 100 : 30);

  return (
    <LessonRequestCardWrapper>
      <TeacherDirectCardContainer className={`container`}>
        <TeacherDirectCardUserInfo>
          <img src={getUsersPicture(card.studentAvatarId)} alt="user avatar" />
          <div className="personal_info">
            <div>
              <p>{card.studentName}</p>
            </div>
            {card.categoryName && <p>{card.categoryName}</p>}
          </div>
        </TeacherDirectCardUserInfo>

        <TeacherDirectCardTimeInfo>
          <div>
            <CalendarClearIcon />
            <p>
              {card.numberOfClassesPerWeek
                ? `${LessonsFrequencyNames[card.numberOfClassesPerWeek]} ${
                    card.numberOfClassesPerWeek === LessonsFrequency.ONE
                      ? t("main-page.search-query.one_time_per_week")
                      : t("main-page.search-query.time_per_week")
                  }`
                : t("my-students.not-specified")}
            </p>
          </div>
          {!isTablet && !isSmallDesktop && (
            <TeacherDirectCardTimeSlots>
              {card.desiredTimeslots.map((timeslot) => (
                <TeacherDirectCardTimeSlot key={timeslot.startDate}>
                  {`${getMonthNameWithDate(
                    timeslot.startDate,
                    language
                  )} ${normalizeHourWithTimezone(timeslot.startDate)}`}
                </TeacherDirectCardTimeSlot>
              ))}
            </TeacherDirectCardTimeSlots>
          )}
        </TeacherDirectCardTimeInfo>

        <TeacherDirectAge>
          <div>
            <p className="card-section-title">
              {t("lesson-requests.cards.age")}
            </p>
            <p>
              {card.age
                ? `${card.age} ${formatAge(+card.age, language, t)}`
                : t("my-students.not-specified")}
            </p>
          </div>
          <div>
            <p className="card-section-title">
              {t("lesson-requests.cards.level")}
            </p>
            <p>
              {card.knowledgeLevel
                ? t(
                    `language-levels.${
                      KnowledgeLevelsNames[card.knowledgeLevel]
                    }`
                  )
                : t("my-students.not-specified")}
            </p>
          </div>
        </TeacherDirectAge>

        {(isTablet || isSmallDesktop) && (
          <TeacherDirectCardTimeSlots>
            {card.desiredTimeslots.map((timeslot) => (
              <TeacherDirectCardTimeSlot key={timeslot.startDate}>
                {`${getMonthNameWithDate(
                  timeslot.startDate,
                  language
                )} ${normalizeHourWithTimezone(timeslot.startDate)}`}
              </TeacherDirectCardTimeSlot>
            ))}
          </TeacherDirectCardTimeSlots>
        )}

        <TeacherDirectCardStatusWrapper>
          <LessonRequestStatus status={card.status} t={t} />
        </TeacherDirectCardStatusWrapper>

        <StudentDirectMessage>
          {card.message ? (
            <div className="container">
              {isShowMore ? (
                <>
                  <p>{card.message}</p>
                  {isMessageLongerThanMaxSymbols && (
                    <button
                      onClick={handleIsShowMore}
                      className="load-more-message-btn"
                    >
                      Less
                    </button>
                  )}
                </>
              ) : (
                <>
                  <p>
                    {isMessageLongerThanMaxSymbols
                      ? `${formattedMessage}...`
                      : formattedMessage}
                  </p>
                  {isMessageLongerThanMaxSymbols && (
                    <button
                      onClick={handleIsShowMore}
                      className="load-more-message-btn"
                    >
                      More
                    </button>
                  )}
                </>
              )}
            </div>
          ) : (
            <p>{t("my-students.not-specified")}</p>
          )}
        </StudentDirectMessage>

        <TeacherDirectCardButtons>
          {card.status === GetRequestsLessonStatuses.CREATED ? (
            <>
              <ChangeRequestStatusButton
                categoryId={card.categoryId}
                connectionRequestId={card.id}
                requestStatus={GetRequestsLessonStatuses.CANCELLED}
                studentId={card.studentId}
                teacherId={card.teacherId}
                successMsg={t("lesson-requests.notifications.decline")}
                theme={ButtonTheme.INVERT_BLUE}
              >
                {t("lesson-requests.buttons.decline")}
              </ChangeRequestStatusButton>
              <CardMessageButton
                language={language}
                navigate={navigate}
                studentId={card.studentId}
                t={t}
                teacherId={card.teacherId}
                type="icon"
                theme={ButtonTheme.INVERT_BLUE}
              />
              <ChangeRequestStatusButton
                categoryId={card.categoryId}
                connectionRequestId={card.id}
                requestStatus={GetRequestsLessonStatuses.ACCEPTED}
                studentId={card.studentId}
                teacherId={card.teacherId}
                theme={ButtonTheme.BLUE}
              >
                {t("lesson-requests.buttons.schedule")}
              </ChangeRequestStatusButton>
            </>
          ) : (
            <CardMessageButton
              language={language}
              navigate={navigate}
              studentId={card.studentId}
              t={t}
              teacherId={card.teacherId}
              type="icon"
              theme={ButtonTheme.INVERT_BLUE}
            />
          )}
        </TeacherDirectCardButtons>
      </TeacherDirectCardContainer>
    </LessonRequestCardWrapper>
  );
};

export default TeacherDirectCard;
