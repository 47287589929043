import { Modal } from "antd";
import styled from "styled-components";

export const CardActionModalWrapper = styled(Modal)`
  h1 {
    color: #18067d;
    font-size: 20px;
    font-weight: 700;
  }

  p {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3em;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
`;
