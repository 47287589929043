import { useEffect } from "react";
import { useIsResponsive } from "./useIsResponsive";

export const useDisableScroll = (condition: boolean, onlyMobile: boolean): void => {
  const {isMobile} = useIsResponsive();

  useEffect(() => {
    const shouldDisableScroll = onlyMobile ? isMobile && condition : condition;

    if (shouldDisableScroll) {
      document.body.style.overflow = "hidden";
      document.body.style.width = "100%";
    } else {
      document.body.style.overflow = "auto";
      document.body.style.width = "unset";
    }

    return () => {
      document.body.style.overflow = "auto";
      document.body.style.width = "unset";
    };
  }, [condition, isMobile, onlyMobile]);
};
