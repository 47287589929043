import {FC, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Rate} from "antd";

import {FeedbackDetailsSectionStyled} from "./feedback-details-section.styled";
import {getFeedbackDetails} from "../../../../../../api/user-api/getFeedbackDetails";
import {TFeedbackBody} from "../../../../../../types";
import {StyledFile} from "../../../../support-page/ticket-details/ticket-details.styled";
import {formatSizeUnits, handleDownloadFile} from "../../../../../../services/file-service";
import {FileIcon} from "../../../../../../assets/images/svg-elements/file";
import {useTranslate} from "../../../../../../assets/translations";
import {StyledStar} from "../../../../../elements/feedback/shared-form/shared-form.styled";

export const FeedbackDetailsSection: FC = () => {

  const [feedbackDetails, setFeedbackDetails] = useState<TFeedbackBody | null>(null)

  const {id} = useParams();
  const {t} = useTranslate();

  useEffect(() => {
    if (!id) return

    getFeedbackDetails(id)
      .then(({data}) => {
        setFeedbackDetails(data);
      })
  }, []);

  return (
    <FeedbackDetailsSectionStyled>
      <div className="title-container">
        <h2>Деталі відгуку</h2>
        <p>Тут надана повна інформація про відгук.</p>
      </div>

      <section className="regular-section">

        <div className="feedback-details-container">
          <div className="meta-data-container">
            <div className="email">
              {feedbackDetails?.email}
            </div>
            <div>
              {feedbackDetails?.type}
            </div>
            <div>
              <Rate character={<StyledStar/>} disabled value={feedbackDetails?.rating}/>
            </div>
          </div>
          <div className="comment-container">
            {feedbackDetails?.comment}
          </div>
        </div>

        {!!feedbackDetails?.medias!.length && <div className="feedback-medias-container">
            <p>вкладені файли</p>
            <div className="medias">
              {feedbackDetails.medias.map((file)=>(
                <StyledFile key={file.id} onClick={() => handleDownloadFile(file, t)}>
                  <div className="file-icon"><FileIcon/></div>
                  <div className="file-info">
                    <h2>{file.filename}</h2>
                    <p>{formatSizeUnits(file.fileSize, t)}</p>
                  </div>
                </StyledFile>
              ))
              }
            </div>
        </div>}

      </section>
    </FeedbackDetailsSectionStyled>
  );
}