import {FC} from "react";
import {Tooltip} from "antd";
import {TooltipProps} from "antd/lib/tooltip";


type Props = TooltipProps & {
    showHint?: boolean;
    textColor?: string;
    zIndex?: number;
}

export const Hint: FC<Props> = ({title, placement, children, showHint = true, textColor = "gray", zIndex}) => {


    if (!showHint) {
        return <>{children}</>;
    }

    return <Tooltip zIndex={zIndex}
                    title={title}
                    placement={placement}
                    overlayInnerStyle={{color: textColor}}
                    color="white"
                    overlayStyle={{filter: "dropShadow(0 2px 8px rgba(0, 0, 0, 0.15))"}}>
        {children}
    </Tooltip>
};
