import {FC, useCallback, useEffect, useState} from "react";
import TextArea from "antd/es/input/TextArea";
import {useNavigate, useParams} from "react-router-dom";
import classNames from "classnames";
import dayjs from "dayjs";

import {StepTwoStyled} from "./step-two.styled";
import {FormInput} from "../../../../elements/form-input";
import {Dropdown, Option} from "../../../../elements/dropdown";
import {ConnectRequestForm} from "../../components/connect-request-form";
import {TType, useTranslate} from "../../../../../assets/translations";
import {activeSlotsKey} from "../../../../../services/storage-service";
import {useLocalStorage} from "../../../../../hooks/useLocalStorage";
import {KnowledgeLevels, LessonsFrequency, LessonsFrequencyNames, TUsersSkillResponse} from "../../../../../types";
import {useUsersSkills} from "../../../../../hooks/query-hooks";
import {postConnectionRequestsDirect} from "../../../../../api/lesson-api/postConnectionRequestsDirect";
import {getConnectionRequestsLatestDirect} from "../../../../../api/lesson-api/getConnectionRequestsLatestDirect";
import {useMediaQuery} from "react-responsive";
import {device} from "../../../../constants";
import {TeachingMeErrorNotification, TeachingMeSuccessNotification} from "../../../../elements/notification";
import {ButtonTheme, StyledButton} from "../../../../elements/button";

interface Props {
  t: TType
}

export const StepTwo: FC<Props> = ({t}) => {

  const [knowledgeLevel, setKnowledgeLevel] = useState<KnowledgeLevels>();
  const [subject, setSubject] = useState<string>();
  const [lessonFrequency, setLessonFrequency] = useState<LessonsFrequency>();
  const [studentAge, setStudentAge] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [activeRequestsCount, setActiveRequestsCount] = useState<number>();

  const [activeSlots, setActiveSlots] = useLocalStorage<string[]>(activeSlotsKey, []);

  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const {teacherId} = useParams();
  const {language} = useTranslate();

  const isDesktop = useMediaQuery({
    query: `(${device.desktop})`,
  });

  const {data: skillsOptions, isLoading: skillOptionsLoading, isError: skillOptionsError} = useUsersSkills<Option[]>({
    teacherId: String(teacherId),
    enabled: true,
    language: language,
    select: useCallback(({data}: TUsersSkillResponse): Option[] => {
      const skillsOptions = data.userCategories.map(({category: {name, id, code}}) => ({
        name,
        code,
        value: id
      }))

      if (skillsOptions.length === 1) {
        setSubject(skillsOptions[0].value);
      }

      return skillsOptions
    }, [])
  });

  useEffect(() => {
    getConnectionRequestsLatestDirect()
      .then(({data: {activeRequestsCount, latestRequest}}) => {
        setActiveRequestsCount(activeRequestsCount)

        latestRequest?.categoryId && skillsOptions && skillsOptions.some((option) => option.value === latestRequest.categoryId) && setSubject(latestRequest.categoryId);
        latestRequest?.knowledgeLevel && setKnowledgeLevel(latestRequest.knowledgeLevel);
        latestRequest?.age && setStudentAge(latestRequest.age)
        latestRequest?.numberOfClassesPerWeek && setLessonFrequency(latestRequest.numberOfClassesPerWeek)
        latestRequest?.message && setMessage(latestRequest.message)
      })
  }, [skillsOptions]);

  const getKnowledgeLevelOptions = (t: TType): Option[] => [
    {
      value: KnowledgeLevels.BEGINNER,
      name: t(`knowledge-levels.${KnowledgeLevels.BEGINNER.toLowerCase()}`)
    },
    {
      value: KnowledgeLevels.INTERMEDIATE,
      name: t(`knowledge-levels.${KnowledgeLevels.INTERMEDIATE.toLowerCase()}`)
    },
    {
      value: KnowledgeLevels.ADVANCED,
      name: t(`knowledge-levels.${KnowledgeLevels.ADVANCED.toLowerCase()}`)
    },
    {
      value: KnowledgeLevels.HIGH,
      name: t(`knowledge-levels.${KnowledgeLevels.HIGH.toLowerCase()}`)
    },
  ];

  const getLessonFrequencyOptions = (t: TType): Option[] => [
    {
      name: `${LessonsFrequencyNames.ONE} ${t("lesson-frequency-name.singular")}`,
      value: LessonsFrequency.ONE
    },
    {
      name: `${LessonsFrequencyNames.TWO} ${t("lesson-frequency-name.plural")}`,
      value: LessonsFrequency.TWO
    },
    {
      name: `${LessonsFrequencyNames.ONE_TWO} ${t("lesson-frequency-name.plural")}`,
      value: LessonsFrequency.ONE_TWO
    },
    {
      name: `${LessonsFrequencyNames.THREE} ${t("lesson-frequency-name.plural")}`,
      value: LessonsFrequency.THREE
    },
    {
      name: `${LessonsFrequencyNames.TWO_THREE} ${t("lesson-frequency-name.plural")}`,
      value: LessonsFrequency.TWO_THREE
    },
    {
      name: `${LessonsFrequencyNames.MORE} ${t("lesson-frequency-name.plural")}`,
      value: LessonsFrequency.MORE
    },
  ]

  function handleButtonClick() {
    if (!teacherId || !subject) return

    setIsButtonLoading(true);

    postConnectionRequestsDirect({
      teacherId: teacherId,
      message: message,
      knowledgeLevel: knowledgeLevel,
      age: studentAge,
      categoryId: subject,
      numberOfClassesPerWeek: lessonFrequency,
      timeSlots: activeSlots.map((activeSlot) => ({
        startDate: dayjs(activeSlot).format("YYYY-MM-DD HH:mm"),
        endDate: dayjs(activeSlot).format("YYYY-MM-DD HH:mm"),
      }))
    })
      .then((response)=>{
        TeachingMeSuccessNotification(t("connect-request-page.notifications.request-success"));
        navigate("/lesson-requests");
      })
      .catch((error)=>{
        if (error?.response?.data.message.includes("Request to that teacher already exists!")) {
          return TeachingMeErrorNotification(
            t("connect-request-page.notifications.request-exists"),
            <StyledButton onClick={()=>navigate("/lesson-requests")} theme={ButtonTheme.BLUE}>Активні запити</StyledButton>
          )
        }

        TeachingMeErrorNotification(t("connect-request-page.notifications.request-error"))
      })
      .finally(()=>{
        setIsButtonLoading(false);
      })
  }

  function defineIsButtonDisabled() {
    let isDisabled = true

    if (isButtonLoading) {
      return true
    }

    if (subject && typeof activeRequestsCount === "number" && activeRequestsCount !== 5) {
      isDisabled = false;
    }

    return isDisabled
  }

  function defineHintForDisabledButton() {

    if (activeRequestsCount === 5) {
      return t("connect-request-page.disabled-send-request-button-hint.active-requests-limit")
    }


    if (!subject) {
      return t("connect-request-page.disabled-send-request-button-hint.no-subject");
    }
  }

  return (
    <StepTwoStyled>
      {!isDesktop && <div className="info-section">
        <div className="requests-info-container">
          <div className="header">
            <div className={classNames("number-display", {
              "max-requests": activeRequestsCount === 5,
            })}>
              {typeof activeRequestsCount === "number" && `${activeRequestsCount}/5`}
            </div>
            <h2>
              {t("connect-request-page.requests")}
            </h2>
          </div>
          <div className="description">
            {t("connect-request-page.requests-description")}
          </div>
        </div>
      </div>}

        <div className="request-form">
          <div className="row-container">
            <div className="input-wrapper">
              <p className="required">{t("connect-request-page.subject")}</p>
              <Dropdown
                value={subject}
                placeholder={t("connect-request-page.subject")}
                options={skillsOptions || []}
                setValue={(value: string) => setSubject(value)}
              />
            </div>
            <div className="input-wrapper">
              <p>{t("connect-request-page.level")}</p>
              <Dropdown
                sortByAlphabet={false}
                placeholder={t("connect-request-page.level")}
                value={knowledgeLevel}
                options={getKnowledgeLevelOptions(t)}
                setValue={(value: KnowledgeLevels) => setKnowledgeLevel(value)}
              />
            </div>
          </div>
          <div className="row-container">
            <FormInput type="number" value={studentAge} wrapperClassName="custom-input-wrapper"
                       placeholder={t("connect-request-page.age")} title={t("connect-request-page.age")}
                       setValue={(e) => setStudentAge(e.target.value)}/>
            <div className="input-wrapper">
              <p>{t("connect-request-page.classes-frequency")}</p>
              <Dropdown
                sortByAlphabet={false}
                value={lessonFrequency}
                placeholder={t("connect-request-page.classes-frequency")}
                options={getLessonFrequencyOptions(t)}
                setValue={(value: LessonsFrequency) => setLessonFrequency(value)}
              />
            </div>
          </div>
          <div className="">
            <div className="input-wrapper">
              <p>{t("connect-request-page.message")}</p>
              <TextArea placeholder={t("connect-request-page.message")}
                        size="large"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        style={{borderRadius: 8}}
                        autoSize={{minRows: 3, maxRows: 6}}
              />
            </div>
          </div>
        </div>

        <div className="info-section">
          {isDesktop && <div className="requests-info-container">
            <div className="header">
              <div className={classNames("number-display", {
                "max-requests": activeRequestsCount === 5,
              })}>
                {typeof activeRequestsCount === "number" && `${activeRequestsCount}/5`}
              </div>
              <h2>
                {t("connect-request-page.requests")}
              </h2>
            </div>
            <div className="description">
              {t("connect-request-page.requests-description")}
            </div>
          </div>}

          <ConnectRequestForm t={t} buttonDisabled={defineIsButtonDisabled()}
                              buttonLoading={isButtonLoading}
                              disabledButtonHintText={defineHintForDisabledButton()}
                              buttonText={t("connect-request-page.send-request")} onButtonClick={handleButtonClick}
                              showActiveSlots={false} activeSlots={activeSlots} setActiveSlots={setActiveSlots}/>
        </div>
    </StepTwoStyled>
)
};