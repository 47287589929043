import {TFile} from "./User";
import {Option} from "../components/elements/dropdown";
import {TType} from "../assets/translations";


export interface ITicket {
    id: string,
    type: TicketType,
    issueDetails: string,
    status: TicketStatus,
    createdDate: string;
}

export interface IFilterTicket {
    id: string,
    type: TicketType,
    issueDetails: string,
    issuerEmail: string,
    assigneeName: string,
    status: TicketStatus,
    createdDate: string
}

export type TFilterTicketsResponse = {
    data: {
        supportTickets: IFilterTicket[],
        pageSize: number,
        totalItems: number
    }
}

export type TTicketsResponse = {
    data: ITicket[];
}

export enum TicketType {
    LESSON_PROBLEM = "LESSON_PROBLEM",
    PROBLEM_WITH_TEACHER = "PROBLEM_WITH_TEACHER",
    PROBLEM_WITH_STUDENT = "PROBLEM_WITH_STUDENT",
    OTHER = "OTHER"
}

export enum TicketStatus {
    OPEN = "OPEN",
    IN_PROGRESS = "IN_PROGRESS",
    RESOLVED = "RESOLVED",
    CLOSED = "CLOSED"
}

export interface ISingleTicket {
    id: string;
    type: TicketType;
    authorEmail: string;
    status: TicketStatus;
    issueDetails: string;
    issueResult: string;
    resolverName: string;
    medias: TFile[];
    createdDate: string | Date;
}

export type TSingleTicketResponse = {
    data: ISingleTicket;
}

export type TTicketBody = {
    type: TicketType;
    issueDetails: string;
    medias: string[];
}

export type TTicketBodyWithAttachments = {
    type: TicketType;
    issueDetails: string;
    medias: TFile[];
}

export const emptyTicket: TTicketBodyWithAttachments = {
    issueDetails: "",
    type: TicketType.LESSON_PROBLEM,
    medias: []
}

export const getTypeOptions = (t: TType): Option[] => [
    {
        name: t("support.types.lesson-problem"),
        value: TicketType.LESSON_PROBLEM
    },
    {
        name: t("support.types.student-problem"),
        value: TicketType.PROBLEM_WITH_STUDENT
    },
    {
        name: t("support.types.teacher-problem"),
        value: TicketType.PROBLEM_WITH_TEACHER
    },
    {
        name: t("support.types.other-problem"),
        value: TicketType.OTHER
    },
];