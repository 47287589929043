import { FC } from "react";

import { ButtonTheme, StyledButton } from "../../../../../elements/button";

import {
  StudentDropdownCardInfoWithPriceWrapper,
  StudentDropdownCardMessage,
  StudentDropdownCardPrice,
  StudentDropdownCardStatus,
  StudentDropdownCardUserInfo,
  StudentDropdownCardWrapper,
} from "./student-dropdown-card-styled";

import {
  ApplicationStatuses,
  RequestLessonApplicants,
} from "../../../../../../types";
import { postAcceptApplicant } from "../../../../../../api/lesson-api/postAcceptApplicant";
import { TType, useTranslate } from "../../../../../../assets/translations";
import { useNavigate } from "react-router-dom";
import { getUsersPicture } from "../../../../../../services/user-service";
import { TeachingMeSuccessNotification } from "../../../../../elements/notification";
import { isAxiosError } from "axios";
import { MoneyCard } from "../../../../../../assets/images/svg-elements/money-card";
import { useFormattedMoreMessage } from "../../../../../../hooks/useFormattedMoreMessage";
import CardActionModal from "../../../../../modals/card-action-modal/card-action-modal";
import { useQueryClient } from "@tanstack/react-query";
import LessonRequestStatus from "../../../lesson-request-status/lesson-request-status";
import OpenContactsButton from "../../../lesson-request-action-buttons/open-contacts/open-contacts";
import { useIsResponsive } from "../../../../../../hooks/useIsResponsive";
import { CardMessageButton } from "../../../lesson-request-action-buttons/lesson-request-action-buttons";

interface StudentDropdownCardProps {
  teacher: RequestLessonApplicants;
  connectionRequestId: string;
  studentId: string;
  t: TType;
  openAcceptModal: (applicantId: string) => void;
  openRejectModal: (applicantId: string) => void;
}

const StudentDropdownCard: FC<StudentDropdownCardProps> = ({
  teacher,
  connectionRequestId,
  studentId,
  t,
  openAcceptModal,
  openRejectModal,
}) => {
  const {
    message,
    price,
    applicantName,
    id: applicationId,
    applicantAvatarId,
    status,
  } = teacher;

  const { language } = useTranslate();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isSmallDesktop, isTablet, isMobile, isBigDesktop } =
    useIsResponsive();

  const {
    isMessageLongerThanMaxSymbols,
    formattedMessage,
    handleIsShowMore,
    isShowMore,
  } = useFormattedMoreMessage(message, !isBigDesktop ? 100 : 30);

  const handleAcceptClick = () => {
    openAcceptModal(applicationId);
  };

  // Reject button handler
  const handleRejectClick = () => {
    openRejectModal(applicationId);
  };

  const buttonDisableCondition =
    status !== ApplicationStatuses.ACTIVE &&
    status !== ApplicationStatuses.CONTACTS_OPENED;

  const showButtonsCondition = status !== ApplicationStatuses.ACCEPTED &&
  status !== ApplicationStatuses.REJECTED;

  return (
    <StudentDropdownCardWrapper showButtonsCondition={showButtonsCondition}>
      {(isSmallDesktop || isTablet) && (
        <StudentDropdownCardInfoWithPriceWrapper>
          <StudentDropdownCardUserInfo>
            <img src={getUsersPicture(applicantAvatarId)} alt="user avatar" />
            <div className="personal_info">
              <div>
                <p>{applicantName}</p>
              </div>
            </div>
          </StudentDropdownCardUserInfo>

          <StudentDropdownCardPrice>
            <div>
              <MoneyCard />
              <p>
                {price
                  ? `${price} ${t("main-page.search-query.uah")}`
                  : t("my-students.not-specified")}
              </p>
            </div>
          </StudentDropdownCardPrice>

          <StudentDropdownCardStatus>
            <LessonRequestStatus status={status} t={t} />
          </StudentDropdownCardStatus>
        </StudentDropdownCardInfoWithPriceWrapper>
      )}

      {isMobile && (
        <StudentDropdownCardInfoWithPriceWrapper>
          <StudentDropdownCardUserInfo>
            <img src={getUsersPicture(applicantAvatarId)} alt="user avatar" />
            <div className="personal_info">
              <div>
                <p>{applicantName}</p>
              </div>
            </div>
          </StudentDropdownCardUserInfo>

          <StudentDropdownCardPrice>
            <div>
              <MoneyCard />
              <p>
                {price
                  ? `${price} ${t("main-page.search-query.uah")}`
                  : t("my-students.not-specified")}
              </p>
            </div>
          </StudentDropdownCardPrice>
        </StudentDropdownCardInfoWithPriceWrapper>
      )}

      {!isMobile && !isTablet && !isSmallDesktop && (
        <>
          <StudentDropdownCardUserInfo>
            <img src={getUsersPicture(applicantAvatarId)} alt="user avatar" />
            <div className="personal_info">
              <div>
                <p>{applicantName}</p>
              </div>
            </div>
          </StudentDropdownCardUserInfo>

          <StudentDropdownCardPrice>
            <div>
              <MoneyCard />
              <p>
                {price
                  ? `${price} ${t("main-page.search-query.uah")}`
                  : t("my-students.not-specified")}
              </p>
            </div>
          </StudentDropdownCardPrice>
        </>
      )}

      {!isTablet && !isSmallDesktop && (
        <StudentDropdownCardStatus>
          <LessonRequestStatus status={status} t={t} />
        </StudentDropdownCardStatus>
      )}

      <StudentDropdownCardMessage>
        {message ? (
          <div className="container">
            {isShowMore ? (
              <>
                <p>{message}</p>
                {isMessageLongerThanMaxSymbols && (
                  <button
                    onClick={handleIsShowMore}
                    className="load-more-message-btn"
                  >
                    Less
                  </button>
                )}
              </>
            ) : (
              <>
                <p>
                  {isMessageLongerThanMaxSymbols
                    ? `${formattedMessage}...`
                    : formattedMessage}
                </p>
                {isMessageLongerThanMaxSymbols && (
                  <button
                    onClick={handleIsShowMore}
                    className="load-more-message-btn"
                  >
                    More
                  </button>
                )}
              </>
            )}
          </div>
        ) : (
          <p>{t("my-students.not-specified")}</p>
        )}
      </StudentDropdownCardMessage>

      {showButtonsCondition && (
        <div className="buttons">
          <StyledButton
            theme={ButtonTheme.BLUE}
            onClick={handleAcceptClick}
          >
            Accept
          </StyledButton>
          <StyledButton
            theme={ButtonTheme.WHITE}
            onClick={handleRejectClick}
            disabled={buttonDisableCondition}
          >
            Decline
          </StyledButton>

          {status === ApplicationStatuses.CONTACTS_OPENED ? (
            <CardMessageButton
              language={language}
              navigate={navigate}
              studentId={studentId}
              t={t}
              teacherId={teacher.applicantId}
              theme={ButtonTheme.INVERT_BLUE}
              disabled={buttonDisableCondition}
              type="icon"
            />
          ) : (
            <OpenContactsButton
              language={language}
              navigate={navigate}
              studentId={studentId}
              teacherId={teacher.applicantId}
              theme={ButtonTheme.INVERT_BLUE}
              t={t}
              connectionRequestId={connectionRequestId}
              applicationId={applicationId}
              disabled={buttonDisableCondition}
            />
          )}
        </div>
      )}
    </StudentDropdownCardWrapper>
  );
};

export default StudentDropdownCard;
