import {Translations, TType} from "../assets/translations";
import {LessonsType, LessonsTypeUk} from "../types";


export const getLocalizedLessonType = (locale: Translations): typeof LessonsType | typeof LessonsTypeUk => {
    switch (locale) {
        case Translations.en:
            return LessonsType;
        case Translations.uk:
            return LessonsTypeUk
    }
}

export const formatAge = (age: number, locale: Translations, t: TType) => {
    if (locale === "en") return t("lesson-requests.cards.age-short");

    if (age === 1) {
        return t("lesson-requests.cards.age-short-1");
    } else if (age >= 2 && age <= 4) {
        return t("lesson-requests.cards.age-short-2");
    } else {
        return t("lesson-requests.cards.age-short-3");
    }
}