import React, {FC, useEffect} from "react";
import {LeftOutlined} from "@ant-design/icons";
import {Outlet, useLocation, useNavigate} from "react-router-dom";

import {ConnectRequestPageStyled} from "./connect-request-page.styled";
import {TType} from "../../../assets/translations";
import {activeSlotsKey, removeFromLS} from "../../../services/storage-service";
import WithSiderWrapper from "../../layout-components/wrappers/page-with-sider/page-with-sider";
import {useMediaQuery} from "react-responsive";
import {device} from "../../constants";

interface Props {
  t: TType
}

export const ConnectRequestPage: FC<Props> = ({t}) => {

  const navigate = useNavigate();

  const location = useLocation();

  const isDesktop = useMediaQuery({
    query: `(${device.desktop})`,
  });

  const isStepTwo = location.pathname.endsWith("/step-two");

  useEffect(() => {

    return () => {
      removeFromLS(activeSlotsKey);
    }
  }, []);

  return (
    <WithSiderWrapper background={isDesktop ? "#ECEFF2" : "#FFF"}>
      <ConnectRequestPageStyled>
        <div className="top-info">
          <div className="navigation" onClick={() => navigate(-1)}><LeftOutlined/></div>
          <div className="info">
            <h2>{t("connect-request-page.title")}</h2>
            <div>
            <span className="step">
              {isStepTwo ? t("connect-request-page.step-two") : t("connect-request-page.step-one")}</span>
              <span className="description">
             {isStepTwo ? t("connect-request-page.step-two-description") : t("connect-request-page.step-one-description")}
            </span>
            </div>
          </div>
        </div>

        <Outlet/>
      </ConnectRequestPageStyled>
    </WithSiderWrapper>
  )
};