import {FeedbackType, TFeedbackBody} from "../../../types";


export const initialFeedback: TFeedbackBody = {
    id: "32ejfso3sdfl",
    type: FeedbackType.GENERAL,
    email: "",
    rating: 1,
    comment: "",
};

export const initialSuggestCategory: TFeedbackBody = {
    id: "32ejfso3sdfdfghdkjsf45d3",
    type: FeedbackType.CATEGORY_REQUEST,
    email: "",
    requestedCategory: ""
}