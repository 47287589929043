import styled from "styled-components";

export const SupportIssueStyled = styled.div`
    border-radius: 16px;
    background: #fafcff;
    padding: 12px;
    display: grid;
    grid-template-columns: 30% 30% 20% 10% 10%;
    align-items: center;
    
    div.border-right {
        border-right: 1px solid #d6e4ff;
        height: 100%;
    }


    .email-and-date {
        display: flex;
        flex-direction: column;
        gap: 8px;

        & > div:first-child {
            font-size: 16px;
            font-weight: 500;
        }
        
        & > div:nth-child(2) {
            font-size: 15px;
            color: gray;
        }
    }
    
    .issue-details-wrapper {
        display: flex;
        align-items: center;
    }
    
    .issue-details {
        padding: 0 16px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        line-height: 1.5;
    }
    
    .issue-type, .issue-status {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .details {
        display: flex;
        justify-content: flex-end;
    }
`