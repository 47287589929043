import styled from "styled-components";
import { device } from "../../constants";

export const ContentContainer = styled.div``;

export const LessonRequestTitleContainer = styled.section`
  h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: rgb(24, 6, 125);
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.45);
  }
`;

export const LessonRequestMainContainer = styled.div`
  padding: 32px;
  background: #ffffff;
  width: 100%;
  max-height: fit-content;
  min-height: calc(100vh - 300px);
  margin-bottom: 24px;
  border-radius: 16px;

  .cards {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  @media(${device.mobileMax}) {
    padding: 0px;
    
    .cards {
      gap: 24px;
    }
  }

  @media(${device.tabletMin}) and (${device.smallDesktop}) {
    .cards {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
`;
