import {FC} from "react";
import {Drawer} from "antd";

import {Form} from "../../shared-form";
import {useDisableScroll} from "../../../../../hooks";
import {TFeedbackBody} from "../../../../../types";
import {TType} from "../../../../../assets/translations";


interface Props {
    t: TType;
    open: boolean;
    approveFeedback: (feedback: TFeedbackBody) => void;
    cancelFeedback: () => void;
    suggestCategory?: boolean;
}

export const MobileForm: FC<Props> = ({t, open, approveFeedback, cancelFeedback, suggestCategory}) => {

    useDisableScroll(open, true);


    return <Drawer open={open} closable={false} width="100vw">
        <Form t={t} approveFeedback={approveFeedback} cancelFeedback={cancelFeedback} suggestCategory={suggestCategory}/>
    </Drawer>
};
