import styled from "styled-components";
import {device} from "../../../../constants";

export const StepOneStyled = styled.div`
    display: flex;
    column-gap: 24px;

    @media (min-width: 1200px) and (max-width: 1400px) {
        column-gap: 16px;

        .lesson-picker {
            >div {
                padding: 16px;
            }
            width: 75%;
            max-width: 75%;
        }

        .connect-request-form {
            width: 25%;
            padding: 16px;
            
            .active-slots-container {
                min-width: auto;
                width: 100%;
                grid-template-columns: 1fr;
            }
        }
    }
    
    @media (min-width: 1023px) and (max-width: 1200px) {
        flex-direction: column;
        gap: 24px;
        
        .connect-request-form {
            margin-bottom: 24px;
        }
    }
    
    @media (${device.tabletMax}) {
        flex-direction: column;
        gap: 24px;
        
        .connect-request-form {
            padding-bottom: 8px;
        }
    }
`