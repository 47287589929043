import styled from "styled-components";
import { device } from "../../../../constants";
import {
  CardContainer,
  LessonRequestCardTimeInfo,
  LessonRequestCardUserInfo,
} from "../lesson-request-card-styled";

export const StandartCardBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 1px solid rgb(214, 228, 255);
  height: 100%;
  padding: 5px 10px;

  .card-section-title {
    color: #595959;
  }

  @media (${device.mobileMax}) {
    border-bottom: 1px solid #d6e4ff;
    border-left: none;
    padding: 12px 0px;
    width: 100%;
    justify-content: center;
  }

  @media (${device.tabletMin}) and (${device.tabletMax}) {
    padding: 12px 0px;
    width: 100%;
    justify-content: center;
  }
`;

export const TeacherPublicCardPrice = styled(StandartCardBlock)`
  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 25%;
    padding: 12px 10px;
    border-left: none;
    align-items: flex-start;

  }
`;

export const TeacherPublicCardLevel = styled(StandartCardBlock)`
  div {
    display: flex;
    gap: 4px;
  }

  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 25%;
    padding: 12px 0px;
  }
`;

export const TeacherPublicAge = styled(StandartCardBlock)``;

export const TeacherPublicMessage = styled(StandartCardBlock)`
  .container {
    background: #fff;
    width: 100%;
    height: 100%;
    font-size: 12px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    position: relative;
    text-wrap: wrap;
    word-break: break-word;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex: 1;
    border-radius: 8px;

    .load-more-message-btn {
      bottom: -5px !important;
      right: 0 !important;
      cursor: pointer;
    }
  }

  .container button {
    color: #18067d;
    border: none;
    text-decoration: underline;
    background: transparent;
    padding-left: 4px;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  p {
    font-size: 12px;
  }

  @media (${device.smallDesktop}) {
    .container {
      max-width: 100%;
      padding: 8px;
    }
  }

  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 50%;
    border-left: none;
  }
`;

export const TeacherPublicCardButtons = styled(StandartCardBlock)`
  @media (${device.smallDesktop}) {
    border-bottom: none;
  }
  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 40%;
  }
`;

export const TeacherPublicCardContainer = styled(CardContainer)`
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr 1fr;

  @media(min-width: 1281px) and (max-width: 1550px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 2fr 1.5fr;
    margin-bottom: 10px;
  }

  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    padding: 0px 0px 12px 0px;
  }

  @media(${device.mobileMax}) {
    display: flex;
    flex-direction: column;
  }
`;

export const TeacherPublicRequestUserInfo = styled(LessonRequestCardUserInfo)`
  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 25%;
    border-bottom: none;
    border-right: 1px solid #d6e4ff;
    height: 76px;
    padding: 12px 0px;
  }
`;

export const TeacherPublicRequestTimeInfo = styled(LessonRequestCardTimeInfo)`
  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 25%;
    align-items: flex-start;
    padding: 0px 10px;
  }
`;
