import styled from "styled-components";
import {VerifiedIcon} from "../../../../../assets/images/svg-elements/verified-icon-new";
import {device} from "../../../../constants";

export const ConnectRequestFormStyled = styled.div`
    border-radius: 16px;
    background-color: #FFFFFF;
    padding: 24px;
    height: fit-content;

    .border-bottom {
        border-bottom: 0.5px solid #BFBFBF;
        padding-bottom: 16px;
    }

    .teacher-info {
        display: flex;
        column-gap: 16px;

        .image {
            position: relative;
            height: fit-content;

            > img {
                border-radius: 50%;
                width: 56px;
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .name {
                display: flex;
                column-gap: 5px;

                h3 {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    text-align: left;
                }
            }

            .skill {
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                text-align: left;
                color: #595959;
            }
        }
    }

    .next-step-button {
        margin-top: 32px;
    }
    
    @media (${device.tabletMax}) {
        padding: 0px;
    }
`

export const RequestOverviewActiveSlotsStyled = styled.div`

    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    &.role-teacher {
        padding-top: 0;

        .active-slots-container {
                min-height: 40px;
        }
    }

    h3 {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        color: #595959;
    }

    .active-slots-container {
        min-height: 88px;
        min-width: 340px;
        gap: 8px;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .active-slot {
            border-radius: 8px;
            border: 1px solid #5D51A4;
            background-color: #E8E6F2;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            padding: 9px 8px;
            height: fit-content;
            text-wrap: nowrap;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                font-size: 16px;
                margin-left: 8px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    
    @media(${device.mobileMax}) {
        .active-slots-container {
            min-width: auto;
            display: flex;
            flex-direction: column;
            .active-slot { 
                width: fit-content;
                min-width: 165px;
            }
        }
    }
`

export const VerifiedIconStyled = styled(VerifiedIcon)`
    min-width: 14px;
    min-height: 17px;
    width: 14px;
    height: 17px;
`