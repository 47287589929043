import { FC, useMemo, useState } from "react";
import { TType } from "../../../assets/translations";
import {
  SecondPartWrapper,
  TeacherDropdownForm,
  TeacherPublicRequestAcceptPopupWrapper,
} from "./teacher-public-request-accept-styled";
import { postUserApplications } from "../../../api/user-api/postUserApplication";
import { TeachingMeErrorNotification } from "../../elements/notification";
import { isAxiosError } from "axios";
import Input from "../../elements/input/input";
import TextArea from "../../elements/textarea/textarea";
import { ButtonTheme, StyledButton } from "../../elements/button";
import { CloseIcon } from "../../../assets/images/svg-elements/close";
import { MailIcon } from "../../../assets/images/svg-elements/mail-icon";
import { useQueryClient } from "@tanstack/react-query";

interface Props {
  t: TType;
  open: boolean;
  onCancel: () => void;
  connectionRequestId: string;
}

function generateHintForEmptyFields(t: TType, message: string, price: number) {
  return (
    <>
      <p>{t("lesson-requests.disable-hint.title")}</p>
      <ul>
        {!message && <li>{t("lesson-requests.modals.price")}</li>}
        {!price && <li>{t("lesson-requests.modals.message")}</li>}
      </ul>
    </>
  );
}

const formDataEmpty = {
  message: "",
  price: 0,
};

const TeacherPublicRequestAcceptPopup: FC<Props> = ({
  t,
  open,
  onCancel,
  connectionRequestId,
}) => {
  const [formData, setFormData] = useState(formDataEmpty);
  const [renderSecondPart, setRenderSecondPart] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const handleChangeMessage = (value: string) => {
    setFormData((prev) => ({ ...prev, message: value }));
  };

  const handleChangePrice = (value: string) => {
    setFormData((prev) => ({ ...prev, price: +value }));
  };

  const disableCondition = !formData.message && !formData.price;

  const handleSubmitForm = async () => {
    try {
      const res = await postUserApplications(connectionRequestId, formData);

      if (res.status === 200) {
        queryClient.refetchQueries(["teacherPublicRequests"]);
        setRenderSecondPart(true);
      }
    } catch (error) {
      if (isAxiosError(error)) {
        const errorMessage = error.response?.data.message;
        TeachingMeErrorNotification(errorMessage);
      }
    }
  };

  const handleCancel = () => {
    setFormData(formDataEmpty);
    setRenderSecondPart(false);
    onCancel();
  };

  const secondPart = useMemo(() => {
    return (
      <SecondPartWrapper>
        <div className="close-button-div">
          <button onClick={handleCancel}>
            {t("lesson-requests.modals.close")}
          </button>
        </div>
        <div className="second-main">
          <MailIcon />
          <h5>{t("lesson-requests.modals.extra-info-sent")}</h5>
        </div>
      </SecondPartWrapper>
    );
  }, [renderSecondPart]);

  return (
    <TeacherPublicRequestAcceptPopupWrapper
      open={open}
      footer={null}
      closable={false}
    >
      {renderSecondPart ? (
        secondPart
      ) : (
        <>
          <div className="accept-popup-header">
            <h1>{t("lesson-requests.modals.add-extra-information")}</h1>
            <CloseIcon className="close" onClick={handleCancel} />
          </div>
          <TeacherDropdownForm>
            <div className="inputs">
              <Input
                type="number"
                onChange={handleChangePrice}
                label={t("lesson-requests.modals.price")}
                required
                value={formData.price.toString()}
              />
              <TextArea
                onChange={handleChangeMessage}
                label={t("lesson-requests.modals.message")}
                placeholder={t("lesson-requests.modals.message-placeholder")}
                required
                value={formData.message}
              />
            </div>
            <StyledButton
              theme={ButtonTheme.BLUE}
              disabled={disableCondition}
              onClick={handleSubmitForm}
              hintForDisabledButton={generateHintForEmptyFields(
                t,
                formData.message,
                formData.price
              )}
              type="submit"
            >
              {t("lesson-requests.modals.offer")}
            </StyledButton>
          </TeacherDropdownForm>
        </>
      )}
    </TeacherPublicRequestAcceptPopupWrapper>
  );
};

export default TeacherPublicRequestAcceptPopup;
