import styled from "styled-components";

export const FeedbacksSectionStyled = styled.section`

  
  .feedbacks-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
  }
`