import axiosClient from "../../config/axiosConfig";
import {GET_FEEDBACKS} from "../endpoints";
import {TFeedbacksResponse} from "../../types";

export const getFeedbacks = async (page: number):Promise<TFeedbacksResponse> => {
  return axiosClient.get(GET_FEEDBACKS(), {
    params: {
      page
    }
  });
}