import styled from "styled-components";
import { InviteUserPopupWrapper } from "../invite-user-popup/invite-user-popup-styled";

export const ClarificationNeededPopupWrapper = styled(InviteUserPopupWrapper)`
  p {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3em;
  }
`;
