import { createGlobalStyle, css } from "styled-components";

interface Props {
  isMobile: boolean;
}

interface SiderProps {
  isSiderOpen: boolean;
  shouldRenderProfileSider: boolean;
  isAuthenticated?: boolean;
}

export const CriticalStyles = createGlobalStyle<Props>`
    
    :root {
        --header-offset: ${({ isMobile }) => (isMobile ? 63 : 54)}px;
        --sider-collapsed-width: 80px;
        --sider-opened-width: 277px;
        --container-padding: 24px;
    }
    
    p {
        margin: 0;
    }
  .notification {
    border-radius: 10px;

    .anticon-exclamation-circle {
      color: red;
    }
  }

  .ant-popover-inner {
    border-radius: 16px;
    max-height: 435px;
    overflow: auto;
    overscroll-behavior: contain;
  }

  .notifications-popover {
    .ant-popover-inner {
      overflow: hidden !important;
    }

  }

  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-select-dropdown {
    border-radius: 8px;

    .ant-select-item-option-selected {
      background: #F5F5F5;
    }
  }

  .required {
    display: flex;
    column-gap: 4px;

    &:after {
      content: "*";
      color: red;
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #FAFAFD;
  }

  ::-webkit-scrollbar-thumb {
    background: #dcdbdb;
    border-radius: 99px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #a8a6a6;
    border-radius: 99px;
  }

  main {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 57px) !important;
  }

  .ant-select-item-empty {
    .not_found_wrapper {
      gap: 10px;
      svg {
        width: auto;
        height: 53px;
      }
      .not_found_details {
        h2 {
          font-size: 14px;
          line-height: 1.5715;
          color: #00000040;
        }
      }
    }
  }

  @media screen and (max-width: 1023px) {
    main {
      min-height: calc(100vh - 23px) !important;
    } 
  }

  @media screen and (max-width: 767px) {
    main {
      min-height: 100vh !important;
    }
  }

  * {
    font-family: "Inter", sans-serif;
    font-style: normal;
  }
`;

export const siderOptimizationStyle = css<SiderProps>`
  padding-left: ${({ isSiderOpen, shouldRenderProfileSider, isAuthenticated = true }) => {
    if (!shouldRenderProfileSider || !isAuthenticated) {
      return "24px";
    }

    return isSiderOpen
      ? "calc(var(--sider-opened-width) + var(--container-padding))"
      : "calc(var(--sider-collapsed-width) + var(--container-padding))";
  }};

  transition: padding 0.3s ease-out;
`;

export const hideScroll = css`
  // IE and Edge
  -ms-overflow-style: none;

  // Firefox
  scrollbar-width: none;

  // Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }
`;
