import {FC} from "react";

import {BlockUserConfirmationPopupStyled} from "./block-user-confirmation-popup.styled";
import {ButtonTheme, StyledButton} from "../../../../../elements/button";

interface Props {
  open: boolean;
  onCancel?: () => void;
}

export const BlockUserConfirmationPopup:FC<Props> = ({open, onCancel})=> {
  return (
    <BlockUserConfirmationPopupStyled open={open} onCancel={onCancel} closable={false} footer={null}>
      <h1>Підтвердження блокування</h1>
      <p className="main-description">
        Ви впевнені, що хочете заблокувати цього користувача? Після блокування він втратить доступ до платформи та всіх її можливостей.
      </p>

      <div className="buttons">
        <StyledButton className="approve"
                      theme={ButtonTheme.WHITE}>
          Підтвердити
        </StyledButton>
        <StyledButton onClick={onCancel} className="cancel">
          Скасувати
        </StyledButton>
      </div>
    </BlockUserConfirmationPopupStyled>
  )
}