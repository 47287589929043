import styled from "styled-components";
import { device } from "../../../constants";

export const LessonRequestCardWrapper = styled.div`
  width: 100%;
  border-radius: 16px;
  background: #fafcff;
  padding: 12px;
`;

export const LessonRequestCardLine = styled.div`
  width: 52px;
  height: 1px;
  background: #d6e4ff;
  transform: rotate(90deg);
  margin: 0 auto;
`;

export const LessonRequestCardUserInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  height: 100%;

  .personal_info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;

    p {
      color: #18067d;
      font-weight: 500;
    }

    > div {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;

      a {
        width: 100%;
      }

      p {
        font-size: 14px;
        line-height: 22px;
        color: #000;
        font-weight: 400;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  img {
    max-width: 44px;
    max-height: 44px;
    border-radius: 100%;
  }

  svg {
    stroke: #18067d;
  }

  @media (${device.smallDesktop}) {
    width: 100%;
    justify-content: center;
    padding: 12px 0px;
    border-bottom: 1px solid #d6e4ff;

    .personal_info {
      flex: none;
      > div {
        p {
          max-width: 100%;
        }
      }
    }
  }

  @media(min-width: 1281px) and (max-width: 1440px) {
    padding-right: 10px;
  }
`;

export const LessonRequestCardTimeInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-left: 1px solid #d6e4ff;
  word-break: break-word;
  padding: 0px 10px;
  justify-content: center;

  div {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      min-width: 16px;
    }
  }

  @media (${device.mobileMax}) {
    border-bottom: 1px solid #d6e4ff;
    border-left: none;
    padding: 12px 0px;
    width: 100%;
    align-items: center;
  }

  @media (${device.tabletMin}) and (${device.tabletMax}) {
    padding: 12px 0px;
    width: 100%;
    align-items: center;
  }

  @media();
`;

export const LessonRequestValidUntil = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid #d6e4ff;

  .card-section-title {
    color: #595959;
  }

  div {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 8px;
  }

  @media (${device.smallDesktop}) {
    border-left: none;
    border-bottom: 1px solid #d6e4ff;
    padding: 12px 0px;
    width: 100%;
  }
`;

export const LessonRequestActionButtonsWrapper = styled.div<{
  view?: "card" | "banner";
}>`
  display: flex;
  gap: 18px;
  justify-content: ${({ view }) => (view === "card" ? "center" : "flex-end")};
  align-items: center;
  border-left: ${({ view }) =>
    view === "card" ? "1px solid #d6e4ff" : "none"};
  margin-top: ${({ view }) => (view === "card" ? "0px" : "16px")};
`;

export const LessonRequestStatusWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #d6e4ff;
  height: 100%;

  @media (${device.mobileMax}) {
    border-bottom: 1px solid #d6e4ff;
    border-left: none;
    padding: 12px 0px;
    width: 100%;
  }

  @media (${device.tabletMin}) and (${device.tabletMax}) {
    padding: 12px 0px;
    width: 100%;
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

  @media (${device.mobileMax}) {
    display: flex;
    flex-direction: column;
  }

  align-items: center;
`;

export const LessonRequestMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-left: 1px solid #d6e4ff;

  p {
    white-space: break-spaces;
  }
`;
