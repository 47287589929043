import React, { FC, useMemo } from "react";

import { TType } from "../../../../assets/translations";

import { CustomLoader } from "../../../elements/custom-loader";
import { NotFoundComponent } from "../../../elements/not-found-component";

import LessonRequestHeader from "../lesson-request-header/lesson-request-header";

import { TeacherLessonMenuTabs, UnionTabsType } from "../lesson-request";

import { FAQMenu } from "../../../../types/FAQ";
import {
  AdminProfile,
  GetRequestsLessonStatuses,
  LessonRequestType,
  RequestLesson,
  TUserProfileData,
} from "../../../../types";
import TeacherPublicCard from "../lesson-request-card/teacher-public-card/teacher-public-card";
import TeacherDirectCard from "../lesson-request-card/teacher-direct-card/teacher-direct-card";
import { NavLink } from "react-router-dom";

export interface UserLessonRequestProps {
  t: TType;
  requests: RequestLesson[];
  isSwitchActive: boolean;
  setIsSwitchActive: (state: boolean) => void;
  isLoading: boolean;
  selectedKey: UnionTabsType;
  handleChangeSelectedKey: (key: UnionTabsType) => void;
  user: AdminProfile | TUserProfileData | null;
  categoryIds: string[];
}

enum ReqTabs {
  REQUESTS_FROM_STUDENTS = "requests-from-students",
  OPPORTUNITY_SPOT = "opportunity-spot",
}

const SEARCH_MAPPING: Record<keyof TeacherLessonMenuTabs, boolean> = {
  opportunity_spot: false,
  requests_from_students: false,
};

const FILTER_INPUT_MAPPING: Record<
  keyof TeacherLessonMenuTabs,
  [boolean, boolean]
> = {
  requests_from_students: [false, false],
  opportunity_spot: [true, false],
};

const TeacherLessonRequest: FC<UserLessonRequestProps> = ({
  t,
  requests,
  isSwitchActive,
  setIsSwitchActive,
  isLoading,
  selectedKey,
  handleChangeSelectedKey,
  user,
  categoryIds,
}) => {
  const selectedTeacherKey = selectedKey as keyof TeacherLessonMenuTabs;

  const MENU_TITLES: FAQMenu<TeacherLessonMenuTabs>[] = [
    {
      id: 0,
      label: t(`lesson-requests.tabs.${ReqTabs.REQUESTS_FROM_STUDENTS}`),
      key: "requests_from_students",
    },
    {
      id: 1,
      label: t(`lesson-requests.tabs.${ReqTabs.OPPORTUNITY_SPOT}`),
      key: "opportunity_spot",
    },
  ];

  const REQUEST_LIMIT_MAPPING: Record<keyof TeacherLessonMenuTabs, boolean> = {
    requests_from_students: false,
    opportunity_spot: false,
  };

  const renderCards = useMemo(() => {
    if (isLoading) {
      return <CustomLoader />;
    }

    if (!requests || requests.length === 0) {
      return (
        <NotFoundComponent
          text={
            <p>
              {t("lesson-requests.not-found-first-half")}{" "}
              <NavLink to="/lesson-requests/opportunity-spot?filter=ALL">
                {t("lesson-requests.not-found-second-half")}
              </NavLink>
            </p>
          }
          style={{ height: "60vh" }}
        />
      );
    }

    return (
      <div className="cards">
        {requests.map((request) =>
          request.type === LessonRequestType.STUDENT_DIRECT ? (
            <TeacherDirectCard card={request} t={t} key={request.id} />
          ) : (
            <TeacherPublicCard
              card={request}
              t={t}
              key={request.id}
              clarificationNeeded={user?.clarificationNeeded}
              categoryIds={categoryIds}
            />
          )
        )}
      </div>
    );
  }, [isLoading, requests, t]);

  return (
    <>
      <LessonRequestHeader
        isShowActiveOnly
        handleChangeSelectedKey={handleChangeSelectedKey}
        menuTitles={MENU_TITLES}
        selectedKey={selectedTeacherKey}
        t={t}
        requestsLength={
          requests.filter(
            (request) => request.status === GetRequestsLessonStatuses.CREATED
          ).length || 0
        }
        handleRenderFilterInputs={(key) => FILTER_INPUT_MAPPING[key]}
        showRequestLimit={(key) => REQUEST_LIMIT_MAPPING[key]}
        setIsSwitchActive={setIsSwitchActive}
        isSwitchActive={isSwitchActive}
        switchLabel={t("lesson-requests.switch")}
        isShowSearchFn={(key) => SEARCH_MAPPING[key]}
      />
      {renderCards}
    </>
  );
};

export default TeacherLessonRequest;
