import { Modal } from "antd";
import styled from "styled-components";

export const InviteUserPopupWrapper = styled(Modal)`
    .ant-modal-body {
        border-radius: 12px;
        background: #FFF;
        box-shadow: 0 4px 79px 0 rgba(0, 0, 0, 0.10);
        padding: 32px 42px;
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        h1 {
            color: #18067D;
            text-align: center;
            font-size: 22px;
            font-weight: 700;
        }

        .buttons {
            margin-top: 28px;
            display: flex;
            justify-content: space-between;
        }
    }
`