import {FC, ReactElement} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../components/redux";
import {Authority} from "../types";
import { Navigate } from "react-router-dom";

interface Props {
  children: ReactElement | null;
}

export const AdminSecureRoute: FC<Props> = ({children})=> {

  const {jwtParsed: {authority}} = useSelector((state: RootState) => state.tokensStore);

  if (authority === Authority.ROLE_ADMINISTRATOR) {
    return children
  }

  return <Navigate to="/"/>;
}