import { useEffect, useMemo, useRef, useState } from "react";

import { useSelector } from "react-redux";

import { TType, useTranslate } from "../../../assets/translations";

import Pagination from "../../elements/pagination/pagination";

import { usePagination } from "../../../hooks/usePagination";
import { useGetConnectionRequestsByType } from "../../../hooks/useGetConnectionRequests";

import WithSiderWrapper from "../../layout-components/wrappers/page-with-sider/page-with-sider";

import { RootState } from "../../redux";

import {
  ContentContainer,
  LessonRequestMainContainer,
  LessonRequestTitleContainer,
} from "./lesson-request-styled";

import { getPayload } from "./services/getPayload";

import StudentLessonRequest from "./student/student-lesson-request";

import TeacherLessonRequest from "./teacher/teacher-lesson-request";

import { AdminProfile, Authority, TUserProfileData } from "../../../types";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { device } from "../../constants";
import { TeacherFilterOptions } from "./lesson-request-filters/lesson-request-filters";

export interface TeacherLessonMenuTabs {
  requests_from_students: string;
  opportunity_spot: string;
}

export interface StudentMenuTabs {
  active_requests: string;
}

export type UnionTabsType = keyof (TeacherLessonMenuTabs & StudentMenuTabs);

const getDefaultSelectedKey = (pathname: string): UnionTabsType => {
  const baseUrl = "/lesson-requests";

  switch (pathname) {
    case `${baseUrl}/requests-from-students`:
      return "requests_from_students";
    case `${baseUrl}/opportunity-spot`:
      return "opportunity_spot";
    case `${baseUrl}`:
      return "active_requests";
    default:
      return "active_requests";
  }
};

const LessonRequestPage = ({
  t,
  user,
}: {
  t: TType;
  user: AdminProfile | TUserProfileData | null;
}) => {
  const {
    jwtParsed: { authority },
  } = useSelector((state: RootState) => state.tokensStore);

  const isStudent: boolean = authority === Authority.ROLE_STUDENT;

  const pathname = window.location.pathname;

  const navigate = useNavigate();

  const [selectedKey, setSelectedKey] = useState<UnionTabsType>(
    getDefaultSelectedKey(pathname)
  );

  const [currentPage, setCurrentPage] = useState(1);

  const [isSwitchActive, setIsSwitchActive] = useState(true);

  const { language } = useTranslate();

  const isMobile = useMediaQuery({
    query: `(${device.mobileMax})`,
  });

  const payload = useMemo(() => {
    return getPayload({
      selectedKey,
      isStudent,
      language,
      isSwitchActive,
      currentPage,
    });
  }, [selectedKey, isStudent, language, isSwitchActive, currentPage]);

  const [searchParams, setSearchParams] = useSearchParams();
  const filterParam = searchParams.get("filter") as TeacherFilterOptions;

  const { requests, isLoading, totalItems, refetch, categoryIds } =
    useGetConnectionRequestsByType({ payload, filterParam });

  const {
    goToNextPage,
    goToPage,
    goToPreviousPage,
    paginationRange,
    totalPages,
  } = usePagination({
    pageSize: 10,
    totalItems,
    currentPage,
    setCurrentPage,
  });

  useEffect(() => {
    setSelectedKey(getDefaultSelectedKey(pathname));
  }, [pathname]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (filterParam !== null) {
      refetch();
    }
  }, [filterParam]);

  const handleChangeSelectedKey = (key: UnionTabsType) => {
    if (isStudent) return
    navigate(`/lesson-requests/${key.replaceAll("_", "-")}`);
    goToPage(1);
  };

  const handleChangleIsSwitchActive = (state: boolean) => {
    setIsSwitchActive(state);
    goToPage(1);
  };

  return (
    <WithSiderWrapper background={!isMobile ? "#ECEFF2" : "#FFFFFF"}>
      <ContentContainer className="content-container">
        <LessonRequestTitleContainer>
          <h3>{t("lesson-requests.title")}</h3>
          <p>{t("lesson-requests.description")}</p>
        </LessonRequestTitleContainer>
        <LessonRequestMainContainer>
          {isStudent ? (
            <StudentLessonRequest
              t={t}
              requests={requests}
              isLoading={isLoading}
              isSwitchActive={isSwitchActive}
              setIsSwitchActive={handleChangleIsSwitchActive}
              selectedKey={selectedKey}
              handleChangeSelectedKey={handleChangeSelectedKey}
              user={user}
              categoryIds={categoryIds}
            />
          ) : (
            <TeacherLessonRequest
              t={t}
              requests={requests}
              isLoading={isLoading}
              isSwitchActive={isSwitchActive}
              setIsSwitchActive={handleChangleIsSwitchActive}
              selectedKey={selectedKey}
              handleChangeSelectedKey={handleChangeSelectedKey}
              user={user}
              categoryIds={categoryIds}
            />
          )}
        </LessonRequestMainContainer>
        {totalPages !== 0 && (
          <Pagination
            currentPage={currentPage}
            goToNextPage={goToNextPage}
            goToPage={goToPage}
            goToPreviousPage={goToPreviousPage}
            paginationRange={paginationRange}
            totalPages={totalPages}
          />
        )}
      </ContentContainer>
    </WithSiderWrapper>
  );
};

export default LessonRequestPage;
