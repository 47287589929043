import styled from "styled-components";
import { device } from "../../../constants";

export const LessonRequestHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .requests-menu {
    border-bottom: none;
    max-width: 420px;
    flex: 1;
  }

  margin-bottom: 24px;

  @media (${device.tabletMax}) {
    flex-direction: column;
    align-items: center;

    .requests-menu {
      max-width: 100%;
      width: 100%;

      button {
        flex: 1;
      }
    }
  }
`;

export const LessonRequestHeaderFilter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  flex: 1;

  > div {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  @media (${device.mobileMax}) {
    flex-direction: column;
  }

  @media (${device.tabletMax}) {
    gap: 16px;
    width: 100%;

    > div {
        width: 100%;
        justify-content: flex-end; 
    }
  }
`;
