import styled from "styled-components";
import {
  TeacherDirectAge,
  TeacherDirectCardButtons,
  TeacherDirectCardTimeSlot,
  TeacherDirectCardTimeSlots,
} from "../teacher-direct-card/teacher-direct-card-styled";
import {
  CardContainer,
  LessonRequestCardTimeInfo,
  LessonRequestCardUserInfo,
  LessonRequestStatusWrapper,
} from "../lesson-request-card-styled";
import { device } from "../../../../constants";
import { StudentPublicMessage } from "../student-public-card/student-public-card-styled";
import { StandartCardBlock } from "../teacher-public-card/teacher-public-card-styled";

export const StudentDirectCardTimeSlots = styled(TeacherDirectCardTimeSlots)`
  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 50%;
    justify-content: flex-start;
  }
`;

export const StudentDirectCardTimeSlot = styled(TeacherDirectCardTimeSlot)``;

export const StudentDirectAge = styled(TeacherDirectAge)`
  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 33.3%;
    border-bottom: none;
  }

  @media (${device.mobileMax}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StudentDirectCardButtons = styled(StandartCardBlock)`
  flex-direction: row;
  gap: 16px;

  @media (${device.smallDesktop}) {
    border-bottom: none;
  }

  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 100%;
    border-left: none;
  }
`;

export const StudentDirectCardContainer = styled(CardContainer)`
  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 16px;
    display: flex;
  }
`;

export const StudentDirectCardUserInfo = styled(LessonRequestCardUserInfo)`
  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 33.3%;
    border-bottom: none;
    border-right: 1px solid #d6e4ff;
    padding: 0;
    justify-content: flex-start;
  }
`;

export const StudentDirectCardTimeInfo = styled(LessonRequestCardTimeInfo)`
  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 33.3%;
    border-bottom: none;
    border-left: none;
  }

  div {
    justify-content: center;
  }
`;

export const StudentDirectCardStatusWrapper = styled(
  LessonRequestStatusWrapper
)`
  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 50%;
  }
`;

export const StudentDirectMessage = styled(StudentPublicMessage)`
 .container {
    .load-more-message-btn {
      bottom: -15px !important;
      right: -10px !important;
    }
  }
`