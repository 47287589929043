import styled from "styled-components";

export const IssueDetailsSectionStyled = styled.section`
    .navigation, .content {
        width: 100%;
        max-width: 1440px;
    }

    p {
        margin: 0;
    }

    .navigation {
        > button {
            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
            border-radius: 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: rgba(0, 0, 0, 0.85);
            padding: 4px 15px;
            display: flex;
            align-items: center;
            column-gap: 12px;
            cursor: pointer;
            transition: box-shadow 0.3s ease-in-out;
            margin-bottom: 24px;

            svg {
                height: 14px;
                width: 14px;
            }

            &:hover {
                box-shadow: rgba(0, 0, 0, 0.16) 0 0 30px 0;
            }
        }
    }

    .content {
        display: flex;
        column-gap: 32px;

        .issue, .resolve {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            width: 100%;

            .header {
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;
                color: rgb(24, 6, 125);
            }

            .content {
                width: 100%;
                background: rgb(255, 255, 255);
                border-radius: 12px;
                box-shadow: rgba(90, 91, 96, 0.05) 0 6px 12px;
                padding: 16px;
                display: flex;
                flex-direction: column;
                row-gap: 16px;

                .top-info {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .type {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                    }

                }

                .description {
                    word-break: break-all;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                }

                .attachments {
                    display: flex;
                    flex-direction: column;
                    row-gap: 12px;

                    p {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 22px;
                        color: rgb(0, 0, 0);
                    }

                    .files {
                        display: flex;
                        column-gap: 10px;
                        row-gap: 10px;
                        flex-wrap: wrap;
                    }

                }

                .date {
                    font-size: 12px;
                    line-height: 20px;
                    color: rgb(140, 140, 140);
                    font-weight: 400;
                }

                .resolver {
                    align-self: flex-end;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: rgb(0, 0, 0);
                }
            }
        }
    }
`