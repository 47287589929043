import { FC } from "react";

import { useNavigate } from "react-router-dom";

import { TType } from "../../../assets/translations";

import { ButtonTheme, StyledButton } from "../../elements/button";

import { ClarificationNeededPopupWrapper } from "./clarification-needed-popup-styled";

interface Props {
  t: TType;
  open: boolean;
  onCancel: () => void;
}

const ClarificationNeededPopup: FC<Props> = ({ t, open, onCancel }) => {
  const navigate = useNavigate();
  return (
    <ClarificationNeededPopupWrapper open={open} footer={null} closable={false}>
      <h1>{t("lesson-requests.modals.update-profile")}</h1>
      <p>{t("lesson-requests.modals.update-profile-description")}</p>
      <div className="buttons">
        <StyledButton theme={ButtonTheme.WHITE} onClick={onCancel}>
          {t("lesson-requests.modals.back")}
        </StyledButton>
        <StyledButton
          theme={ButtonTheme.BLUE}
          onClick={() => navigate("/user/settings/account")}
        >
          {t("lesson-requests.modals.to-the-settings")}
        </StyledButton>
      </div>
    </ClarificationNeededPopupWrapper>
  );
};

export default ClarificationNeededPopup;
