import styled from "styled-components";
import { device } from "../../../../constants";

export const StudentDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  gap: 12px;

  > div:first-child {
    border-top: 1px solid #d6e4ff;
  }

  .not_found_wrapper {
    padding-top: 12px;

    svg {
      width: 85px;
      height: 85px;
    }

    h2 {
        font-size: 16px;
    }
  }

  @media (${device.tabletMax}) {
    padding: 0;
  }
`;
