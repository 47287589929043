import {FC, ReactNode, useCallback, useState} from "react";
import {useMediaQuery} from "react-responsive";

import {DesktopForm, MobileForm} from "./forms";
import {TeachingMeErrorNotification, TeachingMeSuccessNotification} from "../notification";
import {postFeedback} from "../../../api/user-api";
import CookieService, {feedbackKey} from "../../../services/cookie-service";
import {device} from "../../constants";
import {TFeedbackBody} from "../../../types";
import {TType} from "../../../assets/translations";
import {today} from "../../../services/date-service";


interface Props {
  t: TType;
  title: string | ReactNode;
  suggestCategory?: boolean;
  tag?: React.ElementType;
}

export const FeedbackMultiuse: FC<Props> = ({t, title, suggestCategory = false, tag = "p"}) => {

  const isMobile = useMediaQuery({
    query: `(${device.mobileMax})`
  });

  const [showForm, setShowForm] = useState<boolean>(false);

  const approveFeedback = (feedback: TFeedbackBody) => {
    postFeedback(feedback)
      .then(() => {
        const expires = new Date(today);
        expires.setMonth(today.getMonth() + 1);

        CookieService.set(feedbackKey, "true", {expires});

        TeachingMeSuccessNotification(t("feedback.success"));
      })
      .catch(() => TeachingMeErrorNotification(t("errors.feedback-failed")))
      .finally(() => {
        setShowForm(false);
      })
  };

  const cancelFeedback = () => {
    setShowForm(false);

    const expires = new Date(today);
    expires.setDate(today.getDate() + 3);

    CookieService.set(feedbackKey, "false", {expires})
  };

  const renderForm = useCallback(() => isMobile ?
      <MobileForm t={t} open={showForm} approveFeedback={approveFeedback} cancelFeedback={cancelFeedback} suggestCategory={suggestCategory}/>
      : <DesktopForm t={t} open={showForm} approveFeedback={approveFeedback} cancelFeedback={cancelFeedback} suggestCategory={suggestCategory}/>,
    [isMobile, showForm]);

  const Tag = `${tag}` as React.ElementType;

  return <>
    {renderForm()}
      <Tag className="feedback-link" onClick={() => setShowForm(true)}>
        {title}
      </Tag>
  </>
};
