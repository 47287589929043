import styled from "styled-components";
import {
  CardContainer,
  LessonRequestCardUserInfo,
  LessonRequestCardWrapper,
  LessonRequestStatusWrapper,
} from "../../../lesson-request-card/lesson-request-card-styled";
import {
  StudentPublicCardPrice,
} from "../../../lesson-request-card/student-public-card/student-public-card-styled";
import { device } from "../../../../../constants";
import { TeacherPublicMessage } from "../../../lesson-request-card/teacher-public-card/teacher-public-card-styled";

export const StudentDropdownCardWrapper = styled(LessonRequestCardWrapper)<{ showButtonsCondition?: boolean }>`
  display: grid;
  grid-template-columns: ${({ showButtonsCondition }) => showButtonsCondition ? "1fr 0.5fr 1fr 2fr 1fr" : "1fr 0.5fr 1fr 2fr"} ;
  padding: 0;
  padding: 12px;
  border-radius: 0px;
  border-bottom: 1px solid rgb(214, 228, 255);


  .personal_info {
    flex-direction: row;
    align-items: center;
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-items: center;

    button {
      font-size: 12px;
    }
  }

  @media(${device.smallDesktop}) {
    grid-template-columns: 1fr;
    border-top: none;

    .buttons {
      border-bottom: none;
      justify-content: center;
      padding: 12px 0px;
      button {
        flex: 1;
      }
    }
  }

  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    padding: 32px 0px;

    .buttons {
      button {
        flex: 1;
      }
    }
  }
`;

export const StudentDropdownCardContainer = styled(CardContainer)`
  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const StudentDropdownCardUserInfo = styled(LessonRequestCardUserInfo)`
  @media (${device.smallDesktop}) {
    justify-content: flex-start;
    border-bottom: none;
  }

  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: auto;
  }
`;

export const StudentDropdownCardPrice = styled(StudentPublicCardPrice)`
  border-left: none;

  div {
    min-width: 50px;
    margin: 0 auto;
  }

  svg {
    stroke: none;
  }

  @media (${device.smallDesktop}) {
    div {
      width: 100%;
      display: flex;
      gap: 4px;
    }

    border-bottom: none;
  }

  @media (${device.mobileMax}) {
    div {
      flex-direction: column;
      align-items: flex-end;
    }
  }
`;
export const StudentDropdownCardMessage = styled(TeacherPublicMessage)`
  border-left: none;

  @media (${device.smallDesktop}) {
    border-bottom: none;
  }

   @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 100%;
  }
`;

export const StudentDropdownCardInfoWithPriceWrapper = styled.div`
  display: flex;

  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    justify-content: space-between;
  }
`;

export const MobileStudentPublicCardWrapper = styled.div`
  display: block;
`;

export const StudentDropdownCardStatus = styled(LessonRequestStatusWrapper)`
  border-left: none;

  @media (${device.mobileMax}) {
    border-bottom: none;
  }

  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: auto;
  }
`;
