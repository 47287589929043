import { FC, useState } from "react";
import { StudentDropdownWrapper } from "./student-dropdown-styled";
import StudentDropdownCard from "./student-dropdown-card/student-dropdown-card";
import { ApplicationStatuses, RequestLesson } from "../../../../../types";
import { TType } from "../../../../../assets/translations";
import { postAcceptApplicant } from "../../../../../api/lesson-api/postAcceptApplicant";
import { TeachingMeSuccessNotification } from "../../../../elements/notification";
import { isAxiosError } from "axios";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import CardActionModal from "../../../../modals/card-action-modal/card-action-modal";

interface StudentDropdownProps {
  t: TType;
  card: RequestLesson;
}

const handleActionApplicant = async (
  connectionRequestId: string,
  applicationId: string,
  status: ApplicationStatuses,
  queryClient: QueryClient,
  successNotification?: string,
  errorNotification?: string,
) => {
  try {
    const res = await postAcceptApplicant(
      connectionRequestId,
      applicationId,
      { status: status }
    );

    if (res.status === 200) {
      queryClient.refetchQueries(["studentActiveRequests"]);
      successNotification &&
        TeachingMeSuccessNotification(successNotification);
    }
  } catch (error) {
    if (isAxiosError(error)) {
      errorNotification && TeachingMeSuccessNotification(errorNotification);
    }
  }
};

const StudentDropdown: FC<StudentDropdownProps> = ({ t, card }) => {
  const { applicants, id: connectionRequestId, studentId } = card;

  const [selectedApplicationForAccept, setSelectedApplicantForAccept] = useState<string | null>(null);
  const [selectedApplicationForReject, setSelectedApplicantForReject] = useState<string | null>(null);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

  const queryClient = useQueryClient();

  const openAcceptModal = (applicantId: string) => {
    setSelectedApplicantForAccept(applicantId);
    setIsAcceptModalOpen(true);
  };

  const openRejectModal = (applicantId: string) => {
    setSelectedApplicantForReject(applicantId);
    setIsRejectModalOpen(true);
  };

  const handleConfirmAccept = async () => {
    handleActionApplicant(
      connectionRequestId,
      selectedApplicationForAccept ?? "",
      ApplicationStatuses.ACCEPTED,
      queryClient
    );

    setIsAcceptModalOpen(false);
    setSelectedApplicantForAccept(null);
  };

  const handleConfirmReject = async () => {
    handleActionApplicant(
      connectionRequestId,
      selectedApplicationForReject ?? "",
      ApplicationStatuses.REJECTED,
      queryClient
    );
    
    setIsRejectModalOpen(false);
    setSelectedApplicantForReject(null);
  };

  const handleCancelModal = () => {
    setIsAcceptModalOpen(false);
    setIsRejectModalOpen(false);
    setSelectedApplicantForAccept(null);
    setSelectedApplicantForReject(null);
  };

  return (
    <StudentDropdownWrapper>
      {applicants.length > 0 &&
        applicants
          .filter(applicant => applicant.status !== ApplicationStatuses.REJECTED)
          .map(applicant => (
            <StudentDropdownCard
              key={applicant.id}
              teacher={applicant}
              connectionRequestId={connectionRequestId}
              studentId={studentId}
              t={t}
              openAcceptModal={openAcceptModal}
              openRejectModal={openRejectModal}
            />
          ))
      }

      {isRejectModalOpen && (
        <CardActionModal
          open={isRejectModalOpen}
          handleRequest={handleConfirmReject}
          handleCancel={handleCancelModal}
          header="Are you sure you want to reject this request?"
          paragraph="If you reject this request, you will not be able to accept it later"
        />
      )}

      {isAcceptModalOpen && (
        <CardActionModal
          open={isAcceptModalOpen}
          handleRequest={handleConfirmAccept}
          handleCancel={handleCancelModal}
          header="Are you sure you want to accept this request?"
          paragraph="If you accept this request, you will not be able to reject it later"
        />
      )}
    </StudentDropdownWrapper>
  );
};

export default StudentDropdown;
