import axiosClient from "../../config/axiosConfig";
import {GET_USERS} from "../endpoints";
import {TUsersResponse} from "../../types";

export const getUsers = async (page: number, userName?: string, userId?: string): Promise<TUsersResponse> => {
  return axiosClient.get(GET_USERS(), {
    params: {
      page,
      userId,
      userName
    }
  });
}