import { Modal } from "antd";
import styled from "styled-components";
import { device } from "../../constants";

export const TeacherPublicRequestAcceptPopupWrapper = styled(Modal)`
  .ant-modal-body {
    background: #F7F6FB;
    border-radius: 16px;
  }

  .accept-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    h1 {
      margin: 0;
      font-size: 24px;
    }

    .close {
      cursor: pointer;
    }
  }

  @media(${device.mobileMax}) {
    h1 {
      font-size: 20px;
    }
  }
`;

export const TeacherDropdownForm = styled.div`
    display: flex;
    flex-direction: column;
    
    .inputs {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        margin-bottom: 16px;

        input {
          max-width: 176px;
        }
    }

    .input, .buttons {
        flex: 1;
    }
`

export const SecondPartWrapper = styled.div`
  .close-button-div {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 25px;

    button {
      background: none;
      border: none;
      color: #8C8C8C;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
    }
  }

  .second-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;

    h5 {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      max-width: 387px;
    }
  }
`