import {FC} from "react";
import styled from "styled-components";

const StyledRichTextDisplay = styled.p`
    word-wrap: break-word;
`
interface Props {
  htmlContent: string;
  className?: string;
}

export const RichTextDisplay: FC<Props> = ({htmlContent, className})=> {

  return <StyledRichTextDisplay className={className} dangerouslySetInnerHTML={{__html: htmlContent}}></StyledRichTextDisplay>
}
