import styled from "styled-components";
import {
  LessonRequestCardTimeInfo,
  LessonRequestCardUserInfo,
} from "../lesson-request-card-styled";
import {
  TeacherPublicCardLevel,
  TeacherPublicCardPrice,
  TeacherPublicMessage,
} from "../teacher-public-card/teacher-public-card-styled";
import { TeacherDirectCardButtons } from "../teacher-direct-card/teacher-direct-card-styled";
import { device } from "../../../../constants";

export const StudentPublicCardCategoryInfo = styled(LessonRequestCardUserInfo)`
  gap: 4px;
  justify-content: flex-start;

  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    border-bottom: none;
    width: 25%;
    border-right: 1px solid #d6e4ff;
    height: 76px;
  }
`;

export const StudentPublicCardPrice = styled(TeacherPublicCardPrice)`
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 12px 0px;

  @media (${device.mobileMax}) {
    justify-content: flex-start;
    flex-direction: row;
  }

  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    align-items: flex-start;
    padding: 0px 10px;
    width: 25%;
    border-left: none;
  }
`;

export const StudentPublicCardLevel = styled(TeacherPublicCardLevel)`
  @media (${device.mobileMax}) {
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 0px;
  }

  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 25%;
  }
`;

export const StudentPublicMessage = styled(TeacherPublicMessage)`
  @media (${device.smallDesktop}) {
    border-bottom: none;
    padding: 20px 0px;
  }

  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    border-left: none;
    width: 100%;
  }

  .container {
    .load-more-message-btn {
      bottom: -2px !important;
      right: -20px !important;
    }
  }
`;

export const StudentPublicCardButtons = styled(TeacherDirectCardButtons)<{ isApplicantsDropdownOpen?: boolean }>`
  @media (${device.smallDesktop}) {
    border-bottom: none;
  }

  @media (${device.tabletMin}) {
    border-left: none;
  }

  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 100%;
  }

  @media(min-width: 1281px) {
    margin-top: ${({ isApplicantsDropdownOpen }) => isApplicantsDropdownOpen ? "0" : "24px"};
  }
`;

export const StudentPublicCardTimeInfo = styled(LessonRequestCardTimeInfo)`
  @media (${device.mobileMax}) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 25%;
  }
`;

