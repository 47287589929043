import styled from "styled-components";

export const UsersSectionStyled = styled.section`


    .section-header {
        margin-bottom: 24px;
        display: flex;
        gap: 24px;
        
        .form-input-wrapper {
            &>div:first-child {
                margin: 0;
            }
        }
    }
    
    .users-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        
        .user-card {
            background: #fafcff;
            display: grid;
            border-radius: 16px;
            grid-template-columns: 45% 45% 10%;
            padding: 8px 16px;
            align-items: center;

            .user-info {
                display: flex;
                gap: 24px;
                align-items: center;

                img {
                    border-radius: 50%;
                    width: 58px;
                    height: 58px;
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 50px;

                    p:first-child {
                        font-weight: 500;
                    }

                    p:nth-child(2) {
                        color: rgb(140, 140, 140);
                    }
                }
            }
        }
    }
`