import styled from "styled-components";

import {device} from "../../constants";


export const ContentContainer = styled.div`
  .header, .tickets {
    width: 100%;
    max-width: 1440px;
    display: flex;
  }

  .header {
    column-gap: 24px;
    align-items: center;

    .caption {
      font-weight: 500;
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      h2, p {
        margin: 0;
      }

      h2 {
        font-size: 24px;
        line-height: 32px;
        color: #18067D;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.45);
      }

    }

    .create {
      display: flex;
      column-gap: 10px;
      padding: 8px 16px;
      align-items: center;
      background: #0d0d0d;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
      border-radius: 8px;
      cursor: pointer;
      transition: hover, 0.4s;

      p, svg path {
        color: #fff;
        fill: #fff;
      }

      svg {
        height: 20px;
        width: 20px;
      }

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin: 0;
      }

      &:hover {
        background: #D9D9D9;

        p, svg path {
          color: #000;
          fill: #000;
        }
      }
    }
  }

  .tickets {
    flex-direction: column;
    row-gap: 24px;

    .ticket {
      background: rgb(255, 255, 255);
      border-radius: 12px;
      box-shadow: rgba(90, 91, 96, 0.05) 0 6px 12px;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      column-gap: 32px;

      .info {
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        p {
          margin: 0;
          font-weight: 400;
          word-break: break-all;
        }

        .issue {
          font-size: 16px;
          line-height: 24px;
          transition: hover, 0.4s;
          cursor: pointer;

          &:hover {
            color: rgb(93, 81, 164);
          }
        }

        .date {
          font-size: 12px;
          line-height: 20px;
          color: rgb(140, 140, 140);
        }
      }

      .details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        row-gap: 12px;

        .show-details-arrow {
          cursor: pointer;
          transform: rotate(180deg);

          path {
            fill: #2F1F8A;
          }
        }
      }

    }
  }

  @media (${device.tabletMin}) and (${device.tabletMax}) {
    padding: 24px 4.9% 32px;
  }
`

export const OpenStatus = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #a6980a;
  background: #eef1a5;
  border: 1px solid #ead824;
  border-radius: 4px;
  padding: 1px 8px;
  width: fit-content;

  &:before {
    content: attr(data-text);
  }
`

export const InProgressStatus = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #ee6e06;
  background: #f1bf99;
  border: 1px solid #ee6e06;
  border-radius: 4px;
  padding: 1px 8px;
  width: fit-content;

  &:before {
    content: attr(data-text);
  }
`

export const ResolvedStatus = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #52C41A;
  background: #F6FFED;
  border: 1px solid #B7EB8F;
  border-radius: 4px;
  padding: 1px 8px;
  width: fit-content;

  &:before {
    content: attr(data-text);
  }
`

export const ClosedStatus = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #ff0000;
  background: #f6b5b5;
  border: 1px solid #ff0000;
  border-radius: 4px;
  padding: 1px 8px;
  width: fit-content;

  &:before {
    content: attr(data-text);
  }
`