import {FC, CSSProperties, ReactNode} from "react";

import {StyledWrapper} from "./not-found.styled";
import {NoResultIcon} from "../../../assets/images/svg-elements/not-result-icon";


interface Props {
    text: string | ReactNode;
    hint?: string;
    style?: CSSProperties;
}

export const NotFoundComponent: FC<Props> = ({text, hint, style}) => {

    return <StyledWrapper style={style} className="not_found_wrapper">
        <NoResultIcon className="not_found_icon"/>
        <div className="not_found_details">
            <h2>{text}</h2>
            <p>{hint}</p>
        </div>
    </StyledWrapper>
};