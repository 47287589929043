import {FC} from "react";
import {useNavigate} from "react-router-dom";

import {StepIds} from "../../../../../types";
import {TType} from "../../../../../assets/translations";
import {ProfileDropdownWrapper, StyledAvatarImg} from "./profile-dropdown.styled";
import {SettingsIcon} from "../../../../../assets/images/svg-elements/settings";
import {SupportIcon} from "../../../../../assets/images/svg-elements/support";
import {LogoutIcon} from "../../../../../assets/images/svg-elements/logout";
import {getUsersPicture} from "../../../../../services/user-service";


interface Props {
    t: TType;
    avatarId: string;
    lastName: string;
    firstName: string;
    logOut: () => void;
    setProfileDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProfileDropdownComponent: FC<Props> = ({
                                                        t,
                                                        avatarId,
                                                        lastName,
                                                        firstName,
                                                        logOut,
                                                        setProfileDropdownOpen
                                                    }) => {

    const navigate = useNavigate();

    const openProfilePage = () => {
        navigate("/user/profile");
    };

    const openSettingsPage = () => {
        navigate("/user/settings/account");
    };

    const openSupportPage = () => {
        navigate("/support");
    };


    return <ProfileDropdownWrapper>
        <div className="profile">
            <StyledAvatarImg src={getUsersPicture(avatarId)}
                             loading="lazy"
                             alt={`${firstName} ${lastName}`}/>
            <div className="profile-info-container">
                <span data-cy="profile-name-avatar-dropdown" className="profile-name">{`${firstName} ${lastName}`}</span>
                <a data-cy="view-profile-link" className="view-profile" onClick={openProfilePage}>
                    {t("profile.view")}
                </a>
            </div>
        </div>
        <div className="profile-menu">
            <div className="profile-menu-button" id={StepIds.settings} onClick={openSettingsPage}>
                <SettingsIcon className="profile-menu-button-icon"/>
                <span>{t("settings")}</span>
            </div>
            <div className="profile-menu-button" id={StepIds.support} onClick={openSupportPage}>
                <SupportIcon className="profile-menu-button-icon"/>
                <span>{t("support.title")}</span>
            </div>
            <div className="profile-menu-button profile-menu-button-sign-out"
                 data-cy="logout-button"
                 onClick={() => {
                     setProfileDropdownOpen(false);
                     logOut();
                 }}>
                <LogoutIcon className="profile-menu-button-icon"/>
                <span>{t("login.form.button.signout")}</span>
            </div>
        </div>
    </ProfileDropdownWrapper>
};