import {message} from "antd";
import {RcFile} from "antd/es/upload";

import {getFile} from "../api/user-api";
import {TFile} from "../types";
import {TType} from "../assets/translations";


function formatSizeUnits(bytes: number = 0, t: TType): string {
    if (bytes >= 1073741824) {
        return (bytes / 1073741824).toFixed(2) + " GB";
    } else if (bytes >= 1048576) {
        return (bytes / 1048576).toFixed(2) + " MB";
    } else if (bytes >= 1024) {
        return (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes > 1) {
        return `${bytes} ${t("bytes")}`
    } else if (bytes === 1) {
        return `${bytes} ${t("byte")}`
    } else {
        return `0 ${t("bytes")}`;
    }
}

const handleDownloadFile = (file: TFile, t: TType) => {

    message.loading(`${t("downloading")} ${file.filename}`);

    getFile(file.id!)
        .then(({data}) => {
            const a = document.createElement("a");
            const blobFile = new Blob([data], {type: "application/octet-stream"});

            a.href = URL.createObjectURL(blobFile);
            a.download = file.filename!;
            a.click();
        })
        .catch(() => message.error(t("errors.files-loading")))

};

const compressImage = async (file: RcFile | File, quality = 0.3): Promise<File> => {
    if (file.type === "image/svg+xml") {
        return file;
    }

    const imageBitmap = await createImageBitmap(file);

    const canvas = document.createElement("canvas");
    canvas.width = imageBitmap.width;
    canvas.height = imageBitmap.height;
    const ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");
    ctx?.drawImage(imageBitmap, 0, 0);

    const blob: any = await new Promise((resolve) =>
        canvas.toBlob(resolve, "image/webp", quality)
    );

    return new File([blob], file.name);
};

export {formatSizeUnits, handleDownloadFile, compressImage};