import {FC, useState} from "react";
import {useParams} from "react-router-dom";

import {UserDetailsSectionStyled} from "./user-details-section.styled";
import {useUserDetails} from "../../../../../../hooks/query-hooks";
import {getUsersPicture} from "../../../../../../services/user-service";
import {StyledButton} from "../../../../../elements/button";
import {RichTextDisplay} from "../../../../../elements/rich-text-display";
import {BlockUserConfirmationPopup} from "../../modals";
import {Divider} from "antd";
import {AssignLabelsPopup} from "../../modals";
import {CustomLoader} from "../../../../../elements/custom-loader";

export const UserDetailsSection: FC = () => {

  const [isBlockUserPopupOpen, setIsBlockUserPopupOpen] = useState<boolean>(false);
  const [isAssignLabelsPopupOpen, setIsAssignLabelsPopupOpen] = useState<boolean>(false)
  const {id} = useParams();

  const {status: userStatus, isFetching, isError, data: user, refetch} = useUserDetails({
    id: String(id),
    enabled: !!id
  });

  return (
    <UserDetailsSectionStyled>
      <BlockUserConfirmationPopup open={isBlockUserPopupOpen} onCancel={()=> setIsBlockUserPopupOpen(false)}/>
      <AssignLabelsPopup refetchUserDetails={refetch} open={isAssignLabelsPopupOpen} user={user} onCancel={()=> setIsAssignLabelsPopupOpen(false)}/>
      <div className="title-container">
        <h2>Деталі користувача</h2>
        <p>Переглядайте інформацію про користувача та виконуйте необхідні дії.</p>
      </div>

      <section className="regular-section">
        {user ? <div className="user-details-card">
            <div className="user-info">
                <div className="picture-and-name">
                    <img src={getUsersPicture(user.avatarId)} loading="lazy" alt={user.firstName}/>
                    <div className="info">
                        <p>{`${user.firstName} ${user.lastName}`}</p>
                        <div>{user.role}</div>
                    </div>
                </div>

                <RichTextDisplay className="about-user" htmlContent={user.about || ""}/>
            </div>
        </div>
        :
          <CustomLoader/>
        }

        <Divider/>
        <div className="action-buttons-container">
          <StyledButton onClick={() => setIsBlockUserPopupOpen(true)}>Заблокувати</StyledButton>
          <StyledButton onClick={()=> setIsAssignLabelsPopupOpen(true)}>Керувати тегами</StyledButton>
        </div>
      </section>
    </UserDetailsSectionStyled>
  )
}