import {QueryClient} from "@tanstack/react-query";

import {Period} from "../types";


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            cacheTime: 0,
            staleTime: 0
        },
        mutations: {}
    }
});

const QUERY_KEYS = {
    USER: {
        skills: () => {
            return ["user skills"]
        },
        skillsById: (id: string) => {
            return [...QUERY_KEYS.USER.skills(), id]
        },
        drawboards: () => {
            return ["drawboards"]
        },
        scheduleEvents: () => {
            return ["my schedule events"]
        },
        scheduleEventsById: (id: string) => {
            return [...QUERY_KEYS.USER.scheduleEvents(), id]
        },
        tickets: () => {
            return ["user tickets"]
        },
        filterTickets: ()=> {
            return ["filter tickets"]
        },
        ticketById: (id: string) => {
            return [...QUERY_KEYS.USER.tickets(), id]
        },
    },
    CATEGORIES: {
        all: () => {
            return ["top categories"]
        },

    },
    ACTIVE_STUDENTS: {
        all: () => {
            return ["active students"]
        }
    },
    FAVOURITE_TEACHERS: {
        all: () => {
            return ["favourite teachers"]
        }
    },
    TEACHER_DETAILS: {
        details: () => {
            return ["teacher details"]
        },
        detailsById: (id: string) => {
            return [...QUERY_KEYS.TEACHER_DETAILS.details(), id]
        },
        responseTime: () => {
            return [...QUERY_KEYS.TEACHER_DETAILS.details(), "response time"]
        },
        viewProfile: () => {
            return [...QUERY_KEYS.TEACHER_DETAILS.details(), "profile view"]
        }
    },
    LESSONS: {
        details: (id: string) => {
            return ["lesson details", id]
        },
        homeworkDetails: (id: string) => {
            return ["homework details", id]
        },
        count: (id: string, period: Period) => {
            return ["lessons count", id, period]
        },
    }
}


export {queryClient, QUERY_KEYS}