import React, { FC } from "react";
import { ActionButtons } from "../lesson-request-action-buttons-styled";
import { ButtonTheme } from "../../../../elements/button";
import { GetRequestsLessonStatuses } from "../../../../../types";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { handleChangeRequestStatus } from "../../services/handleChangeRequestStatus";

interface ChangeRequestStatusButtonProps {
  children: string | React.ReactElement;
  requestStatus: GetRequestsLessonStatuses;
  categoryId: string;
  connectionRequestId: string;
  studentId: string;
  successMsg?: string;
  teacherId: string;
  theme: ButtonTheme;
  disabled?: boolean;
}

const ChangeRequestStatusButton: FC<ChangeRequestStatusButtonProps> = ({
  children,
  requestStatus,
  categoryId,
  connectionRequestId,
  studentId,
  successMsg,
  teacherId,
  theme,
  disabled
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return (
    <ActionButtons
      theme={theme}
      disabled={disabled}
      onClick={() =>
        handleChangeRequestStatus({
          navigate,
          queryClient,
          newStatus: requestStatus,
          categoryId,
          connectionRequestId,
          studentId,
          successMsg,
          teacherId,
        })
      }
    >
      {children}
    </ActionButtons>
  );
};

export default ChangeRequestStatusButton;
