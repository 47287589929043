import {FC, useEffect, useState} from "react";

import {IssuesSectionStyled} from "./issues-section.styled";
import {IFilterTicket, TicketStatus} from "../../../../../../types";
import {Dropdown} from "../../../../../elements/dropdown";
import {getFilterTickets} from "../../../../../../api/user-api/getFilterTickets";
import {usePagination} from "../../../../../../hooks/usePagination";
import Pagination from "../../../../../elements/pagination/pagination";
import {SupportIssue} from "../../elements/support-issue";
import {NotFoundComponent} from "../../../../../elements/not-found-component";

const ticketFilterOptions = [
  {
    value: "ALL",
    name: "Всі"
  },
  {
    value: TicketStatus.OPEN,
    name: "Відкриті"
  },
  {
    value: TicketStatus.IN_PROGRESS,
    name: "В процесі"
  },
  {
    value: TicketStatus.RESOLVED,
    name: "Вирішені"
  },
  {
    value: TicketStatus.CLOSED,
    name: "Закриті"
  },
]

export const IssuesSection: FC = () => {

  const [ticketStatus, setTicketStatus] = useState<string>(ticketFilterOptions[0].value);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number | undefined>();
  const [filterTickets, setFilterTickets] = useState<IFilterTicket[]>([])

  const {
    goToNextPage,
    goToPage,
    goToPreviousPage,
    paginationRange,
    totalPages,
  } = usePagination({
    pageSize: 10,
    totalItems,
    currentPage,
    setCurrentPage,
  });

  useEffect(() => {
    const statusesArray = Object.values(TicketStatus);

    getFilterTickets(ticketStatus === "ALL" ? statusesArray : [ticketStatus], currentPage - 1)
      .then(({data}) => {
        setTotalItems(data.totalItems);
        setFilterTickets(data.supportTickets);
      })
  }, [ticketStatus, currentPage]);

  function handleTicketFilterChange(value: string) {
    setTicketStatus(value);
  }

  return (
    <IssuesSectionStyled>
      <div className="title-container">
        <h2>Підтримка користувачів</h2>
        <p>Керуйте запитами користувачів, відповідайте на їхні питання та вирішуйте проблеми.</p>
      </div>

      <section className="regular-section">
        <div className="section-header">
          <Dropdown style={{width: 240}} value={ticketStatus} options={ticketFilterOptions}
                    setValue={handleTicketFilterChange} placeholder="issued filter"/>
        </div>
        {filterTickets ?
          <div className="issues-container">
            {filterTickets && filterTickets.map((filterTicket) => <SupportIssue key={filterTicket.id} issue={filterTicket}/>)}
          </div>
          :
          <NotFoundComponent text="Немає результатів" style={{height: "auto"}}/>
        }
      </section>

      {totalPages !== 0 && (
        <Pagination
          currentPage={currentPage}
          goToNextPage={goToNextPage}
          goToPage={goToPage}
          goToPreviousPage={goToPreviousPage}
          paginationRange={paginationRange}
          totalPages={totalPages}
        />
      )}
    </IssuesSectionStyled>
  )
}