import {FC, useState} from "react";
import {SyncOutlined} from "@ant-design/icons";

import {NavSiderButtonItem, NavSiderImageBlock} from "../../../../../elements/profile-sider/profile-sider-styled";
import {PostRecalculateIndex} from "../../../../../../api/category-api/postRecalculateIndex";
import {TeachingMeErrorNotification, TeachingMeSuccessNotification} from "../../../../../elements/notification";

interface Props {
  collapsed?: string | undefined;
}

export const RecalculateIndexButton:FC<Props> = ({collapsed})=> {

  const [cooldown, setCooldown] = useState(0);

  function recalculateIndex() {
    if (cooldown > 0) {
      TeachingMeErrorNotification("Не можна оновити дані зараз. Дочекайтесь завершення таймеру.")
      return;
    }

    PostRecalculateIndex()
      .then((response)=> {
        TeachingMeSuccessNotification("Перерахунок відбувся успішно!")
        startCooldown()
      });
  }

  function startCooldown() {
    setCooldown(180);

    const interval = setInterval(() => {
      setCooldown((prev)=>{

        if (prev <= 1) {
          clearInterval(interval);
          return 0
        }

        return prev -1;
      })
    }, 1000);
  }

  return (
    <NavSiderButtonItem onClick={recalculateIndex} collapsed={collapsed}>
      <div className="button-container">
        <div className="info-block">
          <NavSiderImageBlock collapsed={collapsed}>
            <SyncOutlined/>
          </NavSiderImageBlock>
          <p style={{maxWidth: "90%"}}>Оновити дані <span style={{color: "red"}}>{!!cooldown && `через ${cooldown} сек.`}</span></p>
        </div>
      </div>
    </NavSiderButtonItem>
  )
}