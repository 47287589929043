import { useCallback, useEffect } from "react";

import debounce from "lodash.debounce";
import { InfoCircleOutlined } from "@ant-design/icons";

import { TType } from "../../../../assets/translations";

import { Hint } from "../../../elements/hint";
import Menu from "../../../elements/menu/menu";
import Input from "../../../elements/input/input";
import Switch from "../../../elements/switch/switch";

import LessonRequestFilters from "../lesson-request-filters/lesson-request-filters";

import {
  LessonRequestHeaderFilter,
  LessonRequestHeaderStyled,
} from "./lesson-request-header-styled";

import { FAQMenu } from "../../../../types/FAQ";

interface LessonRequestHeaderProps<T> {
  selectedKey: keyof T;
  menuTitles: FAQMenu<T>[];
  t: TType;
  handleChangeSelectedKey: (key: keyof T) => void;
  isShowActiveOnly?: boolean;
  requestsLength?: number;
  handleRenderFilterInputs?: (selectedKey: keyof T) => boolean[];
  showRequestLimit?: (selectedKey: keyof T) => boolean;
  isSwitchActive?: boolean;
  setIsSwitchActive?: (state: boolean) => void;
  isShowSearchFn: (selectedKey: keyof T) => boolean;
  search?: string;
  performSearch?: (value: string) => void;
  switchLabel?: string;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
}

const LessonRequestHeader = <T,>({
  selectedKey,
  menuTitles,
  t,
  handleChangeSelectedKey,
  isShowActiveOnly,
  requestsLength,
  handleRenderFilterInputs = () => [true, true],
  showRequestLimit = () => false,
  isSwitchActive,
  setIsSwitchActive,
  isShowSearchFn,
  performSearch,
  switchLabel,
  search,
  setSearch
}: LessonRequestHeaderProps<T>) => {
  const [isFilter, isSort] = handleRenderFilterInputs(selectedKey);
  const isShowRequestLimit = showRequestLimit(selectedKey);
  const isShowSearch = isShowSearchFn(selectedKey);

  const MAX_REQUESTS = 5;

  const performSearchDebounce = useCallback(
    debounce((value: string) => {
      if (performSearch) {
        performSearch(value);
      }
    }, 1000),
    []
  );

  useEffect(() => {
    return () => {
      performSearchDebounce.cancel();
    };
  }, [performSearchDebounce]);

  const handleInputChange = (value: string) => {
    if (!setSearch) return;

    performSearchDebounce(value);
    setSearch(value);
  };

  return (
    <LessonRequestHeaderStyled>
      <Menu
        cn={"requests-menu"}
        selectedKey={selectedKey}
        items={menuTitles}
        t={t}
        handleClick={handleChangeSelectedKey}
      />
      <LessonRequestHeaderFilter>
        <LessonRequestFilters t={t} isFilter={isFilter} isSort={isSort} />
        {isShowSearch && (
          <div>
            <Input
              value={search ? search : ""}
              type="search"
              onChange={handleInputChange}
              onEnterPress={performSearch}
              placeholder={t("my-students.search-placeholder")}
            />
          </div>
        )}
        {isShowActiveOnly && setIsSwitchActive && (
          <Switch
            isActive={isSwitchActive ?? false}
            setIsActive={() => setIsSwitchActive(!isSwitchActive)}
            label={switchLabel}
          />
        )}
        {isShowRequestLimit && (
          <div>
            <p>
              {requestsLength}/{MAX_REQUESTS} {t("lesson-requests.requests")}
            </p>
            <Hint placement="topLeft" title={t("lesson-requests.requests-hint")}>
              <InfoCircleOutlined />
            </Hint>
          </div>
        )}
        
      </LessonRequestHeaderFilter>
    </LessonRequestHeaderStyled>
  );
};

export default LessonRequestHeader;
