import { FC } from "react";

import { useNavigate } from "react-router-dom";

import { TType } from "../../../assets/translations";

import { ButtonTheme, StyledButton } from "../../elements/button";
import { ClarificationNeededPopupWrapper } from "../clarification-needed/clarification-needed-popup-styled";

interface Props {
  t: TType;
  open: boolean;
  onCancel: () => void;
}

const WrongCategoryPopup: FC<Props> = ({ t, open, onCancel }) => {
  const navigate = useNavigate();
  return (
    <ClarificationNeededPopupWrapper open={open} footer={null} closable={false}>
      <h1>Неможливо відгукнутись на запит</h1>
      <p>
        Неможливо відгукнутись на запит, адже ви не викладаєте його. Для того
        щоб це змінити, добавте відповідний предмет у налаштуваннях
      </p>
      <div className="buttons">
        <StyledButton theme={ButtonTheme.WHITE} onClick={onCancel}>
          {t("lesson-requests.modals.back")}
        </StyledButton>
        <StyledButton
          theme={ButtonTheme.BLUE}
          onClick={() =>
            navigate("/lesson-requests/opportunity-spot?filter=MY_SUBJECTS")
          }
        >
          Показати запити за моїми предметами
        </StyledButton>
      </div>
    </ClarificationNeededPopupWrapper>
  );
};

export default WrongCategoryPopup;
