import styled from "styled-components";
import {Modal} from "antd";

export const BlockUserConfirmationPopupStyled = styled(Modal)`
    .ant-modal-body {
        border-radius: 12px;
        background: #FFF;
        box-shadow: 0 4px 79px 0 rgba(0, 0, 0, 0.10);
        padding: 32px 42px;
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        h1 {
            color: #18067D;
            text-align: center;
            font-size: 22px;
            font-weight: 700;
        }

        .main-description {
            color: rgba(0, 0, 0, 0.80);
            font-size: 14px;
            font-weight: 400;
        }

        .buttons {
            padding-top: 16px;
            display: flex;
            gap: 8px;
            justify-content: flex-end;
        }
    }
`