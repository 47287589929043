import {Modal} from "antd";
import styled from "styled-components";

export const AssignLabelsPopupStyled = styled(Modal)`

    width: 1000px !important;

    .ant-modal-body {
        border-radius: 12px;
        background: #FFF;
        box-shadow: 0 4px 79px 0 rgba(0, 0, 0, 0.10);
        padding: 32px 42px;
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        h1 {
            color: #18067D;
            text-align: center;
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 6px;
        }

        .main-description {
            color: rgba(0, 0, 0, 0.80);
            font-size: 14px;
            font-weight: 400;
            text-align: center;
        }

        .manage-labels-block {
            display: flex;
            width: 100%;
            padding: 32px 0;

            .labels-block {
                display: flex;
                gap: 12px;
                flex-direction: column;
                width: 50%;
                height: 350px;

                h2 {
                    margin-bottom: 16px;
                    font-size: 19px;
                    text-align: center;
                }

                .badge-container {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    button {
                        height: 28px;
                        width: 46px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 18px;
                        border-radius: 16px;
                        font-weight: bold;
                        transition: background-color 0.3s, transform 0.2s;
                        outline: none;
                        border: none;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                &.available-labels-container {
                    padding-left: 32px;

                    .badge-container {
                        button {
                            background-color: #FFF;
                            color: #41a838;
                            box-shadow: 2px 2px 4px #afafaf;

                            &:active {
                                background-color: #41a838;
                                color: #FFF;
                            }
                        }
                    }
                }

                &.active-labels-container {
                    padding-right: 32px;
                    border-right: 1px solid #d7d7d7;

                    .badge-container {
                        button {
                            background-color: #FFF;
                            color: #d12128;
                            box-shadow: 2px 2px 4px #afafaf;

                            &:active {
                                background-color: #d12128;
                                color: #FFF;
                            }

                            &:disabled {
                                cursor: not-allowed;
                                background-color: gray;
                                cursor: not-allowed;
                                background-color: rgba(146, 146, 146, 0.6);
                                color: #f56363;
                                backdrop-filter: blur(2px);
                                opacity: 0.5;
                                border: 1px solid rgba(150, 150, 150, 0.4);
                                transition: all 0.3s ease;
                            }
                        }
                    }
                }
            }
        }

        .buttons {
            display: flex;
            gap: 8px;
            justify-content: flex-end;
        }
    }
`