import {FC, memo} from "react";
import {UserOutlined} from "@ant-design/icons";

import {NotificationsDropdown} from "../desktop-header/notifications-dropdown";
import {AdminProfile, Authority, StudentProfile, TUserProfileData} from "../../../../types";
import {Translations, TType} from "../../../../assets/translations";
import {StyledLogo, StyledTabletHeader} from "./tablet-header.styled";
import {Underline} from "../../../../assets/images/svg-elements/underline";
import {AffixStyled} from "../../../layout-components/affix/affix-styled";
import ProfileSider from "../../profile-sider/profile-sider";
import BurgerMenuButton from "../../burger-menu/burger-menu";


interface Props {
    t: TType;
    avatarId: string;
    logOut: () => void;
    firstName: string;
    lastName: string;
    notificationsCount: number;
    setNotificationsCount: any;
    messagesCount: number;
    setMessagesCount: any;
    language: Translations;
    onLanguageChange: (value: string) => void;
    goHome: () => void;
    signIn: () => void;
    signUp: () => void;
    openProfilePage: () => void;
    authority: Authority;
    isAuthenticated: () => boolean;
    renderRegistrationThirdStepPopup: any;
    renderLoginPopup: any;
    renderSignUpPopup: any;
    menuOpen: boolean;
    handleMenuOpen: () => void;
    user: TUserProfileData | StudentProfile | AdminProfile | null;
}

export const TabletHeader: FC<Props> = memo(({
                                                 t,
                                                 notificationsCount,
                                                 setNotificationsCount,
                                                 messagesCount,
                                                 setMessagesCount,
                                                 goHome,
                                                 signIn,
                                                 signUp,
                                                 authority,
                                                 isAuthenticated,
                                                 renderRegistrationThirdStepPopup,
                                                 renderLoginPopup,
                                                 renderSignUpPopup,
                                                 menuOpen,
                                                 handleMenuOpen,
                                                 user
                                             }) => {

    return <AffixStyled>
        <StyledTabletHeader>
            <div style={{position: "absolute"}}>

                {renderRegistrationThirdStepPopup()}
                {renderLoginPopup()}
                {renderSignUpPopup()}
            </div>

            <div className="header-logo" data-cy="header-logo" onClick={goHome}>
                <StyledLogo/>
                <h4>{t("teaching-me")}
                    <Underline/>
                </h4>
            </div>

            {isAuthenticated() && user ?
                <div className="menu">
                    <NotificationsDropdown t={t}
                                           notificationsCount={notificationsCount}
                                           setNotificationsCount={setNotificationsCount}
                                           messagesCount={messagesCount}
                                           setMessagesCount={setMessagesCount}
                    />
                    <BurgerMenuButton handleMenuOpen={handleMenuOpen} menuOpen={menuOpen} />
                    <ProfileSider t={t} administrator={authority === Authority.ROLE_ADMINISTRATOR} isOpen={menuOpen} setIsOpen={handleMenuOpen} user={user}/>

                </div>
                :
                <>
                  <div className="not-authorized-buttons-wrapper">
                    <button className="login" onClick={signIn} data-cy="login-btn">
                      <UserOutlined/>
                      {t("login.form.button.signin")}
                    </button>
                    <button className="create-account" data-cy="sign-up-header-btn" onClick={signUp}>
                      {t("register.createAccount")}
                    </button>
                  </div>
                </>
            }
        </StyledTabletHeader>
    </AffixStyled>
});