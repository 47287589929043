import { FC } from "react";

import { TType, useTranslate } from "../../../../../assets/translations";
import { CalendarClearIcon } from "../../../../../assets/images/svg-elements/calendar-clear";

import LessonRequestStatus from "../../lesson-request-status/lesson-request-status";

import {
  StudentDirectAge,
  StudentDirectCardButtons,
  StudentDirectCardContainer,
  StudentDirectCardStatusWrapper,
  StudentDirectCardTimeInfo,
  StudentDirectCardTimeSlot,
  StudentDirectCardTimeSlots,
  StudentDirectCardUserInfo,
  StudentDirectMessage,
} from "./student-direct-card-styled";
import { LessonRequestCardWrapper } from "../lesson-request-card-styled";

import { getUsersPicture } from "../../../../../services/user-service";
import { formatAge } from "../../../../../services/localization-service";

import {
  GetRequestsLessonStatuses,
  KnowledgeLevelsNames,
  LessonsFrequency,
  LessonsFrequencyNames,
  RequestLesson,
} from "../../../../../types";
import DeleteRequestButton from "../../lesson-request-action-buttons/delete-request/delete-request";
import { deleteConnectionRequest } from "../../../../../api/lesson-api/deleteConnectionRequest";
import { useQueryClient } from "@tanstack/react-query";
import {
  TeachingMeErrorNotification,
  TeachingMeSuccessNotification,
} from "../../../../elements/notification";
import { useIsResponsive } from "../../../../../hooks/useIsResponsive";
import {
  getMonthNameWithDate,
  normalizeHourWithTimezone,
} from "../../../../../services/date-service";
import { CardMessageButton } from "../../lesson-request-action-buttons/lesson-request-action-buttons";
import { NavLink, useNavigate } from "react-router-dom";
import { ButtonTheme } from "../../../../elements/button";
import { useFormattedMoreMessage } from "../../../../../hooks/useFormattedMoreMessage";

interface StudentDirectCardProps {
  card: RequestLesson;
  t: TType;
}

const StudentDirectCard: FC<StudentDirectCardProps> = ({ card, t }) => {
  const { language } = useTranslate();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { isTablet, isSmallDesktop, isBigDesktop } = useIsResponsive();

  const {
    isMessageLongerThanMaxSymbols,
    formattedMessage,
    handleIsShowMore,
    isShowMore,
  } = useFormattedMoreMessage(card.message, !isBigDesktop ? 100 : 30);

  const handleDeleteRequest = async () => {
    try {
      const res = await deleteConnectionRequest(card.id);

      if (res.status === 200) {
        queryClient.refetchQueries(["studentActiveRequests"]);
        queryClient.refetchQueries(["studentAllRequests"]);
        TeachingMeSuccessNotification("Request has been successfully deleted");
      }
    } catch (error) {
      TeachingMeErrorNotification("Error occured while deleting an request");
    }
  };

  return (
    <LessonRequestCardWrapper>
      <StudentDirectCardContainer className="container">
        <StudentDirectCardUserInfo>
          <NavLink to={`/user/details/${card.teacherId}`}>
            <img
              src={getUsersPicture(card.teacherAvatarId)}
              alt="teacher avatar"
            />
          </NavLink>
          <div className="personal_info">
            <div>
              <NavLink to={`/user/details/${card.teacherId}`}>
                <p>{card.teacherName}</p>
              </NavLink>
            </div>
            {card.categoryName && <p>{card.categoryName}</p>}
          </div>
        </StudentDirectCardUserInfo>

        <StudentDirectCardTimeInfo>
          <div>
            <CalendarClearIcon />
            <p>
              {card.numberOfClassesPerWeek
                ? `${LessonsFrequencyNames[card.numberOfClassesPerWeek]} ${
                    card.numberOfClassesPerWeek === LessonsFrequency.ONE
                      ? t("main-page.search-query.one_time_per_week")
                      : t("main-page.search-query.time_per_week")
                  }`
                : t("my-students.not-specified")}
            </p>
          </div>
          {!isTablet && !isSmallDesktop && (
            <StudentDirectCardTimeSlots>
              {card.desiredTimeslots.map((timeslot) => (
                <StudentDirectCardTimeSlot key={timeslot.startDate}>
                  {`${getMonthNameWithDate(
                    timeslot.startDate,
                    language
                  )} ${normalizeHourWithTimezone(timeslot.startDate)}`}
                </StudentDirectCardTimeSlot>
              ))}
            </StudentDirectCardTimeSlots>
          )}
        </StudentDirectCardTimeInfo>

        <StudentDirectAge>
          <div>
            <p className="card-section-title">
              {t("lesson-requests.cards.age")}
            </p>
            <p>
              {card.age
                ? `${card.age} ${formatAge(+card.age, language, t)}`
                : t("my-students.not-specified")}
            </p>
          </div>
          <div>
            <p className="card-section-title">
              {t("lesson-requests.cards.level")}
            </p>
            <p>
              {card.knowledgeLevel
                ? t(
                    `language-levels.${
                      KnowledgeLevelsNames[card.knowledgeLevel]
                    }`
                  )
                : t("my-students.not-specified")}
            </p>
          </div>
        </StudentDirectAge>

        {(isTablet || isSmallDesktop) && (
          <StudentDirectCardTimeSlots>
            {card.desiredTimeslots.map((timeslot) => (
              <StudentDirectCardTimeSlot key={timeslot.startDate}>
                {`${getMonthNameWithDate(
                  timeslot.startDate,
                  language
                )} ${normalizeHourWithTimezone(timeslot.startDate)}`}
              </StudentDirectCardTimeSlot>
            ))}
          </StudentDirectCardTimeSlots>
        )}

        <StudentDirectCardStatusWrapper>
          <LessonRequestStatus status={card.status} t={t} />
        </StudentDirectCardStatusWrapper>

        <StudentDirectMessage>
          {card.message ? (
            <div className="container">
              {isShowMore ? (
                <>
                  <p>{card.message}</p>
                  {isMessageLongerThanMaxSymbols && (
                    <button
                      onClick={handleIsShowMore}
                      className="load-more-message-btn"
                    >
                      Less
                    </button>
                  )}
                </>
              ) : (
                <>
                  <p>
                    {isMessageLongerThanMaxSymbols
                      ? `${formattedMessage}...`
                      : formattedMessage}
                  </p>
                  {isMessageLongerThanMaxSymbols && (
                    <button
                      onClick={handleIsShowMore}
                      className="load-more-message-btn"
                    >
                      More
                    </button>
                  )}
                </>
              )}
            </div>
          ) : (
            <p>{t("my-students.not-specified")}</p>
          )}
        </StudentDirectMessage>

        {card.status === GetRequestsLessonStatuses.CREATED ? (
          <StudentDirectCardButtons>
            <DeleteRequestButton handleDelete={handleDeleteRequest} />
            <CardMessageButton
              language={language}
              navigate={navigate}
              studentId={card.studentId}
              t={t}
              teacherId={card.teacherId}
              theme={ButtonTheme.INVERT_BLUE}
              type="icon"
            />
          </StudentDirectCardButtons>
        ) : (
          <StudentDirectCardButtons>
            <CardMessageButton
              language={language}
              navigate={navigate}
              studentId={card.studentId}
              t={t}
              teacherId={card.teacherId}
              theme={ButtonTheme.INVERT_BLUE}
              type="icon"
            />
          </StudentDirectCardButtons>
        )}
      </StudentDirectCardContainer>
    </LessonRequestCardWrapper>
  );
};

export default StudentDirectCard;
