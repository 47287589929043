import styled from "styled-components";

export const BackOfficePageStyled = styled.div`
    
    .title-container {
        margin-bottom: 24px;
        
        h2 {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            color: rgb(24, 6, 125);
        }

        p {
            font-size: 16px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.45);
        }
    }
    
    .regular-section {
        padding: 32px;
        background: #ffffff;
        width: 100%;
        max-height: fit-content;
        min-height: calc(100vh - 300px);
        margin-bottom: 24px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
    }

`