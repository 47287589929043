import styled, { css } from "styled-components";

import { Chevron } from "../../../assets/images/svg-elements/chevron";
import { Translations } from "../../../assets/translations";
import { device } from "../../constants";
import { hideScroll } from "../../../config";

export interface NavSiderStyledProps {
  collapsed?: string | undefined;
  opened?: string | undefined;
  language?: Translations;
  hasCount?: boolean;
}

export const NavSider = styled.aside<NavSiderStyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  border-right: 1px solid rgba(0, 0, 0, 10%);
  padding: 24px 0px;
  text-wrap: nowrap;
  position: fixed;
  top: 0;
  align-self: stretch;
  z-index: 1000;
  margin-top: var(--header-offset);
  height: calc(100vh - var(--header-offset));
  line-height: 22px;

  width: ${({ collapsed }) =>
    collapsed ? "var(--sider-collapsed-width)" : "var(--sider-opened-width)"};
  max-width: ${({ collapsed }) =>
    collapsed ? "var(--sider-collapsed-width)" : "var(--sider-opened-width)"};
  min-width: ${({ collapsed }) =>
    collapsed ? "var(--sider-collapsed-width)" : "var(--sider-opened-width)"};

  transition: all 0.3s ease-out;

  @media (${device.mobileMax}) {
    padding: 16px 0px;
    transform: translateX(100%);
    max-width: 100%;
    width: 100%;
    transition: transform 0.3s ease-out;
    overflow-y: auto;
    height: calc(100dvh - var(--header-offset));

    ${hideScroll}

    &.mobile-open {
      transform: translateX(-15px);
    }
  }

  @media (${device.tabletMin}) and (${device.tabletMax}) {
    transform: translateX(55%);
    transition: transform 0.3s ease-out;
    width: var(--sider-opened-width);
    max-width: var(--sider-opened-width);
    min-width: var(--sider-opened-width);

    &.mobile-open {
      transform: translateX(-55%);
    }
  }
`;

export const NavSiderInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 80%;

  @media (${device.mobileMax}) {
    height: auto;
  }
`;

export const NavInfoSider = styled.div<NavSiderStyledProps>`
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0px 19px;
  min-height: fit-content;

  img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin: 0px 24px 0px 0px;
  }

  p {
    font-weight: 500;
    color: rgba(0, 0, 0, 85%);
    visibility: ${({ collapsed }) => (collapsed ? "hidden" : "visible")};
    transition-delay: ${({ collapsed }) => (collapsed ? "0" : "0.2s")};
    max-width: 212px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const NavDividerSider = styled.div<NavSiderStyledProps>`
  position: relative;
  padding: 19px;

  .line {
    border: 1px solid #f6f6f6;
    border-radius: 8px;
  }

  button {
    position: absolute;
    top: 20%;
    right: ${({ collapsed }) => (collapsed ? "-15%" : "-5%")};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    background: white;
    border: 1px solid #f6f6f6;
    border-radius: 8px;
    cursor: pointer;
  }
`;

export const ChevronIconStyled = styled(Chevron)`
  min-width: 16px;
  min-height: 16px;
`;

export const ChevronHorizontalIcon = styled(
  ChevronIconStyled
)<NavSiderStyledProps>`
  transform: rotate(${({ collapsed }) => (collapsed ? "0deg" : "180deg")});
  transition: transform 0.1s ease-out;
  stroke: #000;
  fill: #000;
`;

export const NavSiderImageBlock = styled.div<NavSiderStyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height: 40px;

  &:hover {
    background: ${({ collapsed }) => (collapsed ? "#F6F6F6" : undefined)};
    border-radius: 8px;
  }

  svg {
    min-width: 20px;
    min-height: 20px;
  }
`;

export const NavSiderButtonItem = styled.button<NavSiderStyledProps>`
  text-wrap: no-wrap;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 100%;
  color: #757575;
  min-width: 40px;
  min-height: 40px;

  &:hover {
    background: ${({ collapsed }) => !collapsed && "#F6F6F6"};
    border-radius: 8px;
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;


    &:hover {
      .tooltiptext {
        visibility: visible;
      }
    }

  .info-block {
    display: flex;
    align-items: center;
    position: relative;
    max-width: ${({ collapsed }) => (collapsed ? "40px" : "auto")};
    flex: 1;

    .logout {
      color: #d55f5a;
    }
  }

  p {
    visibility: ${({ collapsed }) => (collapsed ? "hidden" : "visible")};
    transition: visibility 0.3s ease-out 0.2s;
    flex: 1;
    text-align: left;
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media(${device.tabletMax}) {
    max-height: 40px;

    .info-block {
      max-height: 40px;
    }
  }
`;

export const NavSiderSystemBlock = styled.div<NavSiderStyledProps>`
  .image-block {
    svg {
      fill: #757575;
    }
  }

  ul {
    padding: 0px 19px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .link-container {
    display: flex;
    align-items: center;
    text-wrap: no-wrap;
    color: #757575;
    position: relative;
    min-width: 40px;
    min-height: 40px;

    &:hover {
      background: #f6f6f6;
      border-radius: 8px;

      .tooltiptext {
        visibility: visible;
      }
    }

    p {
      visibility: ${({ collapsed }) => (collapsed ? "hidden" : "visible")};
      transition-delay: ${({ collapsed }) => (collapsed ? "0" : "0.2s")};
    }
  }
`;

export const NavSiderSystemImageBlock = styled(NavSiderImageBlock)`
  svg {
    fill: #757575;
  }
`;

export const SiderBadge = styled.div<NavSiderStyledProps>`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #ff4d4f;
  color: white;
  position: absolute !important;
  font-size: 12px;
  ${({ collapsed, opened, language }) => {
    // sider closed and menu opened
    if (collapsed && opened) {
      return css`
        right: 5px !important;
        top: 5px !important;
      `;
    }

    // sider closed
    if (collapsed) {
      return css`
        right: 0px !important;
        top: 0px !important;
      `;
    }

    // sider opened and menu opened and language english
    if (!collapsed && opened && language === "en") {
      return css`
        right: 0%;
        top: 10px;
      `;
    }

    // sider opened and menu opened
    if (!collapsed && opened) {
      return css`
        right: 15%;
        top: 10px;
      `;
    }

    // sider opened and menu closed and language english
    if (!collapsed && !opened && language === "en") {
      return css`
        right: 35%;
        top: 10px;
      `;
    }

    // sider opened and menu closed and language ukraine
    if (!collapsed && !opened && language === "uk") {
      return css`
        right: 25%;
        top: 10px;
      `;
    }
  }}

  &.sub-menu-collapsed-badge {
    display: ${({ opened }) => (opened ? "block" : "none")};
  }
`;
