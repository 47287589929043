import styled from "styled-components";
import {device} from "../../constants";

export const ConnectRequestPageStyled = styled.div`
    .top-info {
        max-width: 1440px;
        width: 100%;
        display: flex;
        column-gap: 23px;
        margin-bottom: 32px;

        .navigation {
            font-size: 24px;
            height: 32px;
            display: flex;
            align-items: center;

            &:hover {
                cursor: pointer;
            }

            &:active {
                color: #5D51A4;
            }
        }

        .info {
            >h2 {
                font-size: 24px;
                font-weight: 500;
                line-height: 32px;
                text-align: left;
                color: #002766;
                margin-bottom: 8px;
            }

            >div {
                span {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                }

                .step {
                    margin-right: 12px;
                    color: #00000073;
                }

                .description {
                    font-weight: 500;
                    color: #000000;
                }
            }
        }
    }
    
    
    @media (${device.tabletMax}) {
        .top-info {
            column-gap: 12px;
            padding-bottom: 24px;
            margin-bottom: 24px;
            border-bottom: 0.5px solid #D9D9D9;
    }
    

    @media (${device.mobileMax}) {
        .top-info {
            
            .navigation {
                font-size: 16px;
                height: 24px;
            }

            .info {
                >h2 {
                    font-size: 16px;
                    line-height: 24px;
                }

                >div {
                    span {
                        font-size: 12px;
                        line-height: 20px;
                    }
                }
            }
        }
`