import { useMediaQuery } from 'react-responsive';

import { device } from '../../constants';

import { BadgeWrapper } from './badge-styled';

import { BadgeSizes, IBadgeItem } from '../../../types/Badge';
import {FC} from "react";

interface BadgeProps {
    badge: IBadgeItem;
    size: BadgeSizes;
    onClick?: ()=> void;
    className?: string;
}

const Badge: FC<BadgeProps> = ({badge, size, onClick, className}) => {
    const {color, borderColor, icon, text, isTextLight} = badge;

    const isSmallDesktop = useMediaQuery({
        query: `(${device.desktop}) and (${device.smallDesktop})`,
    });

    return (
    <BadgeWrapper onClick={onClick} className={className} size={size} color={color} borderColor={borderColor} isTextLight={isTextLight!} isSmallDesktop={isSmallDesktop}>
        {icon && <img src={icon} alt='test-alt'/>}
        {size !== "icon" && <p>{text}</p>}
    </BadgeWrapper>
  )
}

export default Badge