import { FC } from "react";
import { BurgerMenuButtonStyled } from "./burger-menu-styled";

interface BurgerMenuButtonProps {
    menuOpen: boolean;
    handleMenuOpen: () => void;
}

const BurgerMenuButton: FC<BurgerMenuButtonProps> = ({ handleMenuOpen, menuOpen }) => {
  const classCondition = menuOpen ? "open" : "closed";
  return (
    <BurgerMenuButtonStyled
      className={classCondition}
      onClick={handleMenuOpen}
    >
      <span></span>
      <span></span>
      <span></span>
    </BurgerMenuButtonStyled>
  );
};

export default BurgerMenuButton;
